import React from "react";
import CustomTable from "components/CustomTable";
import { Typography } from "@material-ui/core";

const FundraiserReport = ({ fundraisers }) => {
  const tableHead = ["Fundraiser", "Team", "Raised", "Goal", "CreatedAt"];
  const formatTableData = (data) => {
    return data?.map((fundraiser) => [
      <div>
        <Typography>{fundraiser.name}</Typography>
        <Typography variant="caption" color="textSecondary">
          {fundraiser.email}
        </Typography>
      </div>,
      `${fundraiser.Team.name}`,
      `$ ${fundraiser.achieved_goal ? fundraiser.achieved_goal : 0 }`,
      `$ ${fundraiser.goal}`,
      `${new Date(fundraiser.createdAt).toLocaleDateString()}   -  ${new Date(fundraiser.createdAt).toLocaleTimeString()}`,
    ]);
  };

  return (
    <CustomTable
      tableHead={tableHead}
      tableData={
        fundraisers && fundraisers.length ? formatTableData(fundraisers) : []
      }
      noBorder
      withPagination={false}
      style={{ marginTop: 20 }}
    />
  );
};

export default FundraiserReport;
