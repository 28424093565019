import React, { useEffect, useState } from "react";
import OrganizationCardView from "views/admin/organization/OrganizationCardView";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganization } from "store/organization";
import Loading from "components/Loading";
import { Paper, Tab, Tabs } from "@material-ui/core";
import TabPanel from "components/TabPanel";
import CampaignListing from "views/admin/campaign/CampaignListing";
import TeamListing from "views/admin/organization/TeamListing";
import ManagerListing from "views/admin/organization/ManagerListing";
import { fetchManagers } from "store/manager";
import { fetchTeams } from "store/team";

const OrganizationDetail = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const { inProgress, org } = useSelector((state) => state.organization);
  const { data: managers } = useSelector((state) => state.manager);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const organizationId = orgId || user?.orgId;
    if (organizationId) {
      dispatch(fetchOrganization(organizationId));
      dispatch(fetchManagers(organizationId, true));
      dispatch(fetchTeams(organizationId, true));
    }
  }, [dispatch, orgId]);

  if (inProgress) {
    return <Loading />;
  }

  const hasManagers = !!(org && org.managers && org.managers.length);

  return (
    <>
      <OrganizationCardView organization={org} />
      <Paper style={{ marginTop: 25 }} variant="outlined">
        <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
          <Tab label="Managers" />
          <Tab label="Teams" />
          <Tab label="Campaigns" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <ManagerListing orgId={orgId || user?.orgId} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <TeamListing orgId={orgId || user?.orgId} />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <CampaignListing
            orgId={orgId || user?.orgId}
            style={hasManagers ? undefined : { marginTop: 30 }}
            withActionbar={hasManagers || managers?.length}
          />
        </TabPanel>
      </Paper>
    </>
  );
};

export default OrganizationDetail;
