import React, { useEffect, useState } from "react";
import { Avatar, Typography } from "@material-ui/core";
import { storageRef } from "../../../index";

const OrganizationCardView = ({ organization }) => {
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    downloadImage();
  }, [organization]);
  const downloadImage = async () => {
    if (organization) {
      try {
        const url = await storageRef
          .child(`organization_profile_${organization?.id}`)
          .getDownloadURL();
        setImageUrl(url);
      } catch (err) {}
    }
  };
  if (!organization) return null;

  return (
    <div style={{ marginTop: 0, marginBottom: 25 }}>
      <div
        style={{
          padding: 20,
          paddingLeft: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          variant="rounded"
          src={imageUrl}
          style={{ height: 150, width: 150, marginRight: 15 }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">{organization.name}</Typography>
          <Typography variant="body1">{organization.address}</Typography>
          <Typography variant="body2">{organization.phone_number}</Typography>
        </div>
      </div>
      {/*<Paper style={{ marginTop: 5, padding: 20 }} variant="outlined">*/}
      {/*  <Typography variant="h6" color="textSecondary">*/}
      {/*    Organization Admin*/}
      {/*  </Typography>*/}

      {/*  <div style={{ marginTop: 20, marginBottom: 20 }}>*/}
      {/*    <KeyValue title="Name" value={organization.Admin.name} />*/}
      {/*    <KeyValue title="Email" value={organization.Admin.email} />*/}
      {/*    <KeyValue title="Phone" value={organization.Admin.phone_number} />*/}
      {/*  </div>*/}
      {/*</Paper>*/}
    </div>
  );
};

export default OrganizationCardView;
