import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  capitalize,
  Container,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
  Divider
} from "@material-ui/core";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AdminTopBar from "components/AdminTopBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignDetailsByWebAddress } from "store/campaign";
import Loading from "components/Loading";
import ResourceNotFound from "components/ResourceNotFound";
import { storageRef } from "index";
import BecomeFundraiserDialog from "views/public/campaign/BecomeFundraiserDialog";
import { Role } from "utils/roles";
import { toast } from "react-toastify";
import { joinCampaignAsFundraiser } from "store/adult";
import { useMediaQuery } from "react-responsive";
import { AttachMoney, Share , ArrowForward } from "@material-ui/icons";
import { BASE_URL } from "api-client";
import TopTeam from "./TopTeam";
import { colors, getRandomColor } from "utils/colors";
import Footer from "components/Footer";
import RewardComponent from "../reward/RewardComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
}));

export default function CampaignDetail() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const cid = campaignId.split("_")[1];
  const { inProgress, currentCampaign: campaignDetails } = useSelector(
    (state) => state.campaign
  );

  const [coverImage, setCoverImage] = useState(null);
  const [imageTopTeam, setImageTopTeam] = useState(null);
  const [imageTopDonorsTeam, setImageTopDonorsTeam] = useState(null);
  const [imageTopFundraiser, setImageTopFundraiser] = useState(null);

  const [isFundraisingDialogShowing, showFundraisingDialog] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [adultJoinedCampaign, setAdultJoinedCampaign] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    if (campaignId) {
      dispatch(
        fetchCampaignDetailsByWebAddress(
          campaignId,
          user?.systemId || undefined
        )
      );
    }
  }, [dispatch, campaignId]);

  useEffect(() => {
    downloadImages();
  }, [coverImage, campaignDetails]);

  const downloadImages = async () => {
    try {
      const coverImage = `campaign_cover_${campaignDetails?.id}`;
      const topTeamImage = `team_${campaignDetails?.topTeam?.Team?.id}`;
      const topDonorsTeamImage = `team_${campaignDetails?.topDonorsTeam?.id}`;
      const topFundraiserImage = `minor_${campaignDetails?.topFundraiser?.id}`;

      const coverImageUrl = await storageRef.child(coverImage).getDownloadURL();
      setCoverImage(coverImageUrl);

      const topTeamImageUrl = await storageRef
        .child(topTeamImage)
        .getDownloadURL();
      setImageTopTeam(topTeamImageUrl);
      const topDonorsTeamImageUrl = await storageRef
        .child(topDonorsTeamImage)
        .getDownloadURL();
      setImageTopDonorsTeam(topDonorsTeamImageUrl);
      const topFundraiserImageUrl = await storageRef
        .child(topFundraiserImage)
        .getDownloadURL();
      setImageTopFundraiser(topFundraiserImageUrl);
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      <AdminTopBar />
      <div className={classes.toolbar} />
      {inProgress ? (
        <Container>
          <Loading />
        </Container>
      ) : (
        <>
            {isDesktopOrLaptop ?
                <Container maxWidth="lg" style={{ marginBottom: 50, padding: 70 }}>
                <Grid container spacing={2}>
                    <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
                        <img
                            width="100%"
                            height="400"
                            style={{
                                borderRadius: 50,
                            }}
                            src={coverImage}
                        />
                        <Typography
                            style={{ fontWeight: "bold", marginTop: 15 }}
                            variant={isDesktopOrLaptop ? "h3" : "h5"}
                            color="textPrimary"
                        >
                            {campaignDetails?.title}
                        </Typography>
                        <Typography>{campaignDetails?.description}</Typography>
                        <Grid container>
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={6}
                                xs={6}
                                style={{ marginTop: 30 }}
                            >
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: 20,
                                            marginRight: 20,
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            history.push(
                                                `${campaignId}/t/${campaignDetails?.topFundraiser?.Team?.name}_${campaignDetails?.topFundraiser?.Team?.id}/f/${campaignDetails?.topFundraiser?.name}_${campaignDetails?.topFundraiser?.id}`
                                            )
                                        }
                                    >
                                        <Typography
                                            variant="body2"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            TOP FUNDRAISER
                                        </Typography>
                                        <Avatar
                                            src={imageTopFundraiser}
                                            style={{
                                                width: 80,
                                                height: 80,
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            {campaignDetails?.topFundraiser?.name}
                                        </Typography>
                                        <Typography variant="caption">
                                            {  campaignDetails?.topFundraiser?.achieved_goal ?
                                                `${campaignDetails?.topFundraiser?.achieved_goal}.00  Raised` : "$0  Raised "}

                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={6}
                                xs={6}
                                style={{ marginTop: 30 }}
                                onClick={() =>
                                    history.push(
                                        `${campaignId}/t/${campaignDetails?.topTeam?.Team.name}_${campaignDetails?.topTeam?.Team.id}`
                                    )
                                }
                            >
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: 20,
                                            marginRight: 20,
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            TOP TEAM
                                        </Typography>
                                        <Avatar
                                            src={imageTopTeam}
                                            style={{
                                                width: 80,
                                                height: 80,
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            {campaignDetails?.topTeam?.Team?.name}
                                        </Typography>
                                        <Typography variant="caption">
                                            ${campaignDetails?.topTeam?.achieved_goal_amount}.00
                                            Raised
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={6}
                                xs={6}
                                style={{ marginTop: 30 }}
                                onClick={() =>
                                    history.push(
                                        `${campaignId}/t/${campaignDetails?.topDonorsTeam?.name}_${campaignDetails?.topDonorsTeam?.id}`
                                    )
                                }
                            >
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: 20,
                                            marginRight: 20,
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            MOST MEMBERS
                                        </Typography>
                                        <Avatar
                                            src={imageTopDonorsTeam}
                                            style={{
                                                width: 80,
                                                height: 80,
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            {campaignDetails?.topDonorsTeam?.name}
                                        </Typography>
                                        <Typography variant="caption">
                                            {campaignDetails?.topDonorsTeam?.donors_count} Members
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={6}
                                xs={6}
                                style={{ marginTop: 30 }}
                                onClick={() =>
                                    history.push(
                                        `${campaignId}/t/${campaignDetails?.topDonorsTeam?.name}_${campaignDetails?.topDonorsTeam?.id}`
                                    )
                                }
                            >
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: 20,
                                            marginRight: 20,
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            MOST DONORS
                                        </Typography>
                                        <Avatar
                                            src={imageTopDonorsTeam}
                                            style={{
                                                width: 80,
                                                height: 80,
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            {campaignDetails?.topDonorsTeam?.name}
                                        </Typography>
                                        <Typography variant="caption">
                                            {campaignDetails?.topDonorsTeam?.donors_count} Donors
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xl={12} lg={12} style={{ marginTop: 20 }}>
                            <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                Team Success
                            </Typography>
                            <Typography variant="body2">
                                See the progress of the Teams
                            </Typography>
                            <Grid container spacing={6} style={{ marginTop: 25 }}>
                                {campaignDetails &&
                                campaignDetails.teamGoals &&
                                campaignDetails.teamGoals.length ? (
                                    campaignDetails.teamGoals
                                        .slice(0)
                                        .sort((a, b) => {
                                            const x = parseInt(a.goal);
                                            const y = parseInt(b.goal);
                                            return x > y ? -1 : x < y ? 1 : 0;
                                        })
                                        .map((goal) => (
                                            <TopTeam goal={goal} campaignId={campaignId} />
                                        ))
                                ) : (
                                    <ResourceNotFound />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                        <Paper elevation={3} style={{ padding: 25, borderRadius: 20 }}>
                            <Typography variant="h5">
                    <span style={{ fontWeight: "bold" }}>
                      ${campaignDetails?.achieved_goal_amount} raised
                    </span>{" "}
                                of ${campaignDetails?.total_goal_amount}
                            </Typography>
                            <LinearProgress
                                style={{
                                    height: 6,
                                    borderRadius: 25,
                                    marginTop: 20,
                                }}
                                variant="determinate"
                                value={
                                    (parseInt(campaignDetails?.achieved_goal_amount) / parseInt(campaignDetails?.total_goal_amount)) * 100
                                }
                                color="secondary"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                fullWidth
                                size="large"
                                disabled={
                                    campaignDetails?.adultJoinedCampaign ||
                                    adultJoinedCampaign ||
                                    user?.role === Role.SuperAdmin ||
                                    user?.role === Role.Admin
                                }
                                style={{
                                    color: colors.white,
                                    marginTop: 25,
                                    marginBottom: 20,
                                    fontWeight: "bold",
                                }}
                                onClick={async () => {
                                    if (user?.role === Role.Adult) {
                                        await dispatch(
                                            joinCampaignAsFundraiser(
                                                user?.systemId,
                                                campaignDetails?.id
                                            )
                                        );
                                        setAdultJoinedCampaign(true);
                                        toast("campaign joined", { type: "success" });
                                    } else {
                                        showFundraisingDialog(true);
                                    }
                                }}
                            >
                                {campaignDetails?.adultJoinedCampaign || adultJoinedCampaign
                                    ? `Joined as Fundraiser`
                                    : `Join the Campaign`}
                            </Button>
                            <div style={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    fullWidth
                                    startIcon={<AttachMoney color= {colors.white} />}
                                    style={{
                                        marginRight: 10,
                                        fontWeight: "bold",
                                        color : colors.white ,
                                        backgroundColor : colors.donationGreen
                                    }}
                                    onClick={() =>
                                        history.push(`${location.pathname}/donate`)
                                    }
                                >
                                    Donate Now
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    fullWidth
                                    style={{ marginLeft: 10, fontWeight: "bold" }}
                                    startIcon={<Share color="action" />}
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            ` ${BASE_URL}${location.pathname}`
                                        );
                                        toast("Link Copied to Share !", { type: "success" });
                                    }}
                                >
                                    Share this Page
                                </Button>
                            </div>
                            <Typography
                                variant="body1"
                                style={{
                                    fontWeight: "bold",
                                    marginTop: 20,
                                    marginBottom: 10,
                                }}
                            >
                                Campaign Activity
                            </Typography>
                            <List>
                                {campaignDetails &&
                                campaignDetails?.donations &&
                                campaignDetails?.donations.length ? (
                                    campaignDetails?.donations.map((donation) => (
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar
                                                    style={{
                                                        backgroundColor: getRandomColor(),
                                                        height: 50,
                                                        width: 50,
                                                    }}
                                                >
                                                    {capitalize(donation?.Donor?.full_name).charAt(0)}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={donation?.isAnonymous == "true" || donation?.isAnonymous == true ? "Anonymous" : ` ${capitalize(
                                                    donation?.Donor?.full_name
                                                )}` }
                                                secondary={
                                            <div style={{display : "flex" }}>
                                              <Typography type="body2" style={{ color: colors.black , fontWeight : "bold" , marginRight : 10 }}> ${parseFloat(donation?.amount).toFixed(2) } </Typography>
                                              <div style={{height :6 , width : 6 , backgroundColor : colors.lightGrey , borderRadius : 3 , marginTop : 8}} ></div>
                                              <Typography type="body2" style={{ marginLeft : 10 }}> {moment(donation?.createdAt).fromNow()} </Typography>
                                            </div>
                                            }
                                            />
                                        </ListItem>
                                    ))
                                ) : (
                                    <ResourceNotFound message="No Activity found" />
                                )}
                            </List>
                        </Paper>
                      <Grid item xl={12} lg={12} style={{marginTop : 30}} >
                        <Paper elevation={2} style={{ paddingBottom : 15 , paddingTop: 25, borderRadius: 20 , paddingRight :10  }}>
                          <Typography variant="h5">
                            <span style={{ fontWeight: "bold" , paddingLeft : 25 , paddingRight : 25}}>
                              Get a Reward
                            </span>
                          </Typography>

                          {campaignDetails &&
                          campaignDetails.rewards &&
                          campaignDetails.rewards.length ? (
                              campaignDetails.rewards
                                  .slice(0)
                                  .sort((a, b) => {
                                    const x = parseInt(a.end_date);
                                    const y = parseInt(b.end_date);
                                    return x > y ? -1 : x < y ? 1 : 0;
                                  })
                                  .map((reward) => (
                                      <div style={{marginTop : 15}}>
                                        <RewardComponent reward={reward} achievedAmount={reward?.campaignAchievedAmount}/>
                                      </div>
                                  ))
                          ) : (
                              <ResourceNotFound />
                          )}
                        </Paper>
                      </Grid>

                    </Grid>
                </Grid>
            </Container>
                :
                <div>
                  <img width="100%" height="250" style={{marginTop : 31}} src={coverImage}/>
                  <div style={{margin :20}}>
                    <Typography
                        style={{ fontWeight: "bold", marginTop: 15 }}
                        variant={isDesktopOrLaptop ? "h3" : "h5"}
                        color="textPrimary"
                    >
                      {campaignDetails?.title}
                    </Typography>
                    <Typography style={{marginTop : 10 , marginBottom : 20}}>{campaignDetails?.description}</Typography>
                    <Typography variant="h5">
                    <span style={{ fontWeight: "bold" }}>
                      ${campaignDetails?.achieved_goal_amount} raised
                    </span>{" "}
                      of ${campaignDetails?.total_goal_amount}
                    </Typography>
                    <LinearProgress
                        style={{
                          height: 6,
                          borderRadius: 25,
                          marginTop: 20,
                        }}
                        variant="determinate"
                        value={
                          (parseInt(campaignDetails?.achieved_goal_amount) /
                              parseInt(campaignDetails?.total_goal_amount)) *
                          100
                        }
                        color="secondary"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        fullWidth
                        size="large"
                        disabled={
                          campaignDetails?.adultJoinedCampaign ||
                          adultJoinedCampaign ||
                          user?.role === Role.SuperAdmin ||
                          user?.role === Role.Admin
                        }
                        style={{
                          color: colors.white,
                          marginTop: 25,
                          marginBottom: 20,
                          fontWeight: "bold",
                        }}
                        onClick={async () => {
                          if (user?.role === Role.Adult) {
                            await dispatch(
                                joinCampaignAsFundraiser(
                                    user?.systemId,
                                    campaignDetails?.id
                                )
                            );
                            setAdultJoinedCampaign(true);
                            toast("campaign joined", { type: "success" });
                          } else {
                            showFundraisingDialog(true);
                          }
                        }}
                    >
                      {campaignDetails?.adultJoinedCampaign || adultJoinedCampaign
                          ? `Joined as Fundraiser`
                          : `Join the Campaign`}
                    </Button>
                    <div style={{ display: "flex" }}>
                      <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          fullWidth
                          startIcon={<AttachMoney color= {colors.white} />}
                          style={{
                            marginRight: 10,
                            fontWeight: "bold",
                            color : colors.white ,
                            backgroundColor : colors.donationGreen
                          }}
                          onClick={() =>
                              history.push(`${location.pathname}/donate`)
                          }
                      >
                        Donate Now
                      </Button>
                      <Button
                          variant="outlined"
                          size="large"
                          fullWidth
                          style={{ marginLeft: 10, fontWeight: "bold" }}
                          startIcon={<Share color="action" />}
                          onClick={() => {
                            navigator.clipboard.writeText(
                                ` ${BASE_URL}${location.pathname}`
                            );
                            toast("Link Copied to Share !", { type: "success" });
                          }}
                      >
                        Share
                      </Button>
                    </div>
                    <Divider style={{marginTop: 30 , marginBottom :20}}/>
                      <div
                          style={{
                            flexDirection: "column",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                              history.push(
                                  `${campaignId}/t/${campaignDetails?.topFundraiser?.Team?.name}_${campaignDetails?.topFundraiser?.Team?.id}/f/${campaignDetails?.topFundraiser?.name}_${campaignDetails?.topFundraiser?.id}`
                              )
                          }
                      >
                        <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                        >
                          TOP FUNDRAISER
                        </Typography>

                        <ListItem style={{padding :0
                        }}>
                          <ListItemAvatar>
                            <Avatar
                                src={imageTopFundraiser}
                                style={{
                                  width: 50,
                                  height: 50,
                                  marginBottom: 10,
                                  marginRight :15,
                                  marginTop: 10,
                                }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                              primary={`${campaignDetails?.topFundraiser?.name}`}
                              secondary={campaignDetails?.topFundraiser?.achieved_goal ?
                                  `${campaignDetails?.topFundraiser?.achieved_goal}.00  Raised` : "$0  Raised "}
                          />
                        </ListItem>
                      </div>


                    <div
                        style={{
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                            history.push(
                                `${campaignId}/t/${campaignDetails?.topTeam?.Team.name}_${campaignDetails?.topTeam?.Team.id}`
                            )
                        }
                    >
                      <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                      >
                        TOP TEAM
                      </Typography>

                      <ListItem style={{padding :0
                      }}>
                        <ListItemAvatar>
                          <Avatar
                              src={imageTopTeam}
                              style={{
                                width: 50,
                                height: 50,
                                marginBottom: 10,
                                marginRight :15,
                                marginTop: 10,
                              }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${ campaignDetails?.topTeam?.Team?.name}`}
                            secondary={campaignDetails?.topTeam?.achieved_goal_amount ?
                                `${campaignDetails?.topTeam?.achieved_goal_amount}.00
                                            Raised` : "$0  Raised "}
                        />
                      </ListItem>
                    </div>

                    <div
                        style={{
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                            history.push(
                                `${campaignId}/t/${campaignDetails?.topDonorsTeam?.name}_${campaignDetails?.topDonorsTeam?.id}`
                            )
                        }
                    >
                      <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                      >
                        MOST MEMBERS
                      </Typography>

                      <ListItem style={{padding :0
                      }}>
                        <ListItemAvatar>
                          <Avatar
                              src={imageTopDonorsTeam}
                              style={{
                                width: 50,
                                height: 50,
                                marginBottom: 10,
                                marginRight :15,
                                marginTop: 10,
                              }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${campaignDetails?.topDonorsTeam?.name}`}
                            secondary={campaignDetails?.topDonorsTeam?.donors_count ?
                                `${campaignDetails?.topDonorsTeam?.donors_count} Members` : "0  Members "}
                        />
                      </ListItem>
                    </div>

                    <div
                        style={{
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                            history.push(
                                `${campaignId}/t/${campaignDetails?.topDonorsTeam?.name}_${campaignDetails?.topDonorsTeam?.id}`
                            )
                        }
                    >
                      <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                      >
                        MOST DONORS
                      </Typography>

                      <ListItem style={{padding :0
                      }}>
                        <ListItemAvatar>
                          <Avatar
                              src={imageTopDonorsTeam}
                              style={{
                                width: 50,
                                height: 50,
                                marginBottom: 10,
                                marginRight :15,
                                marginTop: 10,
                              }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${campaignDetails?.topDonorsTeam?.name}`}
                            secondary={campaignDetails?.topDonorsTeam?.donors_count ?
                                `${campaignDetails?.topDonorsTeam?.donors_count} Donors` : "0  Donors "}
                        />
                      </ListItem>
                    </div>
                    <Divider style={{marginTop: 30 , marginBottom :20}}/>

                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      Team Success
                    </Typography>
                    <Typography variant="body2">
                      See the progress of the Teams
                    </Typography>
                    {campaignDetails &&
                    campaignDetails.teamGoals &&
                    campaignDetails.teamGoals.length ? (
                        campaignDetails.teamGoals
                            .slice(0)
                            .sort((a, b) => {
                              const x = parseInt(a.goal);
                              const y = parseInt(b.goal);
                              return x > y ? -1 : x < y ? 1 : 0;
                            })
                            .map((goal) => (
                                <div style={{marginTop : 15}}>
                                  <TopTeam  goal={goal} campaignId={campaignId} />
                                </div>
                            ))
                    ) : (
                        <ResourceNotFound />
                    )}
                    <Divider style={{marginTop: 30 , marginBottom :20}}/>

                    <Typography
                        variant="body1"
                        style={{
                          fontWeight: "bold",
                          marginTop: 20,
                          marginBottom: 10,
                        }}
                    >
                      Campaign Activity
                    </Typography>
                    <List>
                      {campaignDetails &&
                      campaignDetails?.donations &&
                      campaignDetails?.donations.length ? (
                          campaignDetails?.donations.map((donation) => (
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar
                                      style={{
                                        backgroundColor: getRandomColor(),
                                        height: 50,
                                        width: 50,
                                      }}
                                  >
                                    {capitalize(donation?.Donor?.full_name).charAt(0)}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={donation?.isAnonymous == "true" || donation?.isAnonymous == true ? "Anonymous" : ` ${capitalize(
                                        donation?.Donor?.full_name
                                    )}` }
                                    secondary={
                                      <div style={{display : "flex" }}>
                                        <Typography type="body2" style={{ color: colors.black , fontWeight : "bold" , marginRight : 10 }}> ${parseFloat(donation?.amount).toFixed(2) } </Typography>
                                        <div style={{height :6 , width : 6 , backgroundColor : colors.lightGrey , borderRadius : 3 , marginTop : 8}} ></div>
                                        <Typography type="body2" style={{ marginLeft : 10 }}> {moment(donation?.createdAt).fromNow()} </Typography>
                                      </div>
                                    }
                                />
                              </ListItem>
                          ))
                      ) : (
                          <ResourceNotFound message="No Activity found" />
                      )}
                    </List>


                  </div>

                </div>
            }
          <BecomeFundraiserDialog
            campaignId={campaignDetails?.id}
            open={isFundraisingDialogShowing}
            onJoin={() => setAdultJoinedCampaign(true)}
            onClose={() => showFundraisingDialog(false)}
          />
        </>
      )}
      <Footer />
    </div>
  );
}
