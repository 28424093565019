import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import ProgressButton from "components/ProgressButton";
import { useDispatch } from "react-redux";
import { addFaq } from "store/campaign";
import { updateFaq } from "store/campaign";

import { toast } from "react-toastify";

const CreateFAQDialog = ({ campaignId, open, faq, onClose }) => {
  const dispatch = useDispatch();
  const [inProgress, setProgress] = useState(false);
  const [qa, setQA] = useState({ question: "", answer: "" });
  useEffect(() => {
    if (faq) {
      setQA(faq);
    } else {
      setQA({ question: "", answer: "" });
    }
  }, [faq]);

  const handleSave = async () => {
    setProgress(true);
    if (faq === null) {
      await dispatch(addFaq(campaignId, qa));
    } else {
      await dispatch(updateFaq(faq.id, campaignId, qa));
    }
    setProgress(false);
    toast("Campaign FAQs updated", { type: "success" });
    setQA({ question: "", answer: "" });
    onClose();
  };

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>Create FAQ</DialogTitle>
      <DialogContent>
        <TextField
          label="Question"
          fullWidth
          variant="outlined"
          style={{ marginBottom: 10 }}
          value={qa.question}
          onChange={(event) => setQA({ ...qa, question: event.target.value })}
        />
        <TextField
          label="Answer"
          fullWidth
          variant="outlined"
          multiline
          rows={3}
          value={qa.answer}
          onChange={(event) => setQA({ ...qa, answer: event.target.value })}
          style={{ marginBottom: 10 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ProgressButton
          disabled={inProgress || !qa.question || !qa.answer}
          color="primary"
          onClick={handleSave}
          label="Save"
          showProgress={inProgress}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateFAQDialog;
