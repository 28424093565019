import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AdultListing from "views/admin/adult/AdultListing";
import AdultDetail from "views/admin/adult/AdultDetail";
import NewAdultForm from "views/admin/adult/NewAdultForm";
import NewMinorForm from "views/admin/adult/NewMinorForm";

const Adults = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <AdultListing />
      </Route>
      <Route path={`${path}/new`}>
        <NewAdultForm />
      </Route>
      <Route path={`${path}/:id/minor/:minorId`}>
        <NewMinorForm />
      </Route>
      <Route path={`${path}/:id`}>
        <AdultDetail />
      </Route>
    </Switch>
  );
};

export default Adults;
