import React, { useEffect, useState } from "react";
import ViewHeader from "components/ViewHeader";
import { Tab, Tabs } from "@material-ui/core";
import TabPanel from "components/TabPanel";
import CampaignFAQForm from "views/admin/campaign/form/CampaignFAQForm";
import CampaignDescriptionForm from "views/admin/campaign/form/CampaignDescriptionForm";
import CampaignGoalsForm from "views/admin/campaign/form/CampaignGoalsForm";
import { useParams } from "react-router-dom";
import { fetchOrganizations } from "store/organization";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading";
import CampaignAssetForm from "views/admin/campaign/form/CampaignAssetForm";
import campaignSlice, { fetchCampaignDetails, publish } from "store/campaign";
import ProgressButton from "../../../components/ProgressButton";
import { toast } from "react-toastify";

const CampaignForm = () => {
  const { orgId, campaignId } = useParams();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);

  const { data, inProgress, org } = useSelector((state) => state.organization);
  const { currentCampaign } = useSelector((state) => state.campaign);
  const { user } = useSelector((state) => state.auth);

  const [publishing, setPublishing] = useState(false);
  const [published, setPublished] = useState(false);

  useEffect(() => {
    dispatch(fetchOrganizations());
    if (campaignId) {
      dispatch(fetchCampaignDetails(campaignId));
    } else {
      dispatch(campaignSlice.actions.setCurrentCampaign(null));
    }
  }, [campaignId]);

  const handlePublish = async () => {
    setPublishing(true);
    await dispatch(publish(currentCampaign?.id));
    setPublishing(false);
    toast("Campaign Published successfully", { type: "success" });
    setPublished(true);
  };

  return (
    <div>
      <ViewHeader
        title={campaignId ? "Edit Campaign" : "New Campaign"}
        subtitle="Create new campaign or Edit an existing campaign"
      >
        <ProgressButton
          variant="contained"
          color="secondary"
          label="Publish Campaign"
          showProgress={publishing}
          onClick={handlePublish}
          disabled={
            published ||
            currentCampaign?.status === "published" ||
            !currentCampaign
          }
        />
      </ViewHeader>

      {inProgress && <Loading />}
      {data && data.length ? (
        <div style={{ marginTop: 30 }}>
          <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
            <Tab label="Description" />
            <Tab label="Assets" />
            <Tab label="FAQ" />
            <Tab label="Goals" />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <CampaignDescriptionForm
              orgId={orgId || user?.orgId || org?.id}
              campaign={currentCampaign}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <CampaignAssetForm campaignId={currentCampaign?.id} />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <CampaignFAQForm campaignId={currentCampaign?.id} />
          </TabPanel>
          <TabPanel value={tabIndex} index={3}>
            <CampaignGoalsForm campaignId={currentCampaign?.id} />
          </TabPanel>
        </div>
      ) : null}
    </div>
  );
};

export default CampaignForm;
