import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField, Typography,
} from "@material-ui/core";
import ProgressButton from "components/ProgressButton";
import { useDispatch, useSelector } from "react-redux";
import { addTeam, updateTem } from "store/team";
import AvatarPicker from "components/AvatarPicker";
import AvatarPickerDialog from "components/AvatarPickerDialog";

const CreateTeamForm = ({ orgId, open, onClose, team }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [inProgress, setProgress] = useState(false);
  const [manager, setManager] = useState("");
  const { data: managers } = useSelector((state) => state.manager);

  const [showImgPickerDialog, setShowImgPickerDialog] = useState(false);
  const [teamId, setTeamId] = useState(null);

  useEffect(() => {
    if (team) {
      setName(team.name);
      setManager(team.manager_id);
    }
  }, [team, managers]);

  const handleClose = () => {
    setName("");
    setManager("");
    onClose();
  };

  const handleSave = async () => {
    setProgress(true);
    const payload = {
      name,
      manager_id: manager,
    };
    if (team) {
      await dispatch(updateTem(orgId, { ...payload, id: team.id }));
      handleClose();
    } else {
      const response = await dispatch(addTeam(orgId, payload));
      setTeamId(response.team.id);
      setShowImgPickerDialog(true);
    }
    setProgress(false);
  };

  return (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{`${team ? "Edit" : "Add"} Team`}</DialogTitle>
        <DialogContent>
          {team && team.id && (
              <>
                <Typography variant="subtitle2" color="textPrimary" style={{marginBottom : 5}}>
                  Team Profile Image
                </Typography>
                <AvatarPicker
                    variant="outlined"
                    height={150}
                    width={150}
                    uniqueId={`team_${team.id}`}
                    style={{ marginBottom: 20 }}
                />
                <Typography variant="subtitle2" color="textPrimary" style={{marginBottom : 5}}>
                  Team Video
                </Typography>
                <AvatarPicker
                    isVideo={true}
                    variant="outlined"
                    height={100}
                    width={100}
                    uniqueId={`team_video_${team.id}`}
                    style={{ marginBottom: 20 }}
                />
              </>
          )}
          <TextField
              label="Team Name"
              variant="outlined"
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
          />
          <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
            <InputLabel>Select Manager</InputLabel>
            <Select
                label="Select Manager"
                value={manager}
                onChange={(e) => setManager(e.target.value)}
            >
              {managers.map((m) => (
                  <MenuItem value={m.id}>{m.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton
              color="primary"
              showProgress={inProgress}
              label={team ? "SAVE" : "CONTINUE"}
              onClick={handleSave}
              disabled={!name || !manager || inProgress}
          />
        </DialogActions>
        {!(team && team.id) && (
            <AvatarPickerDialog
                variant="outlined"
                uniqueId={`team_${teamId}`}
                open={showImgPickerDialog}
                onClose={() => {
                  setShowImgPickerDialog(false);
                  setTeamId(null);
                  handleClose();
                }}
            />
        )}
      </Dialog>
  );
};

export default CreateTeamForm;
