import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Alert } from "@material-ui/lab";
import { isValidEmail } from "utils";
import { addAdult, joinCampaignAsFundraiser } from "store/adult";
import { toast } from "react-toastify";
import LoginDialog from "components/LoginDialog";
import firebase from "firebase";
import { Role } from "utils/roles";
import {useMediaQuery} from "react-responsive";

const BecomeFundraiserDialog = ({ campaignId, open, onClose, onJoin }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [inProgress, setProgress] = useState(false);
  const [error, setError] = useState(null);
  const [isLoginShowing, showLogin] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const handleClose = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setError(null);
    setProgress(false);
    onClose();
  };

  const handleRegisterFundraiser = async (e) => {
    setError(null);
    setProgress(true);
    const payload = {
      name: `${firstName} ${lastName}`,
      email,
      password,
    };
    const { error, adult } = await dispatch(addAdult(payload));
    if (error) {
      toast(error, {
        type: "error",
      });
    } else {
      const response = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      await firebase
        .firestore()
        .collection("users")
        .doc(response.user.uid)
        .set({ role: Role.Adult, systemId: adult.id });
      await dispatch(joinCampaignAsFundraiser(adult.id, campaignId));
      toast(`Account Registered Successfully`, {
        type: "success",
      });
      onJoin && onJoin(); // letting campaign detail know that it's being joined at the moment
    }
    setProgress(false);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWith
        PaperProps={{
          style: {
            borderRadius: 25,
          },
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            style={{ fontWeight: "bold", marginTop: 17, marginBottom: 17 }}
            variant="h6"
          >
            Sign up
          </Typography>
        </div>
        <Divider />
        <DialogContent>
          <div style={{ paddingLeft: isDesktopOrLaptop ? 100 : 0, paddingRight: isDesktopOrLaptop ? 100 : 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 1, marginRight: 10 }}>
                  <Typography
                    variant="body2"
                    style={{ marginTop: 10, fontWeight: "bold" }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    style={{ marginTop: 10 }}
                    placeholder="Sam"
                    variant="outlined"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div style={{ flex: 1, marginLeft: 10 }}>
                  <Typography
                    variant="body2"
                    style={{ marginTop: 10, fontWeight: "bold" }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    style={{ marginTop: 10 }}
                    placeholder="Smith"
                    variant="outlined"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <Typography
                variant="body2"
                style={{ marginTop: 10, fontWeight: "bold" }}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                style={{ marginTop: 10 }}
                placeholder="example@gmail.com"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Typography
                variant="body2"
                style={{ marginTop: 10, fontWeight: "bold" }}
              >
                Password
              </Typography>
              <TextField
                fullWidth
                style={{ marginTop: 10 }}
                placeholder="***********"
                type="password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                fullWidth
                style={{
                  marginTop: 20,
                  marginBottom: 55,
                  textTransform: "none",
                  color: "white",
                  fontWeight: "bold",
                  height: 55,
                }}
                variant="contained"
                disableElevation
                size="large"
                color="primary"
                disabled={
                  inProgress ||
                  !firstName ||
                  !lastName ||
                  !isValidEmail(email) ||
                  !password ||
                  password.length < 8
                }
                onClick={(e) => handleRegisterFundraiser(e)}
              >
                Sign up
              </Button>
            </div>
          </div>
        </DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
      </Dialog>
      <LoginDialog open={isLoginShowing} onClose={() => showLogin(false)} />
    </>
  );
};

export default BecomeFundraiserDialog;
