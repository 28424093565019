import React, { useEffect } from "react";
import ViewHeader from "components/ViewHeader";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { fetchAdults } from "store/organization";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading";
import ResourceNotFound from "components/ResourceNotFound";
import MUIDataTable from "mui-datatables";
import { Paper } from "@material-ui/core";

const AdultListing = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { adults, inProgress } = useSelector((state) => state.organization);

  useEffect(() => {
    dispatch(fetchAdults());
  }, [dispatch]);

  const tableHead = ["Name", "Email", "Actions"];
  const formatTableData = (adults) =>
    adults.map((adult) => [
      adult.name,
      adult.email,
      <RemoveRedEyeOutlined
        color="action"
        style={{ cursor: "pointer" }}
        onClick={() => history.push(`${location.pathname}/${adult.id}`)}
      />,
    ]);
  const options = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    elevation: 0,
  };
  return (
    <>
      <ViewHeader title="Adults" subtitle="Manage Adults and their Kids" />
      {inProgress && <Loading />}
      {adults && adults.length ? (
        // <CustomTable
        //   tableHead={tableHead}
        //   tableData={formatTableData(adults)}
        //   style={{ marginTop: 25 }}
        // />
        <Paper variant="outlined" style={{ marginTop: 20 }}>
          <MUIDataTable
            data={formatTableData(adults)}
            columns={tableHead}
            options={options}
          />
        </Paper>
      ) : (
        !inProgress && <ResourceNotFound />
      )}
    </>
  );
};

export default AdultListing;
