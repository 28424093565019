import React from "react";
import { colors } from "utils/colors";
import Typography from "@material-ui/core/Typography";
import { Mail , Instagram , Facebook } from "@material-ui/icons";
import { Button, Divider, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import AppbarLogo from "assets/appbar-logo.svg";
import AppleLogo from "assets/downloadfromapple.png";
import GoogleLogo from "assets/downloadfromgoogle.png";
import Linkedin from "assets/linkedin.png";
import Medium from "assets/medium.png";

import Twitter from "assets/twitter.png";
import Youtube from "assets/youtube.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.default,
    border: "none",
  },
    downloadButton: {
        height: 50,
        width: 142,
        cursor: "pointer",
        float: "right",
    },
    logo: {
        height: 48,
        width: 48,
        cursor: "pointer",
    },
  toolbar: {
    backgroundColor: theme.palette.background.default,
    color: colors.dark,
    marginLeft: 50,
    marginRight: 50,
    marginTop: 15,
    marginBottom: 15,
  },
}));

const DesktopFooter = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div
      style={{
        marginTop: 25,
        padding: 0,
        backgroundColor: "#191B3E",
        height: 210,
      }}
    >
        <Grid
            container
            xs={12}
            lg={12}
            spacing={4}
            style={{ paddingLeft: 155, paddingRight: 135, paddingTop: 45 }}
        >
            <Grid item xs={12} lg={4} >
                <img
                    className={classes.logo}
                    alt="Remy Sharp"
                    src={AppbarLogo}
                    onClick={() => history.push("/")}
                />
                <span style={{ flexGrow: 1 }} />
            </Grid>
            <Grid item xs={12} lg={4}>
                <div style={{marginLeft : 40}}>
                    <img
                        className={classes.downloadButton}
                        alt="Remy Sharp"
                        src={GoogleLogo}
                        onClick={()=>{
                            window.open("https://links.bamrec.com/Android", "_blank")
                        }}
                    />
                    <img
                        className={classes.downloadButton}
                        alt="Remy Sharp"
                        src={AppleLogo}
                        style={{ marginRight: 10 }}
                        onClick={()=>{
                            window.open("https://links.bamrec.com/AppleiOS", "_blank")
                        }}
                    />
                </div>
            </Grid>
            <Divider
          light={true}
          style={{ backgroundColor: "#2F304F", marginTop: 30 }}
        />
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            style={{ marginTop: 30 }}
          >
            <Typography
              variant="h6"
              style={{
                color: "#FFFFFF",
                fontWeight: "bold",
                fontFamily: "Raleway",
              }}
            >
              Get in touch
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{
                color: "#FFFFFF",
                marginTop: 20,
                fontWeight: "400",
                fontFamily: "Raleway",
              }}
            >
              help@bamrec.com
            </Typography>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              startIcon={<Mail />}
              size="medium"
              style={{
                color: colors.white,
                marginTop: 20,
                fontWeight: "bold",
                textTransform: "none",
              }}
            >
              Contact form
            </Button>
            </Grid>
            <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            style={{ marginTop: 30 }}
          >
            <Typography
              variant="h6"
              style={{
                color: "#FFFFFF",
                fontWeight: "bold",
                fontFamily: "Raleway",
              }}
            >
              Our Company
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{
                color: "#FFFFFF",
                marginTop: 20,
                fontWeight: "400",
                fontFamily: "Raleway",
              }}
            >
              About Us
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{
                color: "#FFFFFF",
                marginTop: 10,
                fontWeight: "400",
                fontFamily: "Raleway",
              }}
            >
              Careers
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{
                color: "#FFFFFF",
                marginTop: 10,
                fontWeight: "400",
                fontFamily: "Raleway",
              }}
            >
              Press
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            style={{ marginTop: 30 }}
          >
            <Typography
              variant="h6"
              style={{
                color: "#FFFFFF",
                fontWeight: "bold",
                fontFamily: "Raleway",
              }}
            >
              Resources
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{
                color: "#FFFFFF",
                marginTop: 18,
                fontWeight: "400",
                fontFamily: "Raleway",
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{
                color: "#FFFFFF",
                marginTop: 20,
                fontWeight: "400",
                fontFamily: "Raleway",
              }}
            >
              Terms of Use
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            style={{ marginTop: 0 }}
          >
            <Typography
              variant="h6"
              style={{
                color: "#FFFFFF",
                fontWeight: "bold",
                fontFamily: "Raleway",
              }}
            >
              Follow Us
            </Typography>
            <Grid item xs={12} lg={4}>
            <div style={{float : "right", marginTop: 22}}>
                    <img
                        alt="Remy Sharp"
                        src={Linkedin}
                        onClick={()=>{
                            window.open("https://www.linkedin.com/company/bamrec", "_blank")
                        }}
                        style={{ height: 30, width: 30,
                            cursor: "pointer" }}
                    />
                    <Instagram
                        onClick={()=>{
                            window.open("https://www.instagram.com/bamrecreation/", "_blank")
                        }}
                        style={{
                            color :"white",
                            marginLeft: 10,
                            cursor: "pointer",
                            height: 30,
                            width: 30,
                        }}
                    >
                    </Instagram>
                    <img
                        alt="Remy Sharp"
                        src={Youtube}
                        onClick={()=>{
                            window.open("https://www.youtube.com/channel/UCByR7G37fEJd7sQ5ILdOpeA", "_blank")
                        }}
                        style={{
                            marginLeft: 10,
                            cursor: "pointer",
                            height: 30,
                            width: 30,
                        }}
                    />
                    <Facebook
                        onClick={()=>{
                            window.open("https://www.facebook.com/BayAreaMuslimRecreation/", "_blank")
                        }}
                        style={{
                            color :"white",
                            marginLeft: 10,
                            cursor: "pointer",
                            height: 30,
                            width: 30,
                        }}
                    >
                    </Facebook>
                </div>
            </Grid>
        </Grid>
      <div style={{ paddingLeft: 135, paddingRight: 135}}>

        <Divider
          light={true}
          style={{ backgroundColor: "#2F304F", marginTop: 35 }}
        />

        <div>
          <Typography
            variant="subtitle1"
            component="p"
            style={{
              color: colors.white,
              marginTop: 10,
            }}
          >
            © Copyright 2021 Bamrec - All Rights Reserved
          </Typography>
        </div>
      </div>
      </Grid>
      </Grid>
      </div>
  );
};
const MobileFooter = () => {
    const history = useHistory();
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "#191B3E", padding: 20 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            className={classes.logo}
            alt="Remy Sharp"
            src={AppbarLogo}
            onClick={() => history.push("/")}
          />
        </div>
        <div style={{ display: "flex", marginTop: 20 , justifyContent: "center" }}>
          <img
            style={{ height: 50, width: 142, cursor: "pointer" }}
            alt="Remy Sharp"
            src={AppleLogo}
            onClick={()=>{
                window.open("https://links.bamrec.com/AppleiOS", "_blank")
            }}
          />
          <img
            style={{ height: 50, width: 142, cursor: "pointer" }}
            alt="Remy Sharp"
            src={GoogleLogo}
            onClick={()=>{
                window.open("https://links.bamrec.com/Android", "_blank")
            }}
          />
        </div>
        <div style={{ display: "flex", marginTop: 20 , justifyContent: "center" }}>
        <Divider
          light={true}
          style={{ backgroundColor: "#2F304F", marginTop: 30 }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <Typography
          style={{ color: colors.white, fontWeight: "bold" }}
          variant="h6"
        >
          Get In Touch
        </Typography>
        <Typography variant="body2" style={{ color: colors.lightGrey }}>
          help@bamrec.com
        </Typography>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          startIcon={<Mail />}
          size="medium"
          style={{
            color: colors.white,
            marginTop: 10,
            fontWeight: "bold",
            textTransform: "none",
          }}
        >
          Contact form
        </Button>
        <Typography
          variant="h6"
          style={{ color: colors.white, fontWeight: "bold", marginTop: 25 }}
        >
          Our Company
        </Typography>
        <Typography
          variant="body2"
          style={{ color: colors.lightGrey, marginTop: 10 }}
        >
          About Us
        </Typography>
        <Typography
          variant="body2"
          style={{ color: colors.lightGrey, marginTop: 10 }}
        >
          Carriers
        </Typography>
        <Typography
          variant="body2"
          style={{ color: colors.lightGrey, marginTop: 10 }}
        >
          Press
        </Typography>
        <Typography
          variant="h6"
          style={{ color: colors.white, fontWeight: "bold", marginTop: 25 }}
        >
          Resources
        </Typography>
        <Typography
          variant="body2"
          style={{ color: colors.lightGrey, marginTop: 10 }}
        >
          Privacy Policy
        </Typography>
        <Typography
          variant="body2"
          style={{ color: colors.lightGrey, marginTop: 10 }}
        >
          Terms of Use
        </Typography>
        <Typography
          variant="h6"
          style={{ color: colors.white, fontWeight: "bold", marginTop: 25 }}
        >
          Follow us
        </Typography>
        <div style={{ marginTop: 10 }}>
          <img
            alt="Remy Sharp"
            src={Linkedin}
            onClick={()=>{
                window.open("https://www.linkedin.com/company/bamrec", "_blank")
            }}
            style={{ height: 30, width: 30 }}
          />
            <Instagram
                onClick={()=>{
                    window.open("https://www.instagram.com/bamrecreation/", "_blank")
                }}
                style={{
                    color :"white",
                    marginLeft: 10,
                    cursor: "pointer",
                    height: 30,
                    width: 30,
                }}
            >
            </Instagram>
          <img
            alt="Remy Sharp"
            src={Youtube}
            onClick={()=>{
                window.open("https://www.youtube.com/channel/UCByR7G37fEJd7sQ5ILdOpeA", "_blank")
            }}
            style={{
              marginLeft: 10,
              cursor: "pointer",
              height: 30,
              width: 30,
            }}
          />
            <Facebook
                onClick={()=>{
                    window.open("https://www.facebook.com/BayAreaMuslimRecreation/", "_blank")
                }}
                style={{
                    color :"white",
                    marginLeft: 10,
                    cursor: "pointer",
                    height: 30,
                    width: 30,
                }}
            >
            </Facebook>
        </div>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
          <Divider
              light={true}
              style={{ backgroundColor: "#2F304F", marginTop: 30 }}
          />
        <Typography
          variant="subtitle2"
          style={{
            color: colors.lightGrey,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          © Copyright 2021 Bamrec - All Rights Reserved
        </Typography>
      </div>
    </div>
  );
};
const Footer = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      {isDesktopOrLaptop && <DesktopFooter />}
      {isTabletOrMobile && <MobileFooter/>}

    </>
  );
};

export default Footer;
