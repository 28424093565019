import React, { useEffect, useState } from "react";
import {
    Avatar,
    capitalize,
    Container, Divider,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles, Paper,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import TeamCard from "views/public/team/TeamCard";
import TabPanel from "components/TabPanel";
import UserAvatar from "components/UserAvatar";
import AdminTopBar from "components/AdminTopBar";
import { useHistory, useParams } from "react-router-dom";
import { fetchTeam } from "store/team";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading";
import ResourceNotFound from "components/ResourceNotFound";
import { storageRef } from "index";
import moment from "moment";
import { colors, getRandomColor } from "utils/colors";
import Footer from "../../../components/Footer";
import RewardComponent from "../reward/RewardComponent";
import {ArrowForward} from "@material-ui/icons";
import ReactPlayer from "react-player";

const Member = ({ teamId, member }) => {
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    downloadImage();
  }, []);

  const downloadImage = async () => {
    const url = await storageRef.child(`minor_${member.id}`).getDownloadURL();
    setImageUrl(url);
  };

  return (
    <Grid item xs={6} sm={6} md={6} lg={3} xl={3} >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <UserAvatar
          image={imageUrl}
          title={member.name}
          onClick={() =>
            history.push(`${teamId}/f/${member?.name}_${member.id}`)
          }
        />
        <LinearProgress
          style={{
            width: "60%",
            height: 4,
            borderRadius: 15,
            marginTop: 5,
            marginBottom: 5,
          }}
          variant="determinate"
          value={
            member.achieved_goal ? (parseInt(member.achieved_goal) / parseInt(member.goal)) * 100 > 100 ? 100 : (parseInt(member.achieved_goal) / parseInt(member.goal)) * 100  : 0
          }
          color="secondary"
        />
        <Typography variant="body2">{`$${
          member.achieved_goal ? member.achieved_goal : 0
        }/$${member.goal}`}</Typography>
      </div>
    </Grid>
  );
};

const Members = ({ teamId, teamDetail }) => {
  if (teamDetail?.minors?.length) {
    return (
      <Grid
        container
        spacing={2}

        style={{
          marginTop: 10,
        }}
      >
        {teamDetail?.minors
          .slice(0)
          .sort((a, b) => (a.goal > b.goal ? -1 : a.goal < b.goal ? 1 : 0))
          .map((member) => (
            <Member member={member} teamId={teamId} />
          ))}
      </Grid>
    );
  } else {
    return (
      <Container style={{ marginTop: 15 }}>
        <ResourceNotFound />
      </Container>
    );
  }
};
export const Updates = ({ updates }) => {
  return (
    <List>
      {updates && updates.length ? (
        updates.map((donation) => (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                style={{
                  height: 43,
                  width: 43,
                  backgroundColor: getRandomColor(),
                }}
              >
                {capitalize(donation?.Donor?.full_name).charAt(0)}
              </Avatar>
            </ListItemAvatar>
              <ListItemText
                  primary={donation?.isAnonymous == "true" || donation?.isAnonymous == true ? "Anonymous" : ` ${capitalize(
                      donation?.Donor?.full_name
                  )}` }
                  secondary={
                      <div style={{display : "flex" }}>
                          <Typography type="body2" style={{ color: colors.black , fontWeight : "bold" , marginRight : 10 }}> ${parseFloat(donation?.amount).toFixed(2) } </Typography>
                          <div style={{height :6 , width : 6 , backgroundColor : colors.lightGrey , borderRadius : 3 , marginTop : 8}} ></div>
                          <Typography type="body2" style={{ marginLeft : 10 }}> {moment(donation?.createdAt).fromNow()} </Typography>
                      </div>
                  }
              />
          </ListItem>
        ))
      ) : (
        <ResourceNotFound />
      )}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
}));

export default function TeamDetail() {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const { team_id } = useParams();
  const teamId = team_id.split("_")[1];
  const { inProgress, team: teamDetail } = useSelector((state) => state.team);
  const [teamVideo, setTeamVideo] = useState(null);


  const dispatch = useDispatch();
  useEffect(() => {
    if (teamId) {
      dispatch(fetchTeam(teamId));
    }
  }, [dispatch, teamId]);

    useEffect(() => {
        downloadVideo();
    }, [teamDetail ]);
    const downloadVideo = async () => {
        try {
            try {
                const teamVideo = `team_video_${teamId}`;
                const teamVideoUrl = await storageRef.child(teamVideo).getDownloadURL();
                setTeamVideo(teamVideoUrl);
            } catch (e) {}
        } catch (err) {}
    };


  return (
    <div>
      <AdminTopBar />
      <div className={classes.toolbar} />
      {inProgress && !teamDetail ? (
        <Container>
          <Loading />
        </Container>
      ) : (
        <>
          <Container maxWidth="lg" style={{ marginTop: 1 , marginBottom : 10 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                <TeamCard teamDetail={teamDetail} />
                  <Grid item xl={12} lg={12} style={{marginTop : 30}} >
                      <Paper elevation={2} style={{ paddingBottom : 15 , paddingTop: 25, borderRadius: 20 , paddingRight : 10 }}>
                          <Typography variant="h5">
                            <span style={{ fontWeight: "bold" , paddingLeft : 25 , paddingRight : 25}}>
                              Get a Reward
                            </span>
                          </Typography>

                          {teamDetail &&
                          teamDetail.rewards &&
                          teamDetail.rewards.length ? (
                              teamDetail.rewards
                                  .slice(0)
                                  .sort((a, b) => {
                                      const x = parseInt(a.end_date);
                                      const y = parseInt(b.end_date);
                                      return x > y ? -1 : x < y ? 1 : 0;
                                  })
                                  .map((reward) => (
                                      <div style={{marginTop : 15}}>
                                          <RewardComponent reward={reward} achievedAmount={reward?.teamAchievedAmount}/>
                                      </div>
                                  ))
                          ) : (
                              <ResourceNotFound />
                          )}
                      </Paper>
                  </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  {teamVideo && <ReactPlayer url={teamVideo} controls={true}  style={{marginTop : 30}} />}

                <Tabs
                  TabScrollButtonProps={{ style: { background: colors.black } }}
                  value={tabIndex}
                  onChange={(e, index) => setTabIndex(index)}
                  style={{
                    marginTop: 20,
                    borderBottom: `0.5px solid ${colors.offwhite}`,
                  }}
                >
                  <Tab label="Members" />
                  <Tab label="Updates" />
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                  <Members teamId={team_id} teamDetail={teamDetail} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <Updates updates={teamDetail?.donations} />
                </TabPanel>
              </Grid>
            </Grid>
          </Container>
            <Footer/>
        </>
      )}
    </div>
  );
}
