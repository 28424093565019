import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  capitalize,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import ViewHeader from "components/ViewHeader";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignDetails, publish } from "store/campaign";
import Loading from "components/Loading";
import LabelValue from "components/LabelValue";
import ResourceNotFound from "components/ResourceNotFound";
import { Alert, AlertTitle } from "@material-ui/lab";
import ProgressButton from "components/ProgressButton";
import { toast } from "react-toastify";

const calculateIssuesToPublish = (campaign) => {
  const issues = [];
  if (campaign) {
    if (!campaign.faqs || !campaign.faqs.length) {
      issues.push("Please add at-least one Frequently Asked Question");
    }
    if (!campaign.teamGoals || !campaign.teamGoals.length) {
      issues.push("Please add goal to at-least one team");
    }
  }
  return issues;
};

const CampaignDetail = () => {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const [issuesToPublish, setIssuesToPublish] = useState([]);
  const { inProgress, currentCampaign: campaignDetails } = useSelector(
    (state) => state.campaign
  );
  const [publishing, setPublishing] = useState(false);
  const [published, setPublished] = useState(false);

  useEffect(() => {
    if (campaignId) {
      dispatch(fetchCampaignDetails(campaignId));
    }
  }, [dispatch, campaignId]);

  useEffect(() => {
    if (campaignDetails) {
      setIssuesToPublish(calculateIssuesToPublish(campaignDetails));
    }
  }, [campaignDetails]);

  const handlePublish = async () => {
    setPublishing(true);
    await dispatch(publish(campaignDetails.id));
    setPublishing(false);
    toast("Campaign Published successfully", { type: "success" });
    setPublished(true);
  };

  if (inProgress) {
    return <Loading />;
  }

  return (
    <div>
      <ViewHeader
        title={capitalize(campaignDetails?.title || "")}
        subtitle={campaignDetails?.website}
      >
        <ProgressButton
          showProgress={publishing}
          variant="contained"
          color="secondary"
          disabled={
            issuesToPublish.length ||
            campaignDetails?.status === "published" ||
            published
          }
          label="Publish Campaign"
          onClick={handlePublish}
        />
      </ViewHeader>
      <div style={{ marginTop: 10 }}>
        {campaignDetails?.status === "published" || published ? (
          <Alert>Campaign has been published</Alert>
        ) : !issuesToPublish.length ? (
          <Alert severity="info">
            Campaign has all the required information, thus can be published.
          </Alert>
        ) : (
          <Alert severity="warning">
            <AlertTitle>Cannot Publish Campaign</AlertTitle>
            {issuesToPublish.join(" and ")}
          </Alert>
        )}
      </div>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12} lg={6}>
          <Paper variant="outlined" style={{ padding: 20 }}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginBottom: 15 }}
            >
              Campaign Details
            </Typography>
            <LabelValue
              label="Description"
              value={campaignDetails?.description}
            />
            <LabelValue
              label="Thank you Message"
              value={campaignDetails?.thank_message}
            />
            <LabelValue
              label="School Goal"
              value={`$${campaignDetails?.total_goal_amount}`}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper variant="outlined" style={{ padding: 20 }}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginBottom: 15 }}
            >
              Campaign Manager
            </Typography>
            <LabelValue
              label="Name"
              value={`${campaignDetails?.Manager?.name}`}
            />
            <LabelValue
              label="Email"
              value={`${campaignDetails?.Manager?.email}`}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper variant="outlined" style={{ padding: 20 }}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginBottom: 15 }}
            >
              Frequently Asked Questions
            </Typography>
            {campaignDetails &&
            campaignDetails.faqs &&
            campaignDetails.faqs.length ? (
              campaignDetails.faqs.map((faq) => (
                <Accordion>
                  <AccordionSummary>{faq.question}</AccordionSummary>
                  <AccordionDetails>{faq.answer}</AccordionDetails>
                </Accordion>
              ))
            ) : (
              <ResourceNotFound />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper variant="outlined" style={{ padding: 20 }}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginBottom: 15 }}
            >
              Team Goals
            </Typography>
            {campaignDetails &&
            campaignDetails.teamGoals &&
            campaignDetails.teamGoals.length ? (
              campaignDetails.teamGoals.map((goal) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 15,
                    paddingRight: 50,
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body2">{goal?.Team?.name}</Typography>
                    <Typography variant="caption">
                      20% of ${goal?.goal}
                    </Typography>
                  </div>
                  <LinearProgress
                    variant="determinate"
                    value={goal.progress}
                    color="secondary"
                  />
                </div>
              ))
            ) : (
              <ResourceNotFound />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CampaignDetail;
