import React, { useEffect, useState } from "react";
import ViewHeader from "components/ViewHeader";
import {
    Button, Card,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignsOfOrganizations } from "store/campaign";
import { fetchTeams } from "store/team";
import { donateOffline } from "store/donation";
import Loading from "components/Loading";
import ProgressButton from "components/ProgressButton";
import { toast } from "react-toastify";
import { fetchOrganizations } from "../../../store/organization";
import {useHistory, useParams} from "react-router-dom";
import { MuiPickersUtilsProvider , KeyboardDateTimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {addReward, fetchRewardDetails, updateReward} from "../../../store/reward";
import AvatarPicker from "../../../components/AvatarPicker";

const RewardForm = () => {
    const { rewardId } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();

    const { data, inProgress } = useSelector((state) => state.campaign);
    const { data: organizations } = useSelector((state) => state.organization);
    const { currentReward : rewardDetails } = useSelector((state) => state.reward);


    const [selectedOrganization, setSelectedOrganization] = useState("");
    const [readyToGetCampaign, setReadyToGetCampaign] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState("");
    const [selectedCampaignOrganization, setSelectedCampaignOrganization] = useState("");
    const [title, setTitle] = useState("");
    const [goal, setGoal] = useState("");
    const [isMaxRecipientEnable, setIsMaxRecipientEnable] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [isTypePerson, setIsTypePerson] = useState(true);
    const [isTypeTeam, setIsTypeTeam] = useState(false);
    const [isTypeCampaign, setIsTypeCampaign] = useState(false);
    const [rewardAim, setRewardAim] = useState("fundraising");
    const [isAimFundraising, setIsAimFundraising] = useState(true);

    const [maxRecipient, setMaxRecipient] = useState("");

    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [isDetailsAdded, setIsDetailsAdded] = useState(false);
    const [newReward, setNewReward] = useState(null);


    useEffect(async () => {
        if(rewardId){
            await dispatch(fetchRewardDetails(rewardId));
        }
    }, [rewardId]);

    useEffect(async () => {
        if(rewardId){
           setSelectedOrganization(rewardDetails?.organization_id)
            await dispatch(fetchCampaignsOfOrganizations(rewardDetails?.organization_id));
            setReadyToGetCampaign(false);
        }
    }, [rewardDetails]);

    useEffect(async () => {
        if(rewardId){
            setSelectedCampaign(rewardDetails?.campaign_id)
            setStartDate(rewardDetails?.start_date);
            setEndDate(rewardDetails?.end_date);
            setTitle(rewardDetails?.title);
            setGoal(rewardDetails?.goal);
            if(rewardDetails?.reward_aim === "video"){
                setIsAimFundraising(false);
            }
            setRewardAim()
            if(rewardDetails?.type == "team"){
                setIsTypeTeam(true);
                setIsTypeCampaign(false);
                setIsTypePerson(false);
            }else if(rewardDetails?.type == "person"){
                setIsTypeTeam(false);
                setIsTypeCampaign(false);
                setIsTypePerson(true);
            }else{
                setIsTypeTeam(false);
                setIsTypeCampaign(true);
                setIsTypePerson(false);
            }
            if(rewardDetails?.maximum_recipients !== 0){
                setMaxRecipient(rewardDetails?.maximum_recipients)
            }
            if(rewardDetails?.have_maximum_recipients === "true"){
                setIsMaxRecipientEnable(true)
            }else{
                setIsMaxRecipientEnable(false)

            }
        }
    }, [rewardDetails]);


    useEffect(async () => {
        await dispatch(fetchOrganizations());
    }, []);
    useEffect(async () => {
        if (organizations) {
            await dispatch(fetchCampaignsOfOrganizations(selectedOrganization));
            setReadyToGetCampaign(false);
        }
    }, [readyToGetCampaign]);
    useEffect(async () => {
        await dispatch(fetchTeams(selectedCampaignOrganization, true));
    }, [selectedCampaign]);


    const handleSave = async () => {
        let rewardType
        if(isTypeCampaign){
            rewardType = "campaign"
        }else if(isTypePerson){
            rewardType = "person"
        }else{
            rewardType = "team"
        }
        const payload = {
            title : title,
            organization_id : selectedOrganization,
            campaign_id : selectedCampaign,
            goal : goal ,
            type : rewardType ,
            start_date : startDate,
            end_date : endDate,
            maximum_recipients : maxRecipient ,
            have_maximum_recipients : isMaxRecipientEnable,
            isAimFundraising : isAimFundraising,
        };

        setSaving(true);
        console.log("payload" , payload)
        if(rewardId){
            const updatedReward = await dispatch(updateReward(rewardId,payload));
        }else{
            const newCreatedReward = await dispatch(addReward(payload));
            setNewReward(newCreatedReward?.reward?.id)
            setIsDetailsAdded(true);
        }
        setSaving(false);
        toast(`Reward ${rewardId ? "Updated" : "Created"} successfully`, { type: "success" });
        setSaved(true);
        if(rewardId){
            history.goBack()
        }
    };

    if (inProgress) {
        return <Loading />;
    }

    return (
        <div>
            <ViewHeader
                title={rewardId ? "Edit Reward" : "New Reward"}
                subtitle="Create and Manage Rewards"
            >
                {/*{!isDetailsAdded ? <Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    size="medium"*/}
                {/*    disableElevation*/}
                {/*    style={{*/}
                {/*        color : "white",*/}
                {/*        fontWeight: "bold",*/}
                {/*        textTransform: "none",*/}
                {/*        marginRight : 10*/}
                {/*    }}*/}
                {/*    onClick={async () => {history.goBack()}}*/}
                {/*>*/}
                {/*    Save draft*/}
                {/*</Button> : <></>}*/}
            </ViewHeader>
            {!isDetailsAdded ? <>
                <div style={{ marginTop: 30 }}>
                    <Typography variant="h6" color="primary">
                        Campaign Information
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={6} lg={12}>
                            <Typography style={{fontWeight : "bold" , marginBottom : 10}} variant="body2" color="textPrimary">
                                Select Organization
                            </Typography>
                            <FormControl style={{width :400}} variant="outlined">
                                <InputLabel>Select Organization</InputLabel>
                                <Select
                                    disabled={rewardId}
                                    value={selectedOrganization}
                                    label="Select Organization"
                                    onChange={(e) => {
                                        setSelectedOrganization(e.target.value);
                                        setReadyToGetCampaign(true);
                                        setSelectedCampaign("")
                                        setSelectedCampaignOrganization("")
                                    }}
                                >
                                    {organizations
                                        ?.filter((o) => o.managers && o.managers.length)
                                        .map((o) => (
                                            <MenuItem value={o.id} key={o.id}>
                                                {o.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} lg={12}>
                            <Typography style={{fontWeight : "bold" , marginBottom : 10}} variant="body2" color="textPrimary">
                                Select Campaign
                            </Typography>
                            <FormControl style={{width :400}} variant="outlined">
                                <InputLabel>Select Campaign</InputLabel>
                                <Select
                                    value={selectedCampaign}
                                    disabled={rewardId}
                                    label="Select Campaign"
                                    onChange={(e) => {
                                        setSelectedCampaign(e.target.value);
                                        let selectedCam = data.find((x) => x.id === e.target.value);
                                        setSelectedCampaignOrganization(selectedCam?.organization_id);
                                    }}
                                >
                                    {data &&
                                        data
                                            .filter((o) => o.status && o.status === "published")
                                            .map((o) => (
                                                <MenuItem value={o.id} key={o.id}>
                                                    {o.title}
                                                </MenuItem>
                                            ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div style={{display :"flex" , marginTop : 30 , paddingRight : 70}}>

                    <Grid item xs={12} lg={4} >
                        <Typography style={{fontWeight : "bold" , marginBottom : 5}} variant="body2" color="textPrimary">
                            Reward Start Date
                        </Typography>
                        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                            <KeyboardDateTimePicker

                                clearable
                                value={startDate}
                                onChange={(date) => {
                                    setStartDate(date)
                                    if(!rewardId){
                                        setEndDate(date)
                                    }
                                }}
                                format="MM/dd/yyyy hh:mm a"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Typography style={{fontWeight : "bold" , marginBottom : 5}} variant="body2" color="textPrimary">
                            Reward End Date
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                clearable
                                value={endDate}
                                onChange={(date) => setEndDate(date)}
                                format="MM/dd/yyyy hh:mm a"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </div>

                <div style={{ marginTop: 30 }}>
                    <Typography variant="h6" color="primary">
                        Reward Information
                    </Typography>

                        <Grid item xl={6} lg={12}>
                            <Typography style={{fontWeight : "bold" , marginBottom : 5 , marginTop : 10}} variant="body2" color="textPrimary">
                                Title
                            </Typography>
                            <TextField
                                style={{width : 600}}
                                fullWidth
                                value={title}
                                placeholder="Reward Title"
                                variant="outlined"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Grid>
                    <div style={{marginTop : 20}}>
                        <Typography variant="h6" color="primary">
                            Reward Aim
                        </Typography>

                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xl={12} lg={6}>
                                <div style={{ display: "flex", justifyContent: "left"}}>
                                    <RadioGroup>
                                        <FormControlLabel
                                            control={<Radio />}
                                            label="Fundraising"
                                            disabled={rewardId}
                                            checked={isAimFundraising}
                                            onClick={() => {
                                               setRewardAim("fundraising")
                                               setIsAimFundraising(true);
                                            }}
                                        />
                                    </RadioGroup>
                                    <RadioGroup>
                                        <FormControlLabel
                                            control={<Radio />}
                                            label="Upload Video"
                                            disabled={rewardId}
                                            checked={!isAimFundraising}
                                            onClick={() => {
                                                setRewardAim("video")
                                                setIsAimFundraising(false);
                                                setIsTypePerson(true);
                                                setIsTypeTeam(false);
                                                setIsTypeCampaign(false)
                                            }}
                                        />
                                    </RadioGroup>
                                </div>

                            </Grid>
                        </Grid>
                    </div>
                    {rewardAim === "fundraising" ? <Grid item xl={6} lg={2}>
                        <Typography style={{fontWeight : "bold" , marginBottom : 5 , marginTop : 10}} variant="body2" color="textPrimary">
                            Goal
                        </Typography>
                        <TextField
                            style={{width : 600}}
                            fullWidth
                            type="number"
                            placeholder="$"
                            value={goal}
                            variant="outlined"
                            onChange={(e) =>{
                                if (e.target.value < 1 && e.target.value !== "") {
                                    e.target.value = 1;
                                }
                                setGoal(e.target.value)}
                            }
                        />
                    </Grid> : <></>}


                </div>

                <div style={{marginTop : 20}}>
                    <Typography variant="h6" color="primary">
                        Reward Type
                    </Typography>

                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xl={12} lg={6}>
                            <div style={{ display: "flex", justifyContent: "left"}}>
                                <RadioGroup>
                                    <FormControlLabel
                                        control={<Radio />}
                                        label="Person"
                                        disabled={rewardId}
                                        checked={isTypePerson}
                                        onClick={() => {
                                            setIsTypePerson(true);
                                            setIsTypeTeam(false);
                                            setIsTypeCampaign(false)
                                        }}
                                    />
                                </RadioGroup>
                                <RadioGroup>
                                    <FormControlLabel
                                        control={<Radio />}
                                        label="Team"
                                        disabled={rewardId}
                                        checked={isTypeTeam}
                                        onClick={() => {
                                            setIsTypePerson(false);
                                            setIsTypeTeam(true);
                                            setIsTypeCampaign(false)
                                        }}
                                    />
                                </RadioGroup>
                                <RadioGroup>
                                    <FormControlLabel
                                        control={<Radio />}
                                        label="Campaign"
                                        disabled={rewardId}
                                        checked={isTypeCampaign}
                                        onClick={() => {
                                            setIsTypePerson(false);
                                            setIsTypeTeam(false);
                                            setIsTypeCampaign(true)
                                            setIsAimFundraising(true);
                                            setRewardAim("fundraising")
                                        }}
                                    />
                                </RadioGroup>
                            </div>

                        </Grid>
                    </Grid>
                </div>

                <div style={{marginTop : 20}}>
                    <Typography variant="h6" color="primary">
                        Limitations
                    </Typography>

                    <Grid container spacing={2} style={{ marginTop: 5 }}>
                        <Grid item xl={12} lg={6}>
                            <div style={{ display: "flex", justifyContent: "left"}}>
                                <RadioGroup>
                                    <FormControlLabel
                                        control={<Radio />}
                                        label="Maximum Recipients"
                                        checked={isMaxRecipientEnable}
                                        onClick={() => {
                                            setIsMaxRecipientEnable(!isMaxRecipientEnable);
                                        }}
                                    />
                                </RadioGroup>
                            </div>

                        </Grid>
                        <Grid item xl={6} lg={12}>
                            <TextField
                                style={{width : 300}}
                                type="number"
                                fullWidth
                                value={maxRecipient}
                                disabled={!isMaxRecipientEnable}
                                placeholder="10"
                                variant="outlined"
                                onChange={(e) =>
                                {
                                    if (e.target.value < 1 && e.target.value !== "" ) {
                                        e.target.value = 1;
                                    }
                                    setMaxRecipient(e.target.value)}
                            }
                            />
                        </Grid>
                        {rewardId ? <Grid item xl={6} lg={4}>

                            <Typography style={{marginBottom : 20}} variant="h6" color="primary">
                                Reward Image
                            </Typography>
                            <Card variant="outlined">
                                <AvatarPicker
                                    variant="outlined"
                                    disabled={!rewardDetails}
                                    height={150}
                                    width={150}
                                    uniqueId={`reward_${rewardDetails?.id}`}
                                />
                            </Card>

                        </Grid> : <></>}


                    </Grid>
                </div>
                <Grid item xl={8} lg={12}>
                    <Grid item xl={8} lg={6}>
                    <div style={{display : "flex" , marginTop : 80 , float :"right"}}>
                        <Button
                            variant="outlined"
                            size="medium"
                            disableElevation
                            style={{
                                fontWeight: "bold",
                                textTransform: "none",
                                marginRight : 10
                            }}
                            onClick={async () => {history.goBack()}}
                        >
                            Cancel
                        </Button>
                        <ProgressButton
                            showProgress={saving}
                            variant="contained"
                            color="primary"
                            label={rewardId ? "Update Changes" : "Save and Upload Reward Image "}
                            disabled={
                                saved ||
                                saving ||
                                !selectedOrganization ||
                                !selectedCampaign ||
                                !title ||
                                isAimFundraising && !goal ||
                                !startDate ||
                                !endDate ||
                                isMaxRecipientEnable && !maxRecipient
                            }
                            onClick={handleSave}
                        />
                    </div>
                    </Grid>
                </Grid>
            </> : <div style={{marginTop : 20}}>

            <Grid item xl={8} lg={6}>
                <Typography style={{marginBottom : 20}} variant="h6" color="primary">
                    Reward Image
                </Typography>
                <Card variant="outlined">
                    <AvatarPicker
                        variant="outlined"
                        disabled={!newReward}
                        height={150}
                        width={150}
                        uniqueId={`reward_${newReward}`}
                    />
                </Card>
                <div style={{display : "flex" , marginTop : 30 , float : "right"}}>
                    <Button
                        variant="outlined"
                        size="large"
                        disableElevation
                        style={{
                            fontWeight: "bold",
                            textTransform: "none",
                            marginRight : 10
                        }}
                        onClick={async () => {history.goBack()}}
                    >
                        Done
                    </Button>
                </div>
            </Grid>

            </div>

            }

        </div>
    );
};

export default RewardForm;
