import React, { useState } from "react";
import CampaignListing from "views/admin/campaign/CampaignListing";
import ActionBar from "components/ActionBar";
import { Paper, Tab, Tabs } from "@material-ui/core";
import TabPanel from "components/TabPanel";
import ViewHeader from "components/ViewHeader";
import { useHistory, useLocation } from "react-router-dom";
import { resetOrganization } from "store/organization";
import { useDispatch } from "react-redux";

const AllCampaigns = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <ViewHeader
        title="All Campaigns"
        subtitle="Manage published and draft Campaigns"
      >
        <ActionBar
          actionText="New Campaign"
          searchPlaceholder="Search Campaigns"
          onAction={async () => {
            await dispatch(resetOrganization());
            history.push(`${location.pathname}/new`);
          }}
        />
      </ViewHeader>
      <Paper style={{ marginTop: 25 }} variant="outlined">
        <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
          <Tab label="Draft" />
          <Tab label="Published" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <CampaignListing status="draft" style={{ marginTop: 20 }} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <CampaignListing status="published" style={{ marginTop: 20 }} />
        </TabPanel>
      </Paper>
    </>
  );
};

export default AllCampaigns;
