import { createSlice } from "@reduxjs/toolkit";
import {
  deleteOrganization,
  getOrganization,
  getOrganizations,
  createOrganization,
  updateOrganization,
  getAdults,
} from "api-client/organization";

export const initialState = {
  error: null,
  inProgress: false,
  data: [],
  adults: null,
  org: null,
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setProgress: (state, action) => {
      state.inProgress = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setOrg: (state, action) => {
      state.org = action.payload;
    },
    setAdults: (state, action) => {
      state.adults = action.payload;
    },
  },
});

export const fetchOrganizations = () => async (dispatch) => {
  try {
    dispatch(organizationSlice.actions.setProgress(true));
    dispatch(organizationSlice.actions.setData([]));
    const response = await getOrganizations();
    dispatch(organizationSlice.actions.setData(response.data.organizations));
  } catch (err) {
    dispatch(organizationSlice.actions.setError(err));
  } finally {
    dispatch(organizationSlice.actions.setProgress(false));
  }
};

export const fetchAdults = () => async (dispatch) => {
  try {
    dispatch(organizationSlice.actions.setProgress(true));
    dispatch(organizationSlice.actions.setAdults([]));
    const response = await getAdults();
    dispatch(organizationSlice.actions.setAdults(response.data.adults));
  } catch (err) {
    dispatch(organizationSlice.actions.setError(err));
  } finally {
    dispatch(organizationSlice.actions.setProgress(false));
  }
};

export const fetchOrganization = (orgId) => async (dispatch) => {
  try {
    dispatch(organizationSlice.actions.setProgress(true));
    dispatch(organizationSlice.actions.setOrg(null));
    const response = await getOrganization(orgId);
    dispatch(organizationSlice.actions.setOrg(response.data.organizations));
  } catch (err) {
    dispatch(organizationSlice.actions.setError(err));
  } finally {
    dispatch(organizationSlice.actions.setProgress(false));
  }
};

export const addOrganization = (organization) => async (dispatch) => {
  try {
    dispatch(organizationSlice.actions.setProgress(true));
    const response = await createOrganization(organization);
    return response.data;
  } catch (e) {
    dispatch(organizationSlice.actions.setError(e));
    return null;
  } finally {
    dispatch(organizationSlice.actions.setProgress(false));
  }
};

export const removeOrganization = (orgId) => async (dispatch, getState) => {
  try {
    const organizations = getState().organization.data;
    const filtered = organizations.filter((org) => org.id !== orgId);
    dispatch(organizationSlice.actions.setData(filtered));
    await deleteOrganization(orgId);
  } catch (err) {
    dispatch(organizationSlice.actions.setError(err));
  } finally {
  }
};

export const updateOrg = (orgId, organization) => async (dispatch) => {
  try {
    dispatch(organizationSlice.actions.setProgress(true));
    await updateOrganization(orgId, organization);
  } catch (err) {
    dispatch(organizationSlice.actions.setError(err));
  } finally {
    dispatch(organizationSlice.actions.setProgress(true));
  }
};
export const resetOrganization = () => async (dispatch) => {
  await dispatch(organizationSlice.actions.setOrg(null));
};

export default organizationSlice;
