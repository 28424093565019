import React, { useEffect, useState } from "react";
import { Avatar, Grid, LinearProgress, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { storageRef } from "index";
import { getRandomColor } from "utils/colors";
import {useMediaQuery} from "react-responsive";

const TopTeam = ({ goal, campaignId }) => {
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState(null);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
  useEffect(() => {
    downloadImage();
  }, []);

  const downloadImage = async () => {
    const url = await storageRef
      .child(`team_${goal?.Team.id}`)
      .getDownloadURL();
    setImageUrl(url);
  };

  return (
    <Grid
      onClick={() =>
        history.push(`${campaignId}/t/${goal?.Team.name}_${goal?.Team.id}`)
      }
      item
      xl={12}
      lg={12}
      xs={12}
      sm={12}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Avatar
          src={imageUrl}
          style={{ width: isDesktopOrLaptop ? 80 : 50 , height: isDesktopOrLaptop ? 80 : 50, backgroundColor: getRandomColor() }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginLeft: 20,
          }}
        >
          <Typography>{goal?.Team.name}</Typography>
          <LinearProgress
            style={{
              width: "50%",
              height: 5,
              borderRadius: 12,
              marginTop: 5,
              marginBottom: 5,
            }}
            value={
              goal?.achieved_goal_amount
                ? (parseInt(goal?.achieved_goal_amount) /
                    parseInt(goal?.goal)) *
                  100
                : 0
            }
            color="secondary"
            variant="determinate"
          />
          <Typography variant="caption">
            ${goal?.achieved_goal_amount ? goal?.achieved_goal_amount : 0}
            {" raised of "}${goal?.goal}
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

export default TopTeam;
