import { createSlice } from "@reduxjs/toolkit";
import {
  offlineDonation,
  stripeCheckout,
  DonatFromStrip,
} from "api-client/donation";

export const initialState = {
  error: "",
  inProgress: false,
};

const donationSlice = createSlice({
  name: "donation",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setProgress: (state, action) => {
      state.inProgress = action.payload;
    },
  },
});

export const donateOffline = (donationPayload) => async (dispatch) => {
  try {
    const response = await offlineDonation(donationPayload);
    return {
      created: true,
      error: null,
      response,
    };
  } catch (err) {
    return {
      created: false,
      error: "Something went wrong.",
    };
  }
};

export const stripeCheckoutEndPoint = (donationPayload) => async (dispatch) => {
  try {
    dispatch(donationSlice.actions.setProgress(true))
    const response = await stripeCheckout(donationPayload);
    dispatch(donationSlice.actions.setProgress(false))
    return response.data.url;
  } catch (err) {
    dispatch(donationSlice.actions.setProgress(false))
    dispatch(donationSlice.actions.setError(err))
    console.log(err, "this is the error....")
    return err
    // return {
    //   created: false,
    //   error: "Something went wrong.",
    // };
  }
};

export const stripeDonation = (donationPayload) => async (dispatch) => {
  try {
    const response = await DonatFromStrip(donationPayload);
    return response;
  } catch (err) {
    return {
      created: false,
      error: "Something went wrong.",
    };
  }
};
export default donationSlice;
