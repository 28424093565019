import React, { useEffect, useState } from "react";
import {Avatar, FormControlLabel, Grid, Radio, RadioGroup, Typography,} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {KeyboardArrowDownOutlined, KeyboardArrowUpOutlined} from "@material-ui/icons";
import useCollapse from "react-collapsed";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../utils/colors";
import {storageRef} from "../index";
import RewardMemberRow from "./RewardMemberRow";
import {toast} from "react-toastify";
const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: colors.orange[600],
        height: 30,
        width: 30,
        alignSelf : "center",
        marginLeft : 20,
        marginRight : 15
    },
}));
const RewardTableRow = ({team , reward , notificationCount , setNotificationCount}) => {
    const classes = useStyles();

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    const [imageTeam, setImageTeam] = useState(null);
    const [videoTeam, setVideoTeam] = useState(null);

    const [rewardedCount , setRewardedCount] = useState(0);
    const [completedCount , setCompletedCount] = useState(0);



    useEffect(() => {
        downloadImage();
        calculateCompletedCount();
        setRewardedCount(team?.rewardedCount);

    }, [team]);
    const downloadImage = async () => {
        if (team) {
            try {
                const url = await storageRef
                    .child(`team_${team?.id}`)
                    .getDownloadURL();
                const videoUrl = await storageRef
                    .child(`team_video_${team?.id}`)
                    .getDownloadURL();
                setVideoTeam(videoUrl);
            } catch (err) {}
        }
    };
    const calculateCompletedCount = async () => {
        if (team) {
            let count = 0 ;
            for(const member of team?.members){
                let memberAchivedGoal = member?.rewardAchievedAmount ? Number(member?.rewardAchievedAmount) : 0
                let memberGoal = reward?.goal
                if(memberAchivedGoal >= memberGoal){
                    count ++
                }
            }
            setCompletedCount(count);
        }
    };

    return (
        <div className="collapsible" style={{borderColor : "#F5F6F8" }}>
            <div style={{ height : 50  }} className="header" {...getToggleProps()}>

                <Grid container style={{  height : 50   }}  spacing={1}>
                    <Grid item xl={6} lg={4} style={{alignSelf : "center" , paddingLeft :  60 }} >
                        <div style={{display: "flex" , flexDirection : "row" }}>

                                <div style={{alignSelf: "center"}}>
                                    {isExpanded ? <KeyboardArrowUpOutlined/> : <KeyboardArrowDownOutlined/>}
                                </div>
                            <Avatar className={classes.avatar} src={imageTeam} />
                            <Typography style={{ alignSelf : "center", marginLeft : 10}}>
                                {team?.name}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >
                        <Typography variant="body1" >
                            {team?.members.length}
                        </Typography>
                    </Grid>
                    <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                        {reward?.type == "team" && reward?.reward_aim === "fundraising" ? <Typography variant="body1" >
                            ${team?.achivedGoal ? team?.achivedGoal : 0 }
                        </Typography> : <> </>}

                    </Grid>
                    <Grid item xl={6} lg={2} style={{alignSelf : "center" }} >
                        {reward?.type == "team" && reward?.reward_aim === "fundraising" ? <Typography variant="body1" >
                            {team?.achivedGoal ? team?.achivedGoal : 0  } / {reward.goal}
                        </Typography> : <>  {reward?.type == "team" ? <>{videoTeam ? "Yes" : "No"} </> : <></>  } </>}
                    </Grid>
                    <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >
                        {reward?.type !== "team" && reward?.type !== "campaign" && reward?.reward_aim === "fundraising" ? <Typography variant="body1" >
                                {completedCount}
                        </Typography> : <> </>}

                    </Grid>
                    <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                            <Typography variant="body1"  >
                                {rewardedCount}
                            </Typography>
                    </Grid>
                </Grid>

            </div>
            <div   {...getCollapseProps()}>

                {team?.members && team?.members.length
                    ? team?.members.map((member, index) => (
                      <>
                      <RewardMemberRow team={team} member={member} reward={reward} rewardedCount={rewardedCount} setRewardedCount={setRewardedCount} notificationCount={notificationCount} setNotificationCount={setNotificationCount} uploadedVideo={videoTeam ? true : false} />
                      </>

                    ))
                    : <></>}




            </div>
        </div>

    );
};

export default RewardTableRow;
