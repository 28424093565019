import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { isValidEmail } from "utils";
import ProgressButton from "components/ProgressButton";
import { useDispatch } from "react-redux";
import { addManager, updateMgr } from "store/manager";
import PhoneInput from "react-phone-number-input";

const CreateManagerForm = ({ orgId, open, onClose, manager }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState("");

  const [inProgress, setProgress] = useState(false);

  useEffect(() => {
    if (manager) {
      setName(manager.name);
      setEmail(manager.email);
    }
  }, [manager]);

  const handleClose = () => {
    setName("");
    setEmail("");
    onClose();
  };

  const handlePhoneNumber = (number) => {
    setNumber(number);
  };

  const handleSave = async () => {
    setProgress(true);
    const payload = {
      name,
      email,
      password,
      number,
    };
    if (manager) {
      await dispatch(updateMgr(orgId, { ...manager, ...payload }));
    } else {
      await dispatch(addManager(orgId, payload));
    }
    setProgress(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false} fullWidth>
      <DialogTitle>{`${manager ? "Edit" : "Add"} Manager`}</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          label="Name"
          type="text"
        />
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          fullWidth
          label="Email"
          type="text"
          style={{ marginTop: 10 }}
        />
        <TextField
          onChange={(e) => setPassword(e.target.value)}
          variant="outlined"
          fullWidth
          label="Password"
          type="password"
          style={{ marginTop: 10 }}
        />
        <div style={{ marginTop: 15 }}>
          <PhoneInput
            defaultCountry="US"
            placeholder="Phone number"
            onChange={handlePhoneNumber}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <ProgressButton
          showProgress={inProgress}
          label="Save"
          onClick={handleSave}
          disabled={
            !name || !isValidEmail(email) || inProgress || !number || !password
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateManagerForm;
