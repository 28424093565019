import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  AccordionActions,
  Button,
  Divider,
} from "@material-ui/core";
import { ExpandMoreOutlined } from "@material-ui/icons";
import CreateFAQDialog from "views/admin/campaign/form/CreateFAQDialog";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqs, removeFaq } from "store/campaign";
import Loading from "components/Loading";
import ResourceNotFound from "components/ResourceNotFound";
import { useConfirm } from "material-ui-confirm";

const CampaignFAQForm = ({ campaignId }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState(null);

  const { faqs, inProgress } = useSelector((state) => state.campaign);
  useEffect(() => {
    campaignId && dispatch(fetchFaqs(campaignId));
  }, [dispatch]);

  const onDeleteFAQ = (faq) => {
    dispatch(removeFaq(faq.id, campaignId));
  };

  const onEditFAQ = (faq) => {
    setSelectedFAQ(faq);
    setOpenDialog(true);
  };

  if (inProgress) {
    return <Loading />;
  }

  return (
    <div style={{ marginTop: 30 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <Typography variant="button" color="secondary">
          Frequently Asked Questions
        </Typography>
        {campaignId && (
          <Button
            variant="outlined"
            onClick={() => setOpenDialog(true)}
            disabled={!campaignId}
          >
            Add FAQ
          </Button>
        )}
      </div>
      {!campaignId && (
        <Alert severity="error" style={{ marginTop: 10, marginBottom: 10 }}>
          Campaign profile missing. Please complete campaign description
          section.
        </Alert>
      )}
      <div style={{ marginTop: 20, width: "50%" }}>
        {campaignId && !inProgress && faqs && faqs.length
          ? faqs.map((faq) => (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                  {faq.question}
                </AccordionSummary>
                <AccordionDetails>{faq.answer}</AccordionDetails>
                <Divider />
                <AccordionActions>
                  <Button size="small" onClick={() => onEditFAQ(faq)}>
                    Edit
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    onClick={async () => {
                      try {
                        await confirm({
                          title: "Delete FAQ",
                          description:
                            "Are you sure you want to delete this FAQ ?",
                        });
                        onDeleteFAQ(faq);
                      } catch {}
                    }}
                  >
                    Delete
                  </Button>
                </AccordionActions>
              </Accordion>
            ))
          : campaignId && <ResourceNotFound />}
        <CreateFAQDialog
          campaignId={campaignId}
          open={openDialog}
          faq={selectedFAQ}
          onClose={() => {
            setOpenDialog(false);
            setSelectedFAQ(null);
          }}
        />
      </div>
    </div>
  );
};

export default CampaignFAQForm;
