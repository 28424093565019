import React from "react";
import { Avatar, Typography } from "@material-ui/core";
import { getRandomColor } from "utils/colors";

const UserAvatar = ({ image, header, title, subtitle, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Typography variant="body2">{header}</Typography>
      <Avatar
        src={image}
        style={{
          width: 78,
          height: 78,
          marginBottom: 10,
          marginTop: 10,
          backgroundColor: getRandomColor(),
        }}
      />
      <Typography variant="body2">{title}</Typography>
      <Typography variant="caption">{subtitle}</Typography>
    </div>
  );
};

export default UserAvatar;
