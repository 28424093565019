import { API_BASE_URL } from "api-client/index";
import axios from "axios";

export const getName = (role, email) => {
  const uri = `${API_BASE_URL}/prefernces/getNameByEmail`;
  return axios.post(uri, { role: role, email: email });
};

export const deleteAccountFromDB = (role, email) => {
  const uri = `${API_BASE_URL}/prefernces/deleteAccount/${role}/${email}`;
  return axios.delete(uri);
};
