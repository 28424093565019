import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  makeStyles,
} from "@material-ui/core";
import PropType from "prop-types";
import AvatarPicker from "../../../../components/AvatarPicker";

const useStyles = makeStyles(() => ({
  avatar: {
    height: 200,
    width: 200,
  },
}));

const Profile = ({ orgId, onChange }) => {
  const classes = useStyles();
  const fileInputRef = useRef();
  const [image, setImage] = useState(null);

  return (
    <Card variant="outlined">
      <AvatarPicker
        variant="outlined"
        disabled={!orgId}
        height={150}
        width={150}
        uniqueId={`organization_profile_${orgId}`}
      />
    </Card>
  );
};

Profile.propTypes = {
  onChange: PropType.func.isRequired,
  orgImage: PropType.string,
};

export default Profile;
