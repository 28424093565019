import axios from "axios";
import { API_BASE_URL } from "api-client/index";

export const getOrganizations = () => {
  const uri = `${API_BASE_URL}/organization`;
  return axios.get(uri);
};
export const getAdults = () => {
  const uri = `${API_BASE_URL}/adult`;
  return axios.get(uri);
};
export const createOrganization = (organization) => {
  const uri = `${API_BASE_URL}/organization`;
  return axios.post(uri, organization);
};

export const updateOrganization = (orgId, organization) => {
  const uri = `${API_BASE_URL}/organization/${orgId}`;
  return axios.put(uri, organization);
};

export const deleteOrganization = (orgId) => {
  const uri = `${API_BASE_URL}/organization/${orgId}`;
  return axios.delete(uri);
};

export const getOrganization = (orgId) => {
  const uri = `${API_BASE_URL}/organization/${orgId}`;
  return axios.get(uri);
};
