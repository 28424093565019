import { createSlice } from "@reduxjs/toolkit";
import {
  createTeam,
  deleteTeam,
  getTeams,
  updateTeam,
  getTeam,
} from "api-client/team";

export const initialState = {
  error: null,
  inProgress: false,
  data: [],
  team: null,
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setProgress: (state, action) => {
      state.inProgress = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
    },
  },
});

export const addTeam = (orgId, team) => async (dispatch) => {
  try {
    const response = await createTeam(orgId, team);
    dispatch(fetchTeams(orgId, true));
    return response.data;
  } catch (err) {
    dispatch(teamSlice.actions.setError(err));
  }
};

export const updateTem = (orgId, team) => async (dispatch) => {
  try {
    await updateTeam(team.id, team);
    dispatch(fetchTeams(orgId, true));
  } catch (err) {
    dispatch(teamSlice.actions.setError(err));
  }
};

export const fetchTeams =
  (orgId, silent = false) =>
  async (dispatch) => {
    try {
      if (!silent) dispatch(teamSlice.actions.setProgress(true));
      const response = await getTeams(orgId);
      dispatch(teamSlice.actions.setData(response.data.teams));
    } catch (err) {
      dispatch(teamSlice.actions.setError(err));
    } finally {
      dispatch(teamSlice.actions.setProgress(false));
    }
  };

export const fetchTeam =
  (teamId, silent = false) =>
  async (dispatch) => {
    try {
      dispatch(teamSlice.actions.setProgress(true));
      const response = await getTeam(teamId);
      dispatch(teamSlice.actions.setTeam(response.data.team));
    } catch (err) {
      dispatch(teamSlice.actions.setError(err));
    } finally {
      dispatch(teamSlice.actions.setProgress(false));
    }
  };

export const removeTeam = (teamId) => async (dispatch, getState) => {
  try {
    await deleteTeam(teamId);
    const filtered = getState().team.data.filter((team) => team.id !== teamId);
    dispatch(teamSlice.actions.setData(filtered));
  } catch (err) {
    dispatch(teamSlice.actions.setError(err));
  }
};

export default teamSlice;
