import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import NotFound404 from "components/NotFound404";

const SecureRoute = ({ children, ...rest }) => {
  const { user } = useSelector((state) => state.auth);
  return <Route {...rest} render={() => (user ? children : <NotFound404 />)} />;
};

export default SecureRoute;
