import React from "react";
import { CircularProgress, Typography } from "@material-ui/core";

const Loading = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: 35 }}>
      <CircularProgress size={24} color="secondary" />
      <Typography variant="body2" style={{ marginLeft: 15 }}>
        Loading, Please wait...
      </Typography>
    </div>
  );
};

export default Loading;
