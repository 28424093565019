import React, { useEffect, useState } from "react";
import ViewHeader from "components/ViewHeader";
import ActionBar from "components/ActionBar";
import {Button, Dialog, DialogContent, Divider, Paper, Tab, Tabs, TextField, Typography} from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {fetchAdultDetail, updateParentName} from "store/adult";
import { Edit } from "@material-ui/icons";
import Loading from "components/Loading";
import TabPanel from "components/TabPanel";
import MinorListing from "views/admin/adult/MinorListing";
import MyCampaignListing from "views/admin/adult/MyCampaignListing";

import {Alert} from "@material-ui/lab";
import {useMediaQuery} from "react-responsive";


const AdultDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const [error, setError] = useState(null);
    const [name, setName] = useState("");

  const { inProgress, adult } = useSelector((state) => state.adult);
  const { user } = useSelector((state) => state.auth);
  const [isEditParentDialogShowing, showEditParentDialog] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
    const handleUpdateParentName = async (e) => {
        setError(null);

        const payload = {
            id: adult?.id,
            name: name,
        };
        await dispatch(updateParentName(payload));
        const adultId = id || user.systemId;
        if (adultId) {
            await dispatch(fetchAdultDetail(adultId));
        }
        handleClose();
    };

    const handleClose = () => {
    //
    showEditParentDialog(false);
    console.log("closed")
  };
  useEffect(() => {

      const adultId = id || user.systemId;
    if (adultId) dispatch(fetchAdultDetail(adultId));

  }, [user]);
    useEffect(() => {
        setName(adult?.name);
    }, []);

  if (inProgress) {
    return <Loading />;
  }

  return (
    <>
      <ViewHeader title={adult?.name} subtitle={adult?.email}>
        <Edit style={{marginRight : 20 , cursor : "pointer" }} onClick={()=>{
          console.log("hashim")
          showEditParentDialog(true);
        }}/>
        <ActionBar
          searchPlaceholder="Search Kids"
          actionText="Add Kid"
          onAction={() => history.push(`${location.pathname}/minor/new`)}
        />
      </ViewHeader>

      <Paper style={{ marginTop: 25 }} variant="outlined">
        <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
          <Tab label="My Campaigns" />
          <Tab label="Kids" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <MyCampaignListing
            campaigns={adult?.campaigns}
            style={{ marginTop: 20 }}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <MinorListing style={{ marginTop: 20 }} minors={adult?.minors} />
        </TabPanel>
      </Paper>
      <Dialog
          open={isEditParentDialogShowing}
          onClose={handleClose}
          fullWith
          PaperProps={{
            style: {
              borderRadius: 25,
            },
          }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
              style={{ fontWeight: "bold", marginTop: 17, marginBottom: 17 }}
              variant="h6"
          >
            Edit Parent Name
          </Typography>
        </div>
        <Divider />
        <DialogContent>
          <div style={{ paddingLeft: isDesktopOrLaptop ? 100 : 0, paddingRight: isDesktopOrLaptop ? 100 : 0 }}>
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
            >

              <Typography
                  variant="body2"
                  style={{ marginTop: 10, fontWeight: "bold" }}
              >
                Name
              </Typography>
              <TextField
                  fullWidth
                  value={name}
                  style={{ marginTop: 10 }}
                  placeholder="John Smith"
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
              />

              <Button
                  fullWidth
                  style={{
                    marginTop: 20,
                    marginBottom: 55,
                    textTransform: "none",
                    color: "white",
                    fontWeight: "bold",
                    height: 55,
                  }}
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                  disabled={
                    inProgress
                  }
                  onClick={(e) => handleUpdateParentName(e)}
              >
                Update
              </Button>
            </div>
          </div>
        </DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
      </Dialog>
    </>
  );
};

export default AdultDetail;
