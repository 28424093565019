import React from "react";
import { Button, InputAdornment, SvgIcon, TextField } from "@material-ui/core";
import { SearchOutlined as SearchIcon } from "@material-ui/icons";

const ActionBar = ({
  showSearch = false,
  searchPlaceholder = "Search",
  onSearch,
  actionText = "New",
  onAction,
}) => {
  return (
    <div style={{ display: "flex" }}>
      <span style={{ flex: 1 }} />
      {showSearch && (
        <TextField
          size="small"
          onChange={onSearch}
          style={{ marginRight: 10, minWidth: 350 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          placeholder={searchPlaceholder}
          variant="outlined"
          color="secondary"
        />
      )}
      <Button variant="outlined" size="large" onClick={onAction}>
        {actionText}
      </Button>
    </div>
  );
};

export default ActionBar;
