import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AllCampaigns from "views/admin/campaign/AllCampaigns";
import CampaignForm from "views/admin/campaign/CampaignForm";
import CampaignDetail from "views/admin/campaign/CampaignDetail";

const Campaigns = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AllCampaigns />
      </Route>
      <Route path={`${path}/new`}>
        <CampaignForm />
      </Route>
      <Route path={`${path}/edit/:campaignId`}>
        <CampaignForm />
      </Route>
      <Route path={`${path}/:campaignId`}>
        <CampaignDetail />
      </Route>
    </Switch>
  );
};

export default Campaigns;
