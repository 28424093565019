import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Divider,
    TextField,
    Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {forgotPassword, signIn} from "store/auth";
import { Alert } from "@material-ui/lab";
import { isValidEmail } from "utils";
import { useHistory } from "react-router-dom";
import { getConsoleHome } from "routes";
import {toast} from "react-toastify";

const ForgotPasswordDialog = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [inProgress, setProgress] = useState(false);
    const [error, setError] = useState(null);

    const handleClose = () => {
        setEmail("");
        setError(null);
        setProgress(false);
        onClose();
    };
    const handleForgotPassword = async (e) => {
        setError(null);
        setProgress(true);
        console.log("here");
        const response = await dispatch(forgotPassword(email));
        toast("Email sent successfully", { type: "success" });
        handleClose()
        setProgress(false);
        console.log("response" , response)
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    borderRadius: 25,
                },
            }}
        >
            <div style={{ display: "flex", justifyContent: "center" , flexDirection : "row"  }}>
                <Typography
                    style={{alignSelf : "center"  , fontWeight: "bold", marginTop: 17, marginBottom: 17 , marginLeft : "35%" }}
                    variant="h6"
                >
                    Forgot Password
                </Typography>
                <Button onClick={handleClose} style={{marginLeft :"auto"}}>
                    x
                </Button>
            </div>

            <Divider />
            <DialogContent>
                <div style={{ marginTop: 40, paddingLeft: 50, paddingRight: 50 }}>
                    <Typography
                        variant="body2"
                        style={{ marginBottom: 5, fontWeight: "bold" }}
                    >
                        Email
                    </Typography>
                    <TextField
                        variant="outlined"
                        value={email}
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="example@email.com"
                        onKeyDown={(e) => {
                            if (
                                e.key !== "Enter" ||
                                inProgress ||
                                !isValidEmail(email)
                            )
                                return;
                            handleForgotPassword(e);
                        }}
                    />
                    <Button
                        fullWidth
                        style={{
                            marginTop: 20,
                            marginBottom: 25,
                            textTransform: "none",
                            color: "white",
                            fontWeight: "bold",
                            height: 55,
                        }}
                        variant="contained"
                        disableElevation
                        size="large"
                        color="primary"
                        disabled={inProgress || !isValidEmail(email)}
                        onClick={(e) => handleForgotPassword(e)}
                    >
                        Send Reset Email
                    </Button>
                </div>
            </DialogContent>
            {error && <Alert severity="error">{error}</Alert>}
        </Dialog>
    );
};
export default ForgotPasswordDialog;
