import { createSlice } from "@reduxjs/toolkit";
import {
    offlineDonation,
    stripeCheckout,
    DonatFromStrip,
} from "api-client/donation";
import {createReward, deleteReward, editReward, getReward, getRewards, rewardToPerson} from "../api-client/reward";
import {deleteCampaign, getCampaign, getCampaigns} from "../api-client/campaign";
import campaignSlice from "./campaign";
import {updateOrganization} from "../api-client/organization";
import organizationSlice from "./organization";

export const initialState = {
    error: "",
    inProgress: false,
    rewards : [],
    currentReward  : null,
};

const rewardSlice = createSlice({
    name: "reward",
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        setProgress: (state, action) => {
            state.inProgress = action.payload;
        },
        setRewards: (state, action) => {
            state.rewards = action.payload;
        },
        setCurrentReward: (state, action) => {
            state.currentReward = action.payload;
        }
    },
});

export const addReward = (payload ) => async () => {
    try {
        const response = await createReward(payload );
        return response.data;
    } catch (err) {
        return {
            created: false,
            error: "Something went wrong.",
        };
    }
};
export const rewardPerson = ( reward_id , minor_id , team_id) => async () => {
    try {
        const response = await rewardToPerson( reward_id , minor_id , team_id);
        return response.data;
    } catch (err) {
        return {
            created: false,
            error: "Something went wrong.",
        };
    }
};




export const fetchRewards = () => async (dispatch) => {
    try {
        dispatch(rewardSlice.actions.setProgress(true));
        dispatch(rewardSlice.actions.setRewards([]));
        const response = await getRewards();
        dispatch(rewardSlice.actions.setRewards(response.data.rewards));
    } catch (err) {
        dispatch(rewardSlice.actions.setError(err));
    } finally {
        dispatch(rewardSlice.actions.setProgress(false));
    }
};


export const fetchRewardDetails = (rewardId) => async (dispatch) => {
        try {
            dispatch(rewardSlice.actions.setProgress(true));
            dispatch(rewardSlice.actions.setCurrentReward(null));
            const res = await getReward(rewardId);
            dispatch(rewardSlice.actions.setCurrentReward(res.data.reward));
        } catch (err) {
            dispatch(rewardSlice.actions.setError(err));
        } finally {
            dispatch(rewardSlice.actions.setProgress(false));
        }
    };
export const updateReward = (rewardId, reward) => async (dispatch) => {
    try {
        dispatch(rewardSlice.actions.setProgress(true));
        await editReward(rewardId, reward);
    } catch (err) {
        dispatch(rewardSlice.actions.setError(err));
    } finally {
        dispatch(rewardSlice.actions.setProgress(true));
    }
};

export const removeReward = (rewardId) => async (dispatch) => {
    try {
        await deleteReward(rewardId);

    } catch (err) {}
};



export default rewardSlice;
