import React, { useEffect, useState } from "react";
import { SvgIcon } from "@material-ui/core";
import {
  DeleteOutline,
  VerifiedUser as DoneIcon,
  EditOutlined,
} from "@material-ui/icons";
import CustomTable from "components/CustomTable";
import ActionBar from "components/ActionBar";
import { colors } from "utils/colors";
import CreateManagerForm from "views/admin/organization/CreateManagerForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchManagers, removeManager } from "store/manager";
import Loading from "components/Loading";
import { useConfirm } from "material-ui-confirm";

const ManagerListing = ({ orgId }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [openDialog, setOpenDialog] = useState(false);
  const { data, inProgress, error } = useSelector((state) => state.manager);
  const [selectedManager, setSelectedManager] = useState(null);

  useEffect(() => {
    orgId && dispatch(fetchManagers(orgId));
  }, [orgId]);

  const tableHead = [
    "Manager Name",
    "Manager Email",
    "Registration Status",
    "Actions",
  ];

  const formatTableData = (managers) =>
    managers.map((manager) => [
      manager.name,
      manager.email,
      manager.is_verified === "true" && (
        <SvgIcon fontSize="small" color="action">
          <DoneIcon style={{ fill: colors.teal }} />
        </SvgIcon>
      ),
      <>
        <SvgIcon
          fontSize="small"
          style={{ cursor: "pointer" }}
          onClick={async () => {
            try {
              await confirm({
                title: "Delete Manager",
                description: "Are you sure you want to delete this Manager ?",
              });
              dispatch(removeManager(manager.id));
            } catch {}
          }}
        >
          <DeleteOutline color="action" />
        </SvgIcon>
      </>,
    ]);

  return (
    <>
      <div style={{ paddingRight: 25, paddingBottom: 15 }}>
        <ActionBar
          actionText="Add Manager"
          onAction={() => setOpenDialog(true)}
        />
      </div>
      {inProgress && (
        <div style={{ margin: 20 }}>
          <Loading />
        </div>
      )}
      {!inProgress && (
        <CustomTable
          tableHead={tableHead}
          tableData={data && data.length ? formatTableData(data) : []}
          noBorder
        />
      )}
      <CreateManagerForm
        orgId={orgId}
        open={openDialog}
        onClose={() => {
          setSelectedManager(null);
          setOpenDialog(false);
        }}
        manager={selectedManager}
      />
    </>
  );
};

export default ManagerListing;
