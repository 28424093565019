import React, { useEffect, useState } from "react";
import {Avatar, FormControlLabel, Grid, Radio, RadioGroup, Typography,} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {KeyboardArrowDownOutlined, KeyboardArrowUpOutlined} from "@material-ui/icons";
import useCollapse from "react-collapsed";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../utils/colors";
import {storageRef} from "../index";
import {toast} from "react-toastify";
import {addReward, fetchRewards, rewardPerson} from "../store/reward";
import {rewardToPerson} from "../api-client/reward";
import {useDispatch} from "react-redux";
const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: colors.orange[600],
        height: 30,
        width: 30,
        alignSelf : "center",
        marginLeft : 20,
        marginRight : 15
    },
}));
const RewardTableRow = ({team ,member , reward, rewardedCount , setRewardedCount , notificationCount ,  setNotificationCount , uploadedVideo}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    const [imageMember, setImageMember] = useState(null);
    const [isRewarded , setIsRewarded] = useState(false);
    const [videoMember, setVideoMember] = useState(null);


    useEffect(() => {
        downloadImage();
        setIsRewarded(member?.isRewarded);
    }, [member]);
    const downloadImage = async () => {
        if (member) {
            try {
                const url = await storageRef
                    .child(`minor_${member?.id}`)
                    .getDownloadURL();
                setImageMember(url);
                const videoUrl = await storageRef
                    .child(`minor_video_${member?.id}`)
                    .getDownloadURL();
                setVideoMember(videoUrl);
            } catch (err) {}
        }
    };

    const handleRewardButton = async () => {

        if(reward?.reward_aim === "video"){

            if(reward?.type == "team"){
            console.log("video uploaded" , uploadedVideo)
                if(uploadedVideo){
                    const rewardRes = await dispatch(rewardPerson( reward?.id , member?.id , team.id));

                    if(rewardRes?.message == "Reward Removed Successfully !"){
                        setRewardedCount(rewardedCount - 1);
                        setNotificationCount(notificationCount + 1)
                        setIsRewarded(!isRewarded);
                    }else if (rewardRes?.message == "Rewarded Successfully !"){
                        setRewardedCount(rewardedCount + 1);
                        setNotificationCount(notificationCount - 1)
                        setIsRewarded(!isRewarded);
                    }
                    toast(rewardRes?.message, { type: rewardRes?.success ? "success" : "error" });

                }else{
                    toast("This team did not uploaded video yet!", { type: "error" });
                }
            }else if (reward?.type == "person"){
                if(videoMember){
                    const rewardRes = await dispatch(rewardPerson( reward?.id , member?.id , team.id));

                    if(rewardRes?.message == "Reward Removed Successfully !"){
                        setRewardedCount(rewardedCount - 1);
                        setNotificationCount(notificationCount + 1)
                        setIsRewarded(!isRewarded);
                    }else if (rewardRes?.message == "Rewarded Successfully !"){
                        setRewardedCount(rewardedCount + 1);
                        setNotificationCount(notificationCount - 1)
                        setIsRewarded(!isRewarded);
                    }
                    toast(rewardRes?.message, { type: rewardRes?.success ? "success" : "error" });

                }else{
                    toast("This member did not uploaded video yet !", { type: "error" });

                }

            }
        }
        else{
            let teamAchivedGoal = team?.achivedGoal ? Number(team?.achivedGoal) : 0

            let memberAchivedGoal = member?.rewardAchievedAmount ? Number(member?.rewardAchievedAmount) : 0

            let achivedGoal = reward?.type == "team" ? teamAchivedGoal : memberAchivedGoal

            if(reward?.type == "team"){
                achivedGoal = teamAchivedGoal ;
            }else if (reward?.type == "person"){
                achivedGoal = memberAchivedGoal ;
            }else{
                achivedGoal = reward?.Campaign?.rewardAchievedAmount ? Number(reward?.Campaign?.rewardAchievedAmount) : 0
            }

            let goal = reward?.goal

            if(achivedGoal < goal){
                toast("Progress not reached to goal !", { type: "error" });
            }else{

                const rewardRes = await dispatch(rewardPerson( reward?.id , member?.id , team.id));

                if(rewardRes?.message == "Reward Removed Successfully !"){
                    setRewardedCount(rewardedCount - 1);
                    setNotificationCount(notificationCount + 1)
                    setIsRewarded(!isRewarded);
                }else if (rewardRes?.message == "Rewarded Successfully !"){
                    setRewardedCount(rewardedCount + 1);
                    setNotificationCount(notificationCount - 1)
                    setIsRewarded(!isRewarded);
                }

                toast(rewardRes?.message, { type: rewardRes?.success ? "success" : "error" });

            }
        }



    };



    return (
        <>
            <div style={{background : "#F5F6F8" , height : 1 , marginBottom : 0}}></div>
            <div style={{  paddingLeft : 20 , marginBottom : 5 , marginTop : 5}}>
                <Grid container style={{  height : 50   }}  spacing={1}>
                    <Grid item xl={6} lg={4} style={{alignSelf : "center" , paddingLeft :  80 }} >
                        <div style={{display: "flex" , flexDirection : "row" }}>

                            <Avatar className={classes.avatar} src={imageMember} />
                            <Typography style={{ alignSelf : "center", marginLeft : 10}}>
                                {member?.name}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >

                    </Grid>
                    <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                        {reward?.type !== "team" && reward?.type !== "campaign" && reward?.reward_aim === "fundraising" ?
                            <Typography variant="body1" >
                            ${member?.rewardAchievedAmount ? member?.rewardAchievedAmount  : 0 }
                        </Typography>
                            : <></>}

                    </Grid>
                    <Grid item xl={6} lg={2} style={{alignSelf : "center" }} >
                        {reward?.type !== "team" && reward?.type !== "campaign" && reward?.reward_aim === "fundraising" ?
                        <Typography variant="body1"  >
                            { member?.rewardAchievedAmount ? member?.rewardAchievedAmount  : 0 } / {reward?.goal}
                        </Typography>
                            : <> {reward?.type !== "team" ? <>{videoMember ? "Yes" : "No"} </> : <></>  }</> }
                    </Grid>
                    <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >

                    </Grid>
                    <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                        <div style={{borderRadius : 15 , border : 1 , borderColor : "#F5F6F8" , borderStyle : "solid"  , padding: 5 , marginRight : 30 , display : "flex"}}>
                            {isRewarded ?
                                <div style={{borderRadius : 15 , border : 1 , borderColor : "#F5F6F8" , borderStyle : "solid" ,padding: 5 , background : "#44D7B6" , marginRight : 10 , textAlign : "center"}}>
                                    <Typography variant="body2" style={{color : "white"}}  >
                                        Rewarded
                                    </Typography>
                                </div>
                                :
                                <div style={{borderRadius : 15 , border : 1 , borderColor : "#F5F6F8" , borderStyle : "solid" ,padding: 5 , background : "#898A99" , marginRight : 10 , textAlign : "center" , width : 120}}>
                                    <Typography variant="body2" style={{color : "white"}}  >
                                        No
                                    </Typography>
                                </div>
                            }

                            <RadioGroup>
                                <FormControlLabel
                                    style={{height :30}}
                                    control={<Radio />}
                                    checked={isRewarded}
                                    onClick={handleRewardButton}
                                />
                            </RadioGroup>


                        </div>
                    </Grid>
                </Grid>

            </div>
        </>
    );
};

export default RewardTableRow;
