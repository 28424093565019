import React, { useEffect, useState } from "react";
import ViewHeader from "components/ViewHeader";
import ActionBar from "components/ActionBar";
import {Button, Dialog, DialogContent, Divider, Paper, Tab, Tabs, TextField, Typography} from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {fetchAdultDetail, updateParentName} from "store/adult";
import {DragIndicatorOutlined, Edit} from "@material-ui/icons";
import Loading from "components/Loading";
import TabPanel from "components/TabPanel";
import MinorListing from "views/admin/adult/MinorListing";
import MyCampaignListing from "views/admin/adult/MyCampaignListing";

import {Alert} from "@material-ui/lab";
import {useMediaQuery} from "react-responsive";
import {useConfirm} from "material-ui-confirm";
import {deleteAccount, forgotPassword, removeUserFromDB} from "../../../store/auth";
import {toast} from "react-toastify";


const Setting = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const confirm = useConfirm();

    const [tabIndex, setTabIndex] = useState(0);
    const [error, setError] = useState(null);
    const [name, setName] = useState("");

    const { inProgress, adult } = useSelector((state) => state.adult);
    const { user } = useSelector((state) => state.auth);
    const [isEditParentDialogShowing, showEditParentDialog] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const handleDeleteAccount = async (e) => {
        setError(null);

        await confirm({
            title: "Delete Account",
            description:
                "Are you sure you want to delete this Account ?",
        });

        const response = await dispatch(deleteAccount());

        const res = await dispatch(removeUserFromDB(user?.role));

        toast("Account deleted successfully", { type: "success" });
        history.push("/")
    };


    useEffect(() => {
        const adultId = id || user.systemId;
        if (adultId) dispatch(fetchAdultDetail(adultId));

    }, [user]);
    useEffect(() => {
        setName(adult?.name);
    }, []);

    if (inProgress) {
        return <Loading />;
    }

    return (
        <>
            <ViewHeader title={adult?.name} subtitle={adult?.email}>
            </ViewHeader>
            <Paper style={{ marginTop: 25 , padding : 20}} variant="outlined">
                <Typography variant="h6" color="primary">
                    Account Settings
                </Typography>
                <Typography variant="subtitle2" color="primary" style={{marginTop : 20}}>
                    By Clicking this Button your account from Walkaton with be Deleted Permanently !
                </Typography>

                <Button
                    variant={"outlined"}
                    size="large"
                    aria-selected="true"
                    style={{ margin : 10 , color : "red" }}
                    onClick={() => {
                        handleDeleteAccount();
                    }}
                >
                    Delete Account
                </Button>

            </Paper>
        </>
    );
};

export default Setting;
