import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { storageRef } from "index";
import { truncate } from "utils";

const Campaign = ({
  id,
  website,
  image,
  title,
  org,
  description,
  percent,
  goal,
  achievedAmount,
  totalGoal,
}) => {
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    downloadImage();
  }, [image]);

  const downloadImage = async () => {
    if (image) {
      try {
        const url = await storageRef.child(image).getDownloadURL();
        setImageUrl(url);
      } catch (err) {}
    }
  };

  return (
    <Card
      onClick={() =>
        history.push(`org/${org.split(" ").join("_")}/c/${website}`)
      }
      style={{ cursor: "pointer", borderRadius: 27 }}
    >
      <CardMedia
        style={{ height: 0, paddingTop: "66.25%" }}
        image={imageUrl}
        title={title}
      />
      <CardContent>
        <Typography
          color="textPrimary"
          variant="h6"
          style={{ fontFamily: "Raleway", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Typography
          style={{ fontFamily: "Raleway", fontWeight: "400" }}
          variant="body2"
        >
          {truncate(description, 40)}
        </Typography>
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 30 }}
        >
          <LinearProgress
            variant="determinate"
            value={percent > 100 ? 100 : percent}
            color="secondary"
            style={{ height: 5, borderRadius: 15 }}
          />
          <div style={{ display: "flex", marginTop: 10 }}>
            <Typography
              variant="caption"
              style={{ fontWeight: "bold", marginRight: 2 }}
            >{`$${achievedAmount} raised `}</Typography>
            <Typography variant="caption">{` of $${totalGoal}`}</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Campaign;
