import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  CircularProgress
} from "@material-ui/core";
import AdminTopBar from "components/AdminTopBar";
import {CheckCircle, Circle, CircleOutlined, Facebook, Instagram} from "@material-ui/icons";
import { fetchTeam } from "store/team";

import { stripeCheckoutEndPoint } from "store/donation";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import { isValidEmail } from "utils";
import { storageRef } from "index";
import { Alert } from "@material-ui/lab";
import TopTeam from "views/public/campaign/TopTeam";
import ResourceNotFound from "components/ResourceNotFound";
import { fetchTeams } from "store/team";
import {fetchCampaignDetailsByWebAddress} from "../../../store/campaign";
import Loading from "../../../components/Loading";
import {useMediaQuery} from "react-responsive";
import AppbarLogo from "../../../assets/appbar-logo.svg";
import GoogleLogo from "../../../assets/downloadfromgoogle.png";
import AppleLogo from "../../../assets/downloadfromapple.png";
import Linkedin from "../../../assets/linkedin.png";
import Youtube from "../../../assets/youtube.png";
import {colors} from "../../../utils/colors";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  numberField: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const Donation = () => {
  const classes = useStyles();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { team_id, campaignID, campaignId, fundraiser_id } = useParams();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [addFeeEnable, setAddFeeEnable] = useState(false);
  const [addMinorSelection, setAddMinorSelection] = useState(false);

  const [amount, setAmount] = useState("");
  const [fee, setfee] = useState(0);
  const [isAnonymous, setAnonymous] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedMinor, setSelectedMinor] = useState(null);
  const [donationLoading, setDonationLoading] = useState(true);

  const { team: teamDetail } = useSelector((state) => state.team);
  const { user } = useSelector((state) => state.auth);

  const { currentCampaign: campaignDetails  ,  inProgress } = useSelector(
    (state) => state.campaign
  );
  const [campaignImage, setCampaignImage] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (campaignId) {
      console.log("casa" , campaignId)
      dispatch(
          fetchCampaignDetailsByWebAddress(
              campaignId,
              user?.systemId || undefined
          )
      );
    }
  }, [dispatch, campaignId]);

  useEffect(async () => {
    await dispatch(fetchTeam(selectedTeam));
  }, [selectedTeam]);
  const downloadImage = async () => {
    if (campaignDetails) {
      const coverImage = `campaign_cover_${campaignDetails?.id}`;
      const coverImageUrl = await storageRef.child(coverImage).getDownloadURL();
      setCampaignImage(coverImageUrl);
    }
  };


  useEffect(() => {
    downloadImage();
    console.log("hashim", team_id?.split("_")[1], fundraiser_id);
    console.log("fundraiser ID ", fundraiser_id);
    console.log("team ID", team_id);
    if (team_id) {
      if (fundraiser_id) {
        setSelectedTeam(team_id);
      } else {
        setSelectedTeam(team_id?.split("_")[1]);
      }
    }
    if (fundraiser_id) {
      setSelectedMinor(fundraiser_id);
    }
  }, [campaignDetails]);
  const handleFeeChange = (event) => {
    setAddFeeEnable(event.target.checked);
    if (event.target.checked) {
      setfee((2.9 / 100) * amount + 0.3);
    } else if (!event.target.checked) {
      setfee(0);
    }
  };
  const handleDonationTypeSelection = (event) => {
    setAddMinorSelection(event.target.checked);
    if (event.target.checked) {
      setSelectedMinor(null);
      setSelectedTeam(null);
    } else if (!event.target.checked) {
      setSelectedMinor(null);
      setSelectedTeam(null);
    }
  };

  const canDonate =
    amount > 0.5 &&
    amount &&
    email &&
    fullName &&
    isValidEmail(email) &&
    ((!addMinorSelection && selectedMinor && selectedTeam) ||
      addMinorSelection);
  const handleAmountChange = () => setfee(0);

  return (
    <>
      <AdminTopBar />
      <div className={classes.toolbar} />

      <Container style={{ marginTop: 50 }}>

        { inProgress && !campaignDetails ? (
            <Loading />
        ) : (
            <Grid container spacing={isDesktopOrLaptop ? 4 : 2} justifyContent="center">
              <Grid item lg={6} xl={12} xs={12}>
                <Paper elevation={isDesktopOrLaptop ? 4 : 2 }>
                  <div
                      style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        paddingTop: 50,
                        paddingBottom: 20,
                      }}
                  >
                    <div  style=  {{ display: isDesktopOrLaptop ? "flex" : "initial" , alignItems: "center" }}>
                      <Avatar
                          variant="rounded"
                          style={{ height: isDesktopOrLaptop ? 80 : "60%", width: isDesktopOrLaptop ? 160 : "100%", marginRight: 20 , marginBottom : isDesktopOrLaptop ? 0 : 10 }}
                          src={campaignImage}
                      />
                      <Typography>
                        You're supporting{" "}
                        <span style={{ fontWeight: "bold" }}>
                        {campaignDetails?.title}
                      </span>
                      </Typography>
                    </div>
                    <Typography
                        variant="h6"
                        style={{ fontWeight: "bold", marginTop: 20 }}
                    >
                      Thank you! May God bless you and reward you in this life and
                      the next. Ameen!
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{
                          fontWeight: "bold",
                          marginTop: 20,
                          marginBottom: 7,
                        }}
                    >
                      Enter your Donation
                    </Typography>
                    <OutlinedInput
                        className={classes.numberField}
                        variant="outlined"
                        type="number"
                        placeholder="0.00"
                        inputProps={{
                          style: { textAlign: "right" },
                        }}
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Roboto",
                          fontSize: 18,
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <Typography
                                variant="h6"
                                style={{ fontWeight: "bold", fontFamily: "Roboto" }}
                            >
                              USD
                            </Typography>
                          </InputAdornment>
                        }
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            e.target.value = 0;
                          }
                          setAmount(e.target.value);
                          handleAmountChange();
                          setAddFeeEnable(false);
                        }}
                    />
                    <FormControlLabel
                        control={
                          <Checkbox
                              color="primary"
                              checked={addFeeEnable}
                              onChange={handleFeeChange}
                              name="checkedF"
                              checkedIcon={<CheckCircle />}
                              icon={<CircleOutlined />}
                          />
                        }
                        label="Cover the processing fees for this donation"
                    />
                  </div>
                  <Divider />
                  <div style={{ paddingLeft: 50, marginTop: 10 }}>
                    <FormControlLabel
                        control={
                          <Checkbox
                              color="primary"
                              checked={addMinorSelection}
                              onChange={handleDonationTypeSelection}
                              name="checkedD"
                              checkedIcon={<CheckCircle />}
                              icon={<CircleOutlined />}
                          />
                        }
                        label="Donate directly to campaign only!"
                    />
                  </div>
                  {!addMinorSelection && (
                      <Grid
                          container
                          spacing={2}
                          style={{ marginTop: 10, marginBottom: 10, paddingLeft: 50 }}
                      >
                        <Grid item xs={10} lg={5} xl={5} md={12} sm={10}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Select Team</InputLabel>
                            <Select
                                value={selectedTeam || ""}
                                label="Select Team"
                                onChange={(e) => {
                                  setSelectedTeam(e.target.value);
                                }}
                            >
                              {campaignDetails &&
                              campaignDetails.teamGoals &&
                              campaignDetails.teamGoals.length ? (
                                  campaignDetails.teamGoals
                                      .slice(0)
                                      .sort((a, b) => {
                                        const x = parseInt(a.goal);
                                        const y = parseInt(b.goal);
                                        return x > y ? -1 : x < y ? 1 : 0;
                                      })
                                      .map((o) => (
                                          <MenuItem value={o.Team?.id} key={o.Team?.id}>
                                            {o.Team?.name}
                                          </MenuItem>
                                      ))
                              ) : (
                                  <ResourceNotFound />
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={10} lg={5} xl={5} md={12} sm={10}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Select Fundraiser</InputLabel>
                            <Select
                                value={selectedMinor || ""}
                                label="Select Fundrasier"
                                onChange={(e) => {
                                  setSelectedMinor(e.target.value);
                                }}
                            >
                              {teamDetail &&
                              teamDetail.minors &&
                              teamDetail.minors.length ? (
                                  teamDetail.minors.map((o) => (
                                      <MenuItem value={o.id} key={o.id}>
                                        {o.name}
                                      </MenuItem>
                                  ))
                              ) : (
                                  <ResourceNotFound />
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                  )}

                  <Divider style={{ marginTop: 10 }} />
                  <div
                      style={{
                        paddingLeft: 50,
                        paddingTop: 20,
                        paddingRight: 50,
                        paddingBottom: 50,
                      }}
                  >
                    <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", marginBottom: 10 }}
                    >
                      Full Name
                    </Typography>
                    <TextField
                        placeholder="Full Name"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setFullName(e.target.value)}
                    />
                    <Typography
                        variant="body2"
                        style={{
                          fontWeight: "bold",
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                    >
                      Email
                    </Typography>
                    <TextField
                        placeholder="Email"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <FormControlLabel
                        control={
                          <Checkbox
                              color="primary"
                              checked={isAnonymous}
                              onChange={(e) => setAnonymous(e.target.checked)}
                              checkedIcon={<CheckCircle />}
                              icon={<CircleOutlined />}
                          />
                        }
                        label="Anonymous Donation"
                    />
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        style={{ marginTop: 15, marginBottom: 15 }}
                    >
                      By Clicking Give Now, you agree to Terms of Service and
                      Privacy Policy
                    </Typography>
                    <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                    >
                      <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          disableElevation
                          disabled={!canDonate}
                          style={{
                            marginRight: 25,
                            width: 196,
                            height: 52,
                            color: "white",
                            fontWeight: "bold",
                          }}
                          onClick={async () => {
                            let teamId;
                            if (!team_id) {
                              teamId = undefined;
                            } else {
                              teamId = team_id?.split("_")[1];
                            }
                            if (fundraiser_id) {
                              teamId = team_id;
                            }
                            const orgName = campaignDetails?.Organization?.name;
                            const paymentAmount = (
                                parseFloat(amount) + fee
                            ).toFixed(2);
                            const payload = {
                              name: fullName,
                              amount: parseFloat(paymentAmount),
                              email: email,
                              campaignId: campaignID ? campaignID : campaignId,
                              teamId: selectedTeam ? selectedTeam : undefined,
                              fundraiserId: selectedMinor
                                  ? selectedMinor
                                  : undefined,
                              orgName: orgName.split(" ").join("_"),
                              isAnonymous : isAnonymous
                            };
                            console.log(payload);
                            const redirectUrl = await dispatch(
                                stripeCheckoutEndPoint(payload)
                            );
                            window.location = redirectUrl;
                            // console.log("redirectUrl...", redirectUrl)
                      }}
                      >
                        {!inProgress ? "Give Now" : <CircularProgress size={24} color="#fff" />}
                      </Button>
                      <Typography
                          variant="h5"
                          color="textSecondary"
                          style={{ fontWeight: "bold", fontFamily: "Roboto" }}
                      >
                        {isNaN(parseFloat(amount) + parseFloat(fee))
                            ? "$0.00"
                            : `$${(parseFloat(amount) + parseFloat(fee)).toFixed(
                                2
                            )}`}
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid  xs={isDesktopOrLaptop ? 3 : 12} style={{marginTop : 16 , marginBottom : 10}}>
                <Paper elevation={isDesktopOrLaptop ? 4 : 2 } style={{ padding: 30 }}>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Your Donation
                  </Typography>
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                  >
                    <Typography variant="body2">Donation</Typography>
                    <Typography variant="body2" style={{ fontFamily: "Roboto" }}>
                      ${amount ? `${amount}` : 0}
                    </Typography>
                  </div>
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                  >
                    <Typography variant="body2">Fees</Typography>
                    <Typography variant="body2" style={{ fontFamily: "Roboto" }}>
                      {fee ? `$${fee.toFixed(2)}` : "$0.00"}
                    </Typography>
                  </div>
                  <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                  >
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Total
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", fontFamily: "Roboto" }}
                    >
                      {isNaN(parseFloat(amount) + parseFloat(fee))
                          ? "$0.00"
                          : `$${(parseFloat(amount) + parseFloat(fee)).toFixed(2)}`}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>
        )}
      </Container>
    </>
  );
};

export default Donation;
