import React, { useEffect, useState } from "react";
import ViewHeader from "components/ViewHeader";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DragIndicatorOutlined } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaigns, fetchCampaignsOfOrganizations } from "store/campaign";
import { fetchTeams } from "store/team";
import { donateOffline } from "store/donation";
import Loading from "components/Loading";
import ProgressButton from "components/ProgressButton";
import { toast } from "react-toastify";
import { fetchOrganizations } from "../../../store/organization";
import { isValidEmail } from "../../../utils";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import PhoneInput from "react-phone-number-input";

const OfflineDonation = () => {
  const dispatch = useDispatch();
  const { data, inProgress } = useSelector((state) => state.campaign);
  const { data: teams } = useSelector((state) => state.team);
  const { data: organizations } = useSelector((state) => state.organization);

  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [readyToGetCampaign, setReadyToGetCampaign] = useState(false);

  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedCampaignOrganization, setSelectedCampaignOrganization] =
    useState("");

  const [selectedTeam, setSelectedTeam] = useState("");
  const [typeOfDonation, setTypeOfDonation] = useState("noTeam");
  const [isAnonymous, setIsAnonymous] = useState(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [donorComment, setDonorComment] = useState("");

  const [amount, setAmount] = useState("");
  const [amountDescription, setAmountDescription] = useState("");

  const [enableTypeOfDonationButton, setEnableTypeOfDonationButton] =
    useState(true);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  useEffect(async () => {
    await dispatch(fetchOrganizations());
  }, []);
  useEffect(async () => {
    if (organizations) {
      await dispatch(fetchCampaignsOfOrganizations(selectedOrganization));
    }
  }, [readyToGetCampaign]);
  useEffect(async () => {
    await dispatch(fetchTeams(selectedCampaignOrganization, true));
  }, [selectedCampaign]);

  const handleAddress = async (address) => {
    setAddress(address.label);
  };

  const handleSave = async () => {
    const payload = {
      organization_id: selectedOrganization,
      campaign_id: selectedCampaign,
      team_id: selectedTeam,
      is_anonymous: isAnonymous,
      type: typeOfDonation,
      amount: amount,
      amount_description: amountDescription,
      full_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      address: address,
      city: city,
      state: state,
      country: country,
      donorComment: donorComment,
    };

    setSaving(true);
    await dispatch(donateOffline(payload));
    setSaving(false);
    toast("Donated successfully", { type: "success" });
    setSaved(true);
  };

  if (inProgress) {
    return <Loading />;
  }

  return (
    <div>
      <ViewHeader
        title="Offline Donation"
        subtitle="Create and Manage Offline Donations"
      >
        <ProgressButton
          showProgress={saving}
          variant="contained"
          color="secondary"
          disabled={
            saved ||
            saving ||
            !selectedOrganization ||
            !selectedCampaign ||
            (typeOfDonation !== "noTeam" && !selectedTeam) ||
            !amount ||
            !amountDescription ||
            !firstName ||
            !lastName ||
            !phone ||
            !address ||
            !city ||
            !state ||
            !country ||
            !donorComment
          }
          label="Save Changes"
          onClick={handleSave}
        />
      </ViewHeader>

      <div style={{ marginTop: 30 }}>
        <Typography variant="h6" color="primary">
          Campaign Information
        </Typography>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={6} lg={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Organization</InputLabel>
              <Select
                value={selectedOrganization}
                label="Select Organization"
                onChange={(e) => {
                  setSelectedOrganization(e.target.value);
                  setReadyToGetCampaign(true);
                }}
              >
                {organizations
                  ?.filter((o) => o.managers && o.managers.length)
                  .map((o) => (
                    <MenuItem value={o.id} key={o.id}>
                      {o.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Campaign</InputLabel>
              <Select
                value={selectedCampaign}
                label="Select Campaign"
                onChange={(e) => {
                  setSelectedCampaign(e.target.value);
                  let selectedCam = data.find((x) => x.id === e.target.value);
                  setSelectedCampaignOrganization(selectedCam?.organization_id);
                }}
              >
                {data &&
                  data
                    .filter((o) => o.status && o.status === "published")
                    .map((o) => (
                      <MenuItem value={o.id} key={o.id}>
                        {o.title}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 30, marginBottom: 10 }} />
      <div>
        <Typography variant="h6" color="primary">
          Select Team
        </Typography>
        <div style={{ marginTop: 10 }}>
          <Button
            variant={
              enableTypeOfDonationButton ? "outlinedSecondary" : "outlined"
            }
            size="large"
            aria-selected="true"
            startIcon={<DragIndicatorOutlined color="secondary" />}
            style={{ marginRight: 20 }}
            onClick={() => {
              setEnableTypeOfDonationButton(true);
              setTypeOfDonation("noTeam");
            }}
          >
            No Team
          </Button>
          <Button
            variant={
              !enableTypeOfDonationButton ? "outlinedSecondary" : "outlined"
            }
            size="large"
            startIcon={<DragIndicatorOutlined color="secondary" />}
            onClick={() => {
              setEnableTypeOfDonationButton(false);
              setTypeOfDonation("haveTeam");
            }}
          >
            Add Team
          </Button>

          {!enableTypeOfDonationButton ? (
            <Grid item xl={6} lg={6} style={{ marginTop: 20 }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Team</InputLabel>
                <Select
                  value={selectedTeam}
                  label="Fundraising Team"
                  onChange={(e) => {
                    setSelectedTeam(e.target.value);
                  }}
                >
                  {teams.map((o) => (
                    <MenuItem value={o.id} key={o.id}>
                      {o.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div style={{ marginTop: 30, marginBottom: 10 }} />
      <div>
        <Typography variant="h6" color="primary">
          Donor Information
        </Typography>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xl={12} lg={12}>
            <Typography>
              Do you want to make this donation anonymous ?
            </Typography>
            <RadioGroup row>
              <FormControlLabel
                control={<Radio />}
                label="Yes"
                checked={isAnonymous}
                onClick={() => {
                  setIsAnonymous(true);
                  setEmail("");
                }}
              />
              <FormControlLabel
                control={<Radio />}
                label="No"
                checked={!isAnonymous}
                onClick={() => {
                  setIsAnonymous(false);
                }}
              />
            </RadioGroup>
          </Grid>
          <Grid item xl={6} lg={6}>
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <PhoneInput
              defaultCountry="US"
              placeholder="Enter Admin phone number"
              onChange={(number) => setPhone(number)}
            />
          </Grid>
          <Grid item xl={12} lg={12}>
            <GooglePlacesAutocomplete
              selectProps={{
                address,
                placeholder: "Search Address",
                onChange: handleAddress,
              }}
              apiKey="AIzaSyBLHaBkTB0c6tEPKBso5rIaWJM5OacfaBE"
            />
          </Grid>
          <Grid item xl={6} lg={4}>
            <TextField
              fullWidth
              label="City"
              variant="outlined"
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xl={6} lg={4}>
            <TextField
              fullWidth
              label="State"
              variant="outlined"
              onChange={(e) => setState(e.target.value)}
            />
          </Grid>
          <Grid item xl={6} lg={4}>
            <TextField
              fullWidth
              label="Country"
              variant="outlined"
              onChange={(e) => setCountry(e.target.value)}
            />
          </Grid>
          <Grid item xl={7} lg={7}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Donor Comments"
              variant="outlined"
              onChange={(e) => setDonorComment(e.target.value)}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 30, marginBottom: 10 }} />
      <div>
        <Typography variant="h6" color="primary">
          Payment Information
        </Typography>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xl={12} lg={12}>
            <Typography>Payment type</Typography>
            <RadioGroup>
              <FormControlLabel
                control={<Radio />}
                label="Card"
                checked={false}
              />
            </RadioGroup>
            <RadioGroup>
              <FormControlLabel
                control={<Radio />}
                label="Cash"
                checked={true}
              />
            </RadioGroup>
          </Grid>
          <Grid item xl={6} lg={6}>
            <TextField
              label="Amount"
              type="number"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                if (e.target.value < 0) {
                  e.target.value = 0;
                }
                setAmount(e.target.value);
              }}
            />
          </Grid>
          <Grid item xl={8} lg={8}>
            <TextField
              multiline
              rows={4}
              label="Description"
              variant="outlined"
              fullWidth
              onChange={(e) => setAmountDescription(e.target.value)}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OfflineDonation;
