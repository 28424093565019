import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import { FileUploadOutlined, DeleteOutlined } from "@material-ui/icons";
import { storageRef } from "index";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";

const UploadStatus = {
  Idle: 0,
  Started: 1,
  InProgress: 2,
  Completed: 3,
  Failed: 4,
};

const AvatarPicker = ({
                        isVideo = false,
                        disabled = false,
                        height = 150,
                        width = 150,
                        label = "Upload Image",
                        variant = "elevation",
                        uniqueId,
                        style,
                      }) => {
  const fileInputRef = useRef();
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.Idle);

  useEffect(() => {
    downloadImage();
  }, [uniqueId]);

  const downloadImage = async () => {
    if (uniqueId) {
      try {
        const imageUrl = await storageRef.child(uniqueId).getDownloadURL();
        setImage(imageUrl);
        setUploadStatus(UploadStatus.Completed);
      } catch (err) {}
    }
  };

  const handleChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    if (uniqueId) {
      setUploadStatus(UploadStatus.Started);
      const uploadTask = storageRef.child(uniqueId).put(event.target.files[0]);
      uploadTask.on(
          "state_changed",
          (snapshot) => {
            setProgress(UploadStatus.InProgress);
            setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          },
          (err) => {
            setUploadStatus(UploadStatus.Failed);
            toast("Failed to upload Image", { type: "error" });
          },
          () => {
            setUploadStatus(UploadStatus.Completed);
            toast("Image Uploaded successfully", { type: "success" });
          }
      );
    }
  };

  const deleteFile = async () => {
    if (uniqueId) {
      await storageRef.child(uniqueId).delete();
      setImage(null);
      setUploadStatus(UploadStatus.Idle);
      setProgress(0);
      toast("Image removed successfully", { type: "success" });
    }
  };

  return (
      <Card variant={variant} style={style}>
        <CardContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isVideo ?
                <ReactPlayer url={image} controls={true}  /> :
                <Avatar
                    variant="rounded"
                    style={{ height: height, width: width, margin: 20 }}
                    src={image}
                />
            }



          </div>
        </CardContent>
        {uploadStatus === 1 || uploadStatus === 2 ? (
            <LinearProgress variant="determinate" value={progress} />
        ) : null}
        <Divider />
        <div style={{ display: "flex", justifyContent: "center", padding: 5 }}>
          <input
              type="file"
              onChange={handleChange}
              style={{ display: "none" }}
              ref={fileInputRef}
          />
          <Button
              disabled={
                  disabled ||
                  uploadStatus === UploadStatus.Started ||
                  uploadStatus === UploadStatus.InProgress
              }
              showProgress={false}
              color="primary"
              startIcon={
                uploadStatus === UploadStatus.Completed ? (
                    <DeleteOutlined />
                ) : (
                    <FileUploadOutlined />
                )
              }
              fullWidth
              variant="text"
              onClick={() =>
                  uploadStatus === UploadStatus.Completed
                      ? deleteFile()
                      : fileInputRef.current.click()
              }
          >
            {uploadStatus === UploadStatus.Completed ? "Remove" : isVideo ? "Upload Video" : label }
          </Button>
        </div>
      </Card>
  );
};

export default AvatarPicker;
