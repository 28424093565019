import React, { useEffect, useState } from "react";
import { Avatar, Grid, LinearProgress, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { storageRef } from "index";
import { getRandomColor } from "utils/colors";
import {useMediaQuery} from "react-responsive";

const RewardComponent = ({ reward, rewardId , achievedAmount }) => {
    const history = useHistory();
    const [imageUrl, setImageUrl] = useState(null);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    useEffect(() => {
        downloadImage();
    }, []);

    const downloadImage = async () => {
        const url = await storageRef
            .child(`reward_${reward?.id}`)
            .getDownloadURL();
        setImageUrl(url);
    };

    return (
        <Grid
            onClick={() =>{
                //   history.push(`${campaignId}/t/${goal?.Team.name}_${goal?.Team.id}`)
            }}
            item
            xl={12}
            lg={12}
            xs={12}
            sm={12}
        >
            <div style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    marginTop : 20  ,
                    marginBottom : 20 ,
                paddingLeft : 25
                }}>
                <Avatar
                    src={imageUrl}
                    style={{ width: isDesktopOrLaptop ? 80 : 50 , height: isDesktopOrLaptop ? 80 : 50, backgroundColor: getRandomColor() }}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        marginLeft: 20,
                    }}
                >
                    <Typography>{reward?.title}</Typography>
                    <LinearProgress
                        style={{
                            width: "80%",
                            height: 5,
                            borderRadius: 12,
                            marginTop: 5,
                            marginBottom: 5,
                        }}
                        value={
                            achievedAmount ? (parseInt(achievedAmount) / parseInt(reward?.goal)) * 100 >100 ? 100 : (parseInt(achievedAmount) / parseInt(reward?.goal)) * 100 : 0
                        }
                        color="secondary"
                        variant="determinate"
                    />
                    <Typography variant="body1">
                        ${achievedAmount} {" raised of "}${reward?.goal}
                    </Typography>
                </div>
            </div>
        </Grid>
    );
};

export default RewardComponent;
