import React from "react";
import { Typography } from "@material-ui/core";

const ViewHeader = ({ title, subtitle, children }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body2">{subtitle}</Typography>
      </div>
      <span style={{ flex: 1 }} />
      {children}
    </div>
  );
};

export default ViewHeader;
