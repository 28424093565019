import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import AvatarPicker from "./AvatarPicker";

const AvatarPickerDialog = ({
  title = "Upload Image",
  open,
  onClose,
  disabled,
  height,
  width,
  label,
  variant,
  uniqueId,
  style,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <AvatarPicker
          disabled={disabled}
          height={height}
          width={width}
          label={label}
          variant={variant}
          uniqueId={uniqueId}
          style={style}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvatarPickerDialog;
