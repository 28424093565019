import React from "react";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const NotFound404 = () => {
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 50,
      }}
    >
      <Typography variant="h2" color="textSecondary">
        Sorry, Page Not Found
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Sorry, The Page you are looking for either does not exist or you are not
        authorized to view it.
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        style={{ marginTop: 20 }}
        onClick={() => history.push("/")}
      >
        Back to Home
      </Button>
    </div>
  );
};

export default NotFound404;
