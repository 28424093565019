import React, { useEffect, useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Checkbox,
  Typography,
  Button,
} from "@material-ui/core";
import {
  GroupOutlined,
  LocalOfferOutlined,
  DeleteOutlined,
} from "@material-ui/icons";
import CreateGoalForm from "views/admin/campaign/form/CreateGoalForm";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { fetchGoals, updateCampaignGoal } from "store/campaign";
import Loading from "components/Loading";

const CampaignGoalsForm = ({ campaignId }) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [checked, setChecked] = useState([]);
  const { goals, inProgress, currentCampaign } = useSelector(
    (state) => state.campaign
  );

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    if (campaignId && currentCampaign) {
      dispatch(fetchGoals(currentCampaign.organization_id, campaignId));
    }
  }, []);

  useEffect(() => {
    if (selectedGoal) {
      setOpenDialog(true);
    }
  }, [selectedGoal]);

  const onSaveGoal = () => {
    setOpenDialog(false);
    setSelectedGoal(null);
  };

  const onCancelGoal = () => {
    setOpenDialog(false);
    setSelectedGoal(null);
    setChecked([]);
  };

  if (inProgress) {
    return <Loading />;
  }

  return (
    <div style={{ marginTop: 30 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <Typography variant="button" color="secondary">
          Manage Goals for Teams
        </Typography>
        {checked?.length > 1 ? (
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedGoal(null);
              setOpenDialog(true);
            }}
          >
            Set Combine Goal
          </Button>
        ) : null}
      </div>

      {!campaignId && (
        <Alert severity="error" style={{ marginTop: 10, marginBottom: 10 }}>
          Campaign profile missing. Please complete campaign description
          section.
        </Alert>
      )}
      {campaignId && (
        <Paper style={{ marginTop: 20, maxWidth: "50%" }} variant="outlined">
          <List dense>
            {goals && goals.length
              ? goals.map((goal, index) => (
                  <>
                    <ListItem
                      key={goal.id}
                      dense
                      button
                      onClick={handleToggle(index, goal.id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(index) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": goal }}
                        />
                      </ListItemIcon>
                      <ListItemIcon>
                        <GroupOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary={goal.name}
                        secondary={goal.goal ? `$${goal.goal}` : "No Goal Set"}
                      />
                      <ListItemSecondaryAction>
                        {goal.goal && (
                          <IconButton
                            onClick={() =>
                              dispatch(
                                updateCampaignGoal(
                                  currentCampaign.organization_id,
                                  campaignId,
                                  goal.id,
                                  null
                                )
                              )
                            }
                          >
                            <DeleteOutlined />
                          </IconButton>
                        )}
                        <IconButton
                          disabled={!campaignId}
                          onClick={() => {
                            setChecked([]);
                            setSelectedGoal(goal);
                          }}
                        >
                          <LocalOfferOutlined />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {index + 1 < goals.length && <Divider />}
                  </>
                ))
              : null}
          </List>
        </Paper>
      )}
      {selectedGoal || checked.length ? (
        <CreateGoalForm
          orgId={currentCampaign.organization_id}
          onClose={onCancelGoal}
          campaignId={campaignId}
          singleGoal={selectedGoal}
          open={openDialog}
          multipleGoals={
            checked.length > 1
              ? checked.map((goalIndex) => goals[goalIndex])
              : undefined
          }
        />
      ) : null}
    </div>
  );
};

export default CampaignGoalsForm;
