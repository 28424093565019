import React from "react";
import CustomTable from "components/CustomTable";
import moment from "moment";
import { Link } from "@material-ui/core";
import { BASE_URL } from "api-client";

const MyCampaignListing = ({ campaigns, style }) => {
  const tableHead = [
    "Campaign Name",
    "Campaign Address",
    "Campaign Date",
    "Campaign Goal Progress",
  ];

  const formatTableData = (campaigns) =>
    campaigns.map((campaign) => [
      <Link underline="none" href={`${BASE_URL}/${campaign?.publicUrl}`}>
        {campaign?.title}
      </Link>,
      campaign?.address,
      moment(campaign?.createdAt).format("DD/MM/YYYY"),
      `${campaign?.achieved_goal_amount}/${campaign?.total_goal_amount}`,
    ]);

  return (
    <CustomTable
      style={style}
      tableHead={tableHead}
      tableData={
        campaigns && campaigns.length ? formatTableData(campaigns) : []
      }
      noBorder
    />
  );
};

export default MyCampaignListing;
