import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import ProgressButton from "components/ProgressButton";
import {
  addCampaign,
  updateCampaignInfo,
  validateWebAddress,
} from "store/campaign";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {fetchOrganization} from "../../../../store/organization";

const CampaignDescriptionForm = ({ orgId, campaign }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [webAddress, setWebAddress] = useState("");
  const [details, setDetails] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [greetingMsg, setGreetingMsg] = useState("");
  const [goal, setGoal] = useState(0);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [validWebAddress, setValidWebAddress] = useState(false);
  const [isCampaignOnline, setIsCampaignOnline] = useState(false);

  const [inProgress, setProgress] = useState(false);

  const { data: organizations , org } = useSelector((state) => state.organization);

  useEffect(() => {
    if (orgId) {
      setSelectedOrganization(orgId);
    }
  }, [orgId]);

  useEffect(() => {
    const payload = {
      web_address: webAddress,
      campaign_id: campaign ? campaign.id : undefined,
    };
    const delayDebounceFn = setTimeout(async () => {
      const isValid = await dispatch(validateWebAddress(payload));
      setValidWebAddress(isValid);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [webAddress]);


  useEffect(() => {
    if (campaign) {
      console.log("cam" , campaign)
      setTitle(campaign.title);
      setWebAddress(campaign.website);
      setAddress(campaign.address);
      setGoal(campaign.total_goal_amount);
      setGreetingMsg(campaign.thank_message);
      setSelectedManager(campaign.manager_id);
      setSelectedOrganization(campaign.organization_id);
      setDetails(campaign.description);
      setStartDate(campaign.start_date);
      setEndDate(campaign.end_date);
      dispatch(fetchOrganization(campaign.organization_id));
    }

  }, [campaign]);

  const handleSave = async () => {
    setProgress(true);
    const payload = {
      organization_id: selectedOrganization,
      manager_id: selectedManager,
      title: title,
      description: details,
      address: address,
      website: webAddress,
      thank_message: greetingMsg,
      total_goal_amount: goal,
      start_date: startDate,
      end_date: endDate,
      type: isCampaignOnline ? "online" : "onGround",
      image: "image",
    };
    if (campaign) {
      await dispatch(updateCampaignInfo(campaign.id, payload));
    } else {
      await dispatch(addCampaign(payload));
    }
    setProgress(false);
    toast(`Campaign ${campaign ? "Updated" : "Created"} Successfully`, {
      type: "success",
    });
  };

  const handleAddress = async (address) => {
    setAddress(address.label);
  };

  return (
    <Grid
      container
      xs={12}
      lg={8}
      spacing={2}
      style={{ marginTop: 20, marginBottom: 50 }}
    >
      <Grid item xs={12} lg={9}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Select Organization</InputLabel>
          <Select
            disabled={ campaign}
            value={selectedOrganization}
            label="Select Organization"
            onChange={(e) =>
              {
                setSelectedOrganization(e.target.value)
                dispatch(fetchOrganization(e.target.value));
              }
          }
          >
            {organizations
              .filter((o) => o.managers && o.managers.length)
              .map((o) => (
                <MenuItem value={o.id} key={o.id}>
                  {
                    o.name
                  }
                </MenuItem>

              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={9}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Select Manager</InputLabel>
          <Select
            disabled={!!campaign}
            variant="outlined"
            label="Select Manager"
            value={selectedManager}
            onChange={(e) => setSelectedManager(e.target.value)}
          >
            {organizations
              .find((o) => o.id === parseInt(selectedOrganization))
              ?.managers?.map((m) => (
                <MenuItem value={m.id} key={m.id}>
                  {m.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={5}>
        <TextField
          fullWidth
          variant="outlined"
          label="Campaign Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            setWebAddress(e.target.value.split(" ").join("_"));
          }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <TextField
          fullWidth
          variant="outlined"
          label="School Goal"
          type="number"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
        />
      </Grid>
      <Grid item ls={12} lg={9}>
        <TextField
          fullWidth
          variant="outlined"
          error={!validWebAddress}
          helperText={`Campaign Web Address ${
            validWebAddress ? "" : "( Not Available)"
          }`}
          label={`https://www.walkathon.bamrec.com/org/${org?.name ?  org?.name.split(" ").join("_") : ""}/c/`}
          value={webAddress}
          onChange={(e) => {
            setWebAddress(e.target.value.split(" ").join("_"));
          }}
        />
      </Grid>

      <Grid item xs={12} lg={5} style={{ marginLeft: 10 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            clearable
            helperText="Campaign Start Date"
            value={startDate ? startDate : new Date()}
            onChange={(date) => setStartDate(date)}
            format="MM/dd/yyyy"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} lg={4}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            clearable
            helperText="Campaign End Date"
            value={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            format="MM/dd/yyyy"
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12} lg={9}>
        <TextField
          multiline
          fullWidth
          rows={4}
          variant="outlined"
          label="Campaign Details"
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} lg={9}>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          label="Your Thank you Message"
          value={greetingMsg}
          onChange={(e) => setGreetingMsg(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <Grid item xl={12} lg={12}>
          <Typography>Campaign Type</Typography>
          <RadioGroup row>
            <FormControlLabel
              control={<Radio />}
              label="In Person"
              checked={!isCampaignOnline}
              onClick={() => {
                setIsCampaignOnline(false);
                setAddress("");
              }}
            />
            <FormControlLabel
              control={<Radio />}
              label="Online"
              checked={isCampaignOnline}
              onClick={() => {
                // setIsAnonymous(false);
                setIsCampaignOnline(true);
              }}
            />
          </RadioGroup>
        </Grid>
      </Grid>
      {!isCampaignOnline ? (
        <Grid item xs={12} lg={6}>
          <Typography variant="button" color="black">
            {address}
          </Typography>
          <GooglePlacesAutocomplete
            selectProps={{
              address,
              placeholder: "Search Address",
              onChange: handleAddress,
            }}
            apiKey="AIzaSyBLHaBkTB0c6tEPKBso5rIaWJM5OacfaBE"
          ></GooglePlacesAutocomplete>
        </Grid>
      ) : (
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Online Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
      )}

      <Grid item xs={12} lg={9}>
        <ProgressButton
          showProgress={inProgress}
          disabled={
            inProgress ||
            !title ||
            !address ||
            !startDate ||
            !endDate ||
            !selectedOrganization ||
            !selectedManager ||
            !goal ||
            !greetingMsg ||
            !webAddress ||
            !validWebAddress
          }
          color="secondary"
          variant="contained"
          onClick={handleSave}
          label={campaign ? "Update Details" : "Save Details"}
        />
      </Grid>
    </Grid>
  );
};

export default CampaignDescriptionForm;
