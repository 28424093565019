import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  makeStyles, Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import FundraiserCard from "views/public/fundraiser/FundraiserCard";
import TabPanel from "components/TabPanel";
import AdminTopBar from "components/AdminTopBar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMinor } from "store/adult";
import Loading from "components/Loading";
import { colors } from "utils/colors";
import { Updates } from "views/public/team/TeamDetail";
import Footer from "../../../components/Footer";
import ReactPlayer from "react-player";
import {storageRef} from "../../../index";
import RewardComponent from "../reward/RewardComponent";
import ResourceNotFound from "../../../components/ResourceNotFound";

const Story = ({ fundraiserDetail }) => {
  return (
    <div style={{ marginTop: 20 }}>
      <Typography variant="h5" style={{ fontWeight: "bold" }}>
        {fundraiserDetail?.story_title}
      </Typography>
      <Typography variant="body2" style={{ marginTop: 10 }}>
        {fundraiserDetail?.introduction_message}
      </Typography>

    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
}));

export default function FundraiserDetail() {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [kidVideo, setKidVideo] = useState(null);

  const { fundraiser_id } = useParams();

  const fundraiserId = fundraiser_id.split("_")[1];
  const { inProgress, fundraiser: fundraiserDetail } = useSelector(
    (state) => state.adult
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (fundraiserId) {
      dispatch(fetchMinor(fundraiserId));
    }
  }, [dispatch, fundraiserId]);

  useEffect(() => {
    downloadVideo();
  }, [fundraiserDetail]);
  const downloadVideo = async () => {
    try {
      try {
        const kidVideo = `minor_video_${fundraiserId}`;
        const kidVideoUrl = await storageRef.child(kidVideo).getDownloadURL();
        setKidVideo(kidVideoUrl);
      } catch (e) {}
    } catch (err) {}
  };


  return (
    <div>
      <AdminTopBar />
      <div className={classes.toolbar} />
      {inProgress && !fundraiserDetail ? (
        <Container>
          <Loading />
        </Container>
      ) : (
        <>
          <Container maxWidth="lg" style={{ marginTop: 1 , marginBottom : 10 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                <FundraiserCard fundraiserDetail={fundraiserDetail} />
                <Grid item xl={12} lg={12} style={{marginTop : 30}} >
                  <Paper elevation={2} style={{ paddingBottom : 15 , paddingTop: 25, borderRadius: 20  , paddingRight : 10}}>
                    <Typography variant="h5">
                            <span style={{ fontWeight: "bold" , paddingLeft : 25 , paddingRight : 25 }}>
                              Get a Reward
                            </span>
                    </Typography>

                    {fundraiserDetail &&
                    fundraiserDetail.rewards &&
                    fundraiserDetail.rewards.length ? (
                        fundraiserDetail.rewards
                            .slice(0)
                            .sort((a, b) => {
                              const x = parseInt(a.end_date);
                              const y = parseInt(b.end_date);
                              return x > y ? -1 : x < y ? 1 : 0;
                            })
                            .map((reward) => (
                                <div style={{marginTop : 15}}>
                                  <RewardComponent reward={reward} achievedAmount={reward?.minorAchievedAmount}/>
                                </div>
                            ))
                    ) : (
                        <ResourceNotFound />
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Container>
                  <Tabs
                    value={tabIndex}
                    onChange={(e, index) => setTabIndex(index)}
                    style={{
                      marginTop: 20,
                      borderBottom: `0.5px solid ${colors.offwhite}`,
                    }}
                  >
                    <Tab label="Story" />
                    <Tab label="Updates" />
                  </Tabs>
                  <TabPanel value={tabIndex} index={0}>
                    <>
                      <Story fundraiserDetail={fundraiserDetail} />
                      {kidVideo && <ReactPlayer url={kidVideo} controls={true}  style={{marginTop : 30}} />}

                    </>

                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <Updates updates={fundraiserDetail?.donations} />
                  </TabPanel>
                </Container>
              </Grid>
            </Grid>
          </Container>
          <Footer/>
        </>
      )}
    </div>
  );
}
