import { API_BASE_URL } from "api-client/index";
import axios from "axios";

export const createReward = (payload) => {
    const uri = `${API_BASE_URL}/reward/`;
    return axios.post(uri, payload);
};
export const rewardToPerson = ( reward_id , minor_id , team_id) => {
    const uri = `${API_BASE_URL}/reward/give/${reward_id}/team/${team_id}/member/${minor_id}`;
    return axios.post(uri);
};


export const getRewards = () => {
    const uri = `${API_BASE_URL}/reward/`;
    return axios.get(uri);
};

export const getReward = (rewardId) => {
    const uri = `${API_BASE_URL}/reward/${rewardId}`;
    return axios.get(uri);
};

export const editReward = (rewardId, reward) => {
    const uri = `${API_BASE_URL}/reward/${rewardId}`;
    return axios.put(uri, reward);
};

export const deleteReward = (rewardId) => {
    const uri = `${API_BASE_URL}/reward/${rewardId}`;
    return axios.delete(uri);
};
