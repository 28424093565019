import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { colors } from "utils/colors";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { NightsStayOutlined, WbSunnyOutlined } from "@material-ui/icons";
import AppBar from "@material-ui/core/AppBar";
import authSlice, { signOut } from "store/auth";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, Switch } from "@material-ui/core";
import LoginDialog from "components/LoginDialog";
import { getConsoleHome } from "routes";
import { useMediaQuery } from "react-responsive";
import AppbarLogo from "assets/appbar-logo.svg";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.default,
    border: "none", // border less Appbar, use with outlined variant
  },
  toolbar: {
    backgroundColor: theme.palette.background.default,
    color: colors.dark,
    marginLeft: 50,
    marginRight: 50,
    marginTop: 15,
    marginBottom: 15,
  },
  logo: {
    height: 48,
    width: 48,
    cursor: "pointer",
  },
  logoMobile: {
    height: 38,
    width: 38,
    cursor: "pointer",
  },
}));

const DesktopActionBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const { isDarkTheme, user } = useSelector((state) => state.auth);
  const [isLoginShowing, showLogin] = useState(false);

  return (
      <AppBar position="fixed" variant="outlined" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img
              className={classes.logo}
              alt="Remy Sharp"
              src={AppbarLogo}
              onClick={() => history.push("/")}
          />
          <span style={{ flexGrow: 1 }} />
          {user?.displayName ? (
              <Typography
                  color="textSecondary"
                  variant="body1"
                  style={{ marginRight: 30 }}
              >
                Welcome {user.displayName ? capitalize(user?.displayName) : ""}
              </Typography>
          ) : null}
          {/* {user && !location.pathname.includes("admin") ? (
          <Button
            style={{ marginRight: 20 }}
            variant="outlined"
            onClick={() => history.push(getConsoleHome(user))}
          >
            Go to Account
          </Button>
        ) : null} */}
        {user ? (
              <Button
                  style={{ marginRight: 20 }}
                  variant="outlined"
                  onClick={() => history.push(getConsoleHome(user))}
              >
                Go to Account
              </Button>
          ) : null}
          <WbSunnyOutlined color="action" />
          <Switch
              checked={isDarkTheme}
              size="medium"
              onChange={(e) =>
                  dispatch(authSlice.actions.setDarkTheme(e.target.checked))
              }
          />
          <NightsStayOutlined color="action" />
          <Button
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              style={{
                color: "white",
                paddingLeft: 35,
                paddingRight: 35,
                marginLeft: 20,
                fontWeight: "bold",
              }}
              onClick={async () => {
                if (user) {
                  await dispatch(signOut());
                  history.push("/");
                } else {
                  showLogin(true);
                }
              }}
          >
            {user ? "Logout" : "Login"}
          </Button>
        </Toolbar>
        <LoginDialog open={isLoginShowing} onClose={() => showLogin(false)} />
      </AppBar>
  );
};

const MobileActionBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const [isLoginShowing, showLogin] = useState(false);

  return (
    <AppBar position="fixed" variant="outlined" className={classes.appBar}>
      <Toolbar className={classes.toolbarMobile}>
        <img
          className={classes.logoMobile}
          alt="Remy Sharp"
          src={AppbarLogo}
          onClick={() => history.push("/")}
        />
        <span style={{ flexGrow: 1 }} />
        {/* {user && !location.pathname.includes("admin") ? (
            <Button
                style={{ marginRight: 10 }}
                size="small"
                variant="outlined"
                onClick={() => history.push(getConsoleHome(user))}
            >
              My Account
            </Button>
        ) : null} */}
        {user ? (
          <Button
            style={{ marginRight: 10 }}
            size="small"
            variant="outlined"
            onClick={() => history.push(getConsoleHome(user))}
          >
            My Account
          </Button>
        ) : null}
        <Button
          variant="contained"
          size="small"
          color="primary"
          disableElevation
          style={{
            color: "white",
            fontWeight: "bold",
            textTransform: "none",
          }}
          onClick={async () => {
            if (user) {
              await dispatch(signOut());
              history.push("/");
            } else {
              showLogin(true);
            }
          }}
        >
          {user ? "Logout" : "Login"}
        </Button>
      </Toolbar>
      <LoginDialog open={isLoginShowing} onClose={() => showLogin(false)} />
    </AppBar>
  );
};

const AdminTopBar = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
      <>
        {isDesktopOrLaptop && <DesktopActionBar />}
        {isTabletOrMobile && <MobileActionBar />}
      </>
  );
};

export default AdminTopBar;
