import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import Profile from "./MyOrganization/Profile";
import ProfileDetails from "./MyOrganization/ProfileDetails";
import ViewHeader from "components/ViewHeader";
import { useDispatch, useSelector } from "react-redux";
import { addOrganization, updateOrg } from "store/organization";
import { toast } from "react-toastify";
import ProgressButton from "components/ProgressButton";
import { useHistory, useParams } from "react-router-dom";

const OrganizationForm = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = useSelector((state) => state.organization);
  const [org, setOrg] = useState(null);
  const [inProgress, setProgress] = useState(false);
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    if (orgId && data) {
      const organization = data.find((org) => org.id === parseInt(orgId));
      setOrganization(organization);
    }
  }, [orgId, data]);

  const isValid = (organization) => {
    if (
      organization &&
      organization.name &&
      organization.email &&
      organization.address &&
      organization.phone_number &&
      organization.website &&
      organization.admin_name &&
      organization.admin_phone_number &&
      organization.admin_email &&
      organization.admin_password &&
      organization.admin_password.length >= 8
    ) {
      return true;
    } else if (
      organization &&
      organization.name &&
      organization.email &&
      organization.address &&
      organization.phone_number &&
      organization.website &&
      orgId
    ) {
      return true;
    }
    return false;
  };

  const handleChange = (values) => setOrg(values);

  const onSubmit = async () => {
    setProgress(true);
    const payload = {
      organization_name: org.name,
      organization_email: org.email,
      organization_address: org.address,
      organization_phone_number: org.phone_number,
      organization_website: org.website,
      admin_name: org.admin_name,
      admin_email: org.admin_email,
      admin_password: org.admin_password,
      admin_phone_number: org.admin_phone_number,
    };
    if (orgId && organization) {
      await dispatch(updateOrg(organization.id, payload));
    } else {
      await dispatch(addOrganization(payload));
    }
    setProgress(false);
    history.goBack();
    toast(
      `Organization ${
        orgId && organization ? "Updated" : "Created"
      } Successfully`,
      {
        type: "success",
      }
    );
  };

  return (
    <div>
      <ViewHeader
        title={orgId ? "Edit Organization" : "Create Organization"}
        subtitle="Create or Edit an exiting organization"
      >
        <ProgressButton
          showProgress={inProgress}
          variant="contained"
          label="Save Changes"
          color="secondary"
          disabled={!isValid(org) || inProgress}
          onClick={onSubmit}
        />
      </ViewHeader>
      <Grid container spacing={3} style={{ marginTop: 30 }}>
        {orgId ? (
          <Grid item lg={4} md={6} xs={12}>
            <Profile orgId={orgId} />
          </Grid>
        ) : (
          <> </>
        )}
        <Grid item lg={8} md={6} xs={12}>
          <ProfileDetails
            orgId={orgId}
            onChange={handleChange}
            organization={organization}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default OrganizationForm;
