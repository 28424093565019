import {
  InsertChartOutlined,
  PaymentOutlined,
  PaymentTwoTone,
  PeopleOutline,
  PieChartOutlined,
  School,
  EmojiEventsOutlined, Settings
} from "@material-ui/icons";
import Report from "views/admin/report/Report";
import Campaigns from "views/admin/campaign/Campaigns";
import Organizations from "views/admin/organization/Organizations";
import OrganizationDetail from "views/admin/organization/OrganizationDetail";
import Adults from "views/admin/adult/Adults";
import OfflineDonation from "views/admin/offline-donation/OfflineDonation";
import { Role } from "utils/roles";
import AdultDetail from "views/admin/adult/AdultDetail";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NewMinorForm from "views/admin/adult/NewMinorForm";
import React from "react";
import CampaignForm from "views/admin/campaign/CampaignForm";
import CampaignDetail from "views/admin/campaign/CampaignDetail";
import Rewards from "./views/admin/rewards/Rewards";
import Setting from "./views/admin/setting/Setting";

const basePath = "/admin";

export const getConsoleHome = (user) => {
  switch (user?.role) {
    case Role.Adult:
      return `${basePath}/adults/${user?.systemId}`;
    case Role.SuperAdmin:
      return `${basePath}/organizations`;
    case Role.Admin:
      return `${basePath}/organizations`;
  }
};

const AdultRouter = () => {
  const { path } = useRouteMatch();
  console.log(path)
  return (
    <Switch>
      <Route path={`${path}/minor/:minorId`}>
        <NewMinorForm />
      </Route>
      <Route exact path={path}>
        <AdultDetail />
      </Route>
    </Switch>
  );
};
const SettingRouter = () => {
  const { path } = useRouteMatch();
  console.log(path)
  return (
      <Switch>
        <Route exact path={`${path}`}>
          <Setting />
        </Route>
      </Switch>
  );
};

const AdminRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/campaign/new`}>
        <CampaignForm />
      </Route>
      <Route path={`${path}/campaigns/edit/:campaignId`}>
        <CampaignForm />
      </Route>
      <Route path={`${path}/campaigns/:campaignId`}>
        <CampaignDetail />
      </Route>
      <Route exact path={path}>
        <OrganizationDetail />
      </Route>
    </Switch>
  );
};

export const getRoutesBasedOnRole = (user) => {
  switch (user.role) {
    case Role.Admin:
      return AdminRoutes;
    case Role.Adult:
      return [
        {
          name: "Fundraisers",
          icon: PeopleOutline,
          path: `${basePath}/adults/${user?.systemId}`,
          component: AdultRouter,
        },
        {
          name: "Settings",
          icon: Settings,
          path: `${basePath}/setting/`,
          component: SettingRouter,
        },
      ];
    case Role.SuperAdmin:
      return SuperAdminRoutes;
    default:
      return [];
  }
};

export const SuperAdminRoutes = [
  {
    name: "Organizations",
    icon: School,
    path: `${basePath}/organizations`,
    component: Organizations,
  },
  {
    name: "Campaigns",
    icon: PaymentOutlined,
    path: `${basePath}/campaigns`,
    component: Campaigns,
  },
  {
    name: "Adults",
    icon: PeopleOutline,
    path: `${basePath}/adults`,
    component: Adults,
  },
  {
    name: "Reports",
    icon: PieChartOutlined,
    path: `${basePath}/reports`,
    component: Report,
  },
  {
    name: "Offline Donation",
    icon: PaymentTwoTone,
    path: `${basePath}/offline-donation`,
    component: OfflineDonation,
  },
  {
    name: "Rewards",
    icon: EmojiEventsOutlined,
    path: `${basePath}/rewards`,
    component: Rewards,
  },

];

export const AdminRoutes = [
  {
    name: "My Organization",
    icon: InsertChartOutlined,
    path: `${basePath}/organizations`,
    component: AdminRouter,
  },
  {
    name: "Adults",
    icon: PeopleOutline,
    path: `${basePath}/adults`,
    component: Adults,
  },
  {
    name: "Offline Donation",
    icon: PaymentTwoTone,
    path: `${basePath}/offline-donation`,
    component: OfflineDonation,
  },
  {
    name: "Settings",
    icon: Settings,
    path: `${basePath}/setting/`,
    component: SettingRouter,
  },
];
