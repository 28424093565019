import { API_BASE_URL } from "api-client/index";
import axios from "axios";

export const offlineDonation = (payload) => {
  const uri = `${API_BASE_URL}/donation/offline`;
  return axios.post(uri, payload);
};

export const stripeCheckout = (payload) => {
  const uri = `${API_BASE_URL}/donation/create-checkout-session`;
  return axios.post(uri, payload);
};

export const DonatFromStrip = (payload) => {
  const uri = `${API_BASE_URL}/donation/stripe`;
  return axios.post(uri, payload);
};
