import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

const ProgressButton = ({ label, showProgress, ...rest }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {showProgress && (
        <CircularProgress
          color="secondary"
          style={{ marginRight: 15 }}
          variant="indeterminate"
          size={24}
        />
      )}
      <Button {...rest}>{label}</Button>
    </div>
  );
};

export default ProgressButton;
