import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { colors } from "utils/colors";
import Campaign from "components/Campaign";
import { Container, Grid, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaigns } from "store/campaign";
import ResourceNotFound from "components/ResourceNotFound";
import Loading from "components/Loading";
import AdminTopBar from "components/AdminTopBar";
import Footer from "components/Footer";
import SortMenu, { Sort } from "components/SortMenu";
import { useMediaQuery } from "react-responsive";
import Banner1 from "assets/image 166.png";
import Banner2 from "assets/image 160.png";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    flexGrow: 1,
    fontWeight: "bold",
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    height: 40,
    width: 40,
  },
  loginInLogo: {
    height: 60,
    width: 60,
    margin: "auto",
    marginTop: 20,
  },
  cardRoot: {
    justifyContent: "center",
    flex: 1,
    height: "100%",
    width: "60%",
    margin: "auto",
    marginTop: 10,
    marginBottom: 10,
    alignContent: "center",
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56,
  },
  campaignTitle: {
    textAlign: "center",
    margin: 30,
  },
  loginDialog: {
    justifyContent: "center",
    minWidth: 250,
  },
  dialogTitle: {
    margin: "auto",
    fontWeight: "bold",
    fontSize: "20px",
  },
  gridContainer: {
    flexGrow: 1,
  },
}));

export default function Landing() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, inProgress } = useSelector((state) => state.campaign);
  const [showAll, setShowAll] = useState(false);
  const [sortOrder, setSortOrder] = useState(Sort.ByNameAsc);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, [dispatch]);
  return (
    <div className={classes.root}>
      <AdminTopBar />
      <div className={classes.toolbar} />
      <Container
        style={{
          marginTop: isDesktopOrLaptop ? 20 : 15,
          padding: isDesktopOrLaptop ? 35 : 20,
        }}
      >
        <Grid container spacing={2} direction="row" alignItems="center">
          {!isDesktopOrLaptop ? (
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{
                marginLeft: 50,
                marginBottom: 10,
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src={Banner1}
                  height={200}
                  width={115}
                  alt="banner"
                  style={{
                    borderRadius: "15px",
                  }}
                />
                <img
                  src={Banner2}
                  height={135}
                  width={100}
                  alt="banner"
                  style={{
                    borderRadius: "15px",
                    marginLeft: 20,
                  }}
                />
                <Paper
                  style={{
                    backgroundColor: colors.green,
                    height: 78,
                    position: "relative",
                    left: -125,
                    top: 125,
                  }}
                  elevation={0}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    <div style={{ height: 32, width: 32 }}>
                      <CircularProgressbar
                        value={75}
                        text="75%"
                        maxValue={100}
                        strokeWidth={12}
                        styles={buildStyles({
                          rotation: 0.25,
                          strokeLinecap: "round",
                          textSize: "26px",
                          pathTransitionDuration: 0.5,
                          pathColor: colors.white,
                          textColor: colors.white,
                          trailColor: "#d6d6d6",
                          backgroundColor: colors.white,
                        })}
                      />
                    </div>
                    <Typography
                      style={{ color: "white", marginTop: 7, fontSize: 5 }}
                      variant="caption"
                    >
                      raised of $10,000 goal
                    </Typography>
                  </div>
                </Paper>
              </div>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography
              variant={isDesktopOrLaptop ? "h3" : "h5"}
              style={{ fontWeight: "700", fontFamily: "Raleway" }}
            >
              Fundraising Campaigns Changing the World
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{
                marginTop: 20,
                fontWeight: "400",
                fontFamily: "Raleway",
              }}
            >
              See how our customers are attracting and converting more
              supporters through beautiful online fundraising pages that are
              easy to navigate.
            </Typography>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              size="large"
              style={
                isDesktopOrLaptop
                  ? { color: colors.white, marginTop: 40 }
                  : { color: colors.white, marginTop: 40, width: "100%" }
              }
            >
              See All Campaigns
            </Button>
          </Grid>
          {isDesktopOrLaptop ? (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div style={{ display: "flex" }}>
                <img
                  src={Banner1}
                  height={300}
                  width={180}
                  alt="banner"
                  style={{
                    borderRadius: "15px",
                  }}
                />
                <img
                  src={Banner2}
                  height={220}
                  width={160}
                  alt="banner"
                  style={{
                    borderRadius: "15px",
                    marginLeft: 20,
                  }}
                />
                <Paper
                  style={{
                    backgroundColor: colors.green,
                    height: 155,
                    position: "relative",
                    left: -250,
                    top: 170,
                  }}
                  elevation={0}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                    }}
                  >
                    <div style={{ height: 65, width: 65 }}>
                      <CircularProgressbar
                        value={75}
                        text="75%"
                        maxValue={100}
                        strokeWidth={12}
                        styles={buildStyles({
                          rotation: 0.25,
                          strokeLinecap: "round",
                          textSize: "26px",
                          pathTransitionDuration: 0.5,
                          pathColor: colors.white,
                          textColor: colors.white,
                          trailColor: "#d6d6d6",
                          backgroundColor: colors.white,
                        })}
                      />
                    </div>
                    <Typography
                      style={{ color: "white", marginTop: 15 }}
                      variant="caption"
                    >
                      raised of $10,000 goal
                    </Typography>
                  </div>
                </Paper>
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Container>

      <Typography
        variant="h5"
        align="center"
        style={{ margin: 50, fontWeight: "bold", fontFamily: "Raleway" }}
      >
        Top Campaigns
      </Typography>
      <div style={{ display: "none", justifyContent: "center", margin: 20 }}>
        <SortMenu
          title="Sort Campaigns"
          onSortChange={(s) => setSortOrder(s)}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
        <Grid
          container
          lg={8}
          xl={8}
          sm={8}
          md={8}
          xs={8}
          justify="center"
          alignItems="center"
          spacing={2}
        >
          {inProgress && <Loading />}
          {data && data.length
            ? data
                .filter((c) => c.status === "published")
                .sort((a, b) => {
                  if (sortOrder === Sort.ByNameAsc) {
                    const x = a.title.toLowerCase();
                    const y = b.title.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                  } else {
                    const x = parseInt(a.achieved_goal_amount);
                    const y = parseInt(b.achieved_goal_amount);
                    return x > y ? -1 : x < y ? 1 : 0;
                  }
                })
                .slice(0, showAll ? data.length : 3)
                .map((c, index) => (
                  <Grid
                    item
                    lg={isDesktopOrLaptop ? 4 : 10}
                    xl={isDesktopOrLaptop ? 4 : 10}
                    sm={isDesktopOrLaptop ? 4 : 10}
                    md={isDesktopOrLaptop ? 4 : 10}
                    xs={isDesktopOrLaptop ? 4 : 10}
                    key={c.id}
                    alignItems="center"
                    justify="center"
                  >
                    <Campaign
                      id={c.id}
                      website={c.website}
                      title={c.title}
                      org={c?.Organization?.name}
                      description={c.description}
                      image={`campaign_profile_${c.id}`}
                      achievedAmount={c.achieved_goal_amount}
                      totalGoal={c.total_goal_amount}
                      goal={`$${c.achieved_goal_amount} raised of $${c.total_goal_amount}`}
                      percent={
                        (parseInt(c.achieved_goal_amount) /
                          parseInt(c.total_goal_amount)) *
                        100
                      }
                    />
                  </Grid>
                ))
            : !inProgress && <ResourceNotFound />}
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 20,
          marginRight: 150,
          marginBottom: 100,
        }}
      >
        {!inProgress && !showAll && data && data.length > 3 ? (
          <Button
            variant="text"
            endIcon={<ArrowRight color="action" />}
            onClick={() => setShowAll(true)}
          >
            See more
          </Button>
        ) : null}
      </div>
      <Footer />
    </div>
  );
}
