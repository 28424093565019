import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import "firebase/auth";
import firebase from "firebase";
import { FIREBASE_CONFIG } from "config/firebase-config";
import authSlice from "store/auth";
import store from "store";

firebase.initializeApp(FIREBASE_CONFIG);
const storage = firebase.storage();
const firestore = firebase.firestore();
export const storageRef = storage.ref("images");
store.dispatch(authSlice.actions.setProgress(true));

firebase.auth().onAuthStateChanged(async (fireUser) => {
  if (fireUser) {
    const doc = await firestore.collection("users").doc(fireUser.uid).get();
    const user = {
      id: fireUser.uid,
      displayName: fireUser.displayName,
      photoURL: fireUser.photoURL,
      email: fireUser.email,
      role: doc?.data()?.role,
      systemId: doc?.data()?.systemId,
      orgId: doc?.data()?.orgId,
    };
    store.dispatch(authSlice.actions.setUser(user));
    store.dispatch(authSlice.actions.setAuthenticated(true));
    store.dispatch(authSlice.actions.setProgress(false));
  } else {
    store.dispatch(authSlice.actions.setUser(null));
    store.dispatch(authSlice.actions.setAuthenticated(false));
    store.dispatch(authSlice.actions.setProgress(false));
  }
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
