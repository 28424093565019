import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { NavLink, useLocation } from "react-router-dom";
import { getRoutesBasedOnRole } from "../routes";
import { colors } from "utils/colors";
import Typography from "@material-ui/core/Typography";
import AdminTopBar from "components/AdminTopBar";
import { Container } from "@material-ui/core";
import SecureRoute from "components/SecureRoute";
import NotFound404 from "components/NotFound404";
import { useSelector } from "react-redux";

const drawerWidth = 100;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerItem: {
    display: "flex",
    justifyContent: "center",
    height: 70,
    paddingTop: 10,
    paddingBottom: 10,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.default,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  drawerContent: {
    marginTop: 100,
  },
  inactiveNavIcon: {
    color: colors.grey,
  },
  activeNavIcon: {
    color: theme.palette.secondary.main,
  },
}));

const Template = () => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  if (!user) {
    return <NotFound404 />;
  }
  const routes = getRoutesBasedOnRole(user);
  return (
    <div className={classes.root}>
      <AdminTopBar className={classes.appBar} />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List className={classes.drawerContent}>
          {routes.map((route) => (
            <NavLink
              to={route.path}
              key={route.name}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <ListItem button className={classes.drawerItem}>
                {
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <route.icon
                      className={
                        pathname.indexOf(route.path) >= 0
                          ? classes.activeNavIcon
                          : classes.inactiveNavIcon
                      }
                      fontSize="large"
                    />
                    <Typography
                      style={{ textAlign: "center" }}
                      variant="caption"
                    >
                      {route.name}
                    </Typography>
                  </div>
                }
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {routes.map((route) => (
          <SecureRoute path={route.path} exact={false} key={route.name}>
            <Container style={{ paddingTop: 20 }}>
              {<route.component />}
            </Container>
          </SecureRoute>
        ))}
      </main>
    </div>
  );
};

export default Template;
