import React, { useEffect } from "react";
import { Button, capitalize, Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { stripeDonation } from "store/donation";
import { useDispatch } from "react-redux";
import {colors} from "../../../utils/colors";
import {useMediaQuery} from "react-responsive";

const NotFound404 = () => {
  const history = useHistory();
  const { name, email, amount, campaignId, teamId, orgName, fundraiserId , stripeSessionId  ,isAnonymous} =
    useParams();
  const dispatch = useDispatch();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  useEffect(async () => {
    let newName;
    if (name.includes("_")) {
      newName = name.split("_").join(" ");
    } else {
      newName = name;
    }
    const payload = {
      name: newName,
      amount: amount,
      email: email,
      campaignId: campaignId,
      teamId: teamId,
      fundraiserId: fundraiserId,
      orgName: orgName,
      stripeSessionId : stripeSessionId,
      isAnonymous : isAnonymous
    };
    await dispatch(stripeDonation(payload));
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 50,
      }}
    >
      <img
        height={300}
        style={{ borderRadius: 50, marginBottom: 40 }}
        width= {isDesktopOrLaptop ? "20%" : "90%"}
        src="https://www.insidehighered.com/sites/default/server_files/media/Harvest%20second%20thank%20you.jpg"
      />

      <Typography variant="h4" color="textSecondary"
                  style={{
                    margin : isDesktopOrLaptop ? 0 : 15,
                    padding: isDesktopOrLaptop ? 35 : 20,
                  }}
      >
        Thank you {capitalize(name)} for your Donation of ${amount} USD !
      </Typography>
      <Typography variant="body2" color="textSecondary">
        You have successfully donated .
      </Typography>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: 20 , color: colors.white }}
        onClick={() => history.push(`/org/${orgName}/c/${campaignId}`)}
      >
        Back to Home
      </Button>
    </div>
  );
};

export default NotFound404;
