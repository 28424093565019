import React, { useState } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  FilterListOutlined,
  TodayOutlined,
  WeekendOutlined,
} from "@material-ui/icons";

export const Sort = {
  ByNameAsc: "ByNameAsc",
  ByNameDesc: "ByNameDesc",
  ByGoalAsc: "ByGoalAsc",
  ByGoalDesc: "ByGoalDesc",
};

const SortMenu = ({ title, onSortChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleSortChange = (sort) => {
    onSortChange(sort);
    setAnchorEl(null);
  };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginLeft: 5 }}
        >
          {title}
        </Typography>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <FilterListOutlined />
        </IconButton>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleSortChange(Sort.ByNameAsc)}>
          <ListItemIcon>
            <TodayOutlined />
          </ListItemIcon>
          <ListItemText>By Name</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleSortChange(Sort.ByGoalAsc)}>
          <ListItemIcon>
            <WeekendOutlined />
          </ListItemIcon>
          <ListItemText>By Goal</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SortMenu;
