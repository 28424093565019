import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import authSlice from "store/auth";
import organizationSlice from "store/organization";
import campaignSlice from "store/campaign";
import managerSlice from "store/manager";
import teamSlice from "store/team";
import adultSlice from "store/adult";
import donationSlice from "store/donation";
import rewardSlice from "./reward";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    organization: organizationSlice.reducer,
    campaign: campaignSlice.reducer,
    manager: managerSlice.reducer,
    team: teamSlice.reducer,
    adult: adultSlice.reducer,
    donation: donationSlice.reducer,
    reward : rewardSlice.reducer
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});

export default store;
