import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import OrganizationListing from "views/admin/organization/OrganizationListing";
import OrganizationForm from "views/admin/organization/OrganizationForm";
import OrganizationDetail from "views/admin/organization/OrganizationDetail";
import CampaignForm from "views/admin/campaign/CampaignForm";

const Organizations = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:orgId/campaign/new`}>
        <CampaignForm />
      </Route>
      <Route exact path={path}>
        <OrganizationListing />
      </Route>
      <Route path={`${path}/new`}>
        <OrganizationForm />
      </Route>
      <Route path={`${path}/edit/:orgId`}>
        <OrganizationForm />
      </Route>
      <Route path={`${path}/:orgId`}>
        <OrganizationDetail />
      </Route>
    </Switch>
  );
};

export default Organizations;
