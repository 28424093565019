import React from "react";
import CustomTable from "components/CustomTable";
import { Typography } from "@material-ui/core";

const DonationReport = ({ donations }) => {
  const tableHead = ["Donor Name", "Amount", "Type", "Created At"];
  const formatTableData = (data) => {
    return data?.map((donation) => [
      <div>
        <Typography>{donation?.Donor?.full_name}</Typography>
        <Typography variant="caption" color="textSecondary">
          {donation.Donor?.eamil}
        </Typography>
      </div>,
      `$ ${donation.amount}`,
      donation.type,
      `${new Date(donation.createdAt).toLocaleDateString()}   -  ${new Date(donation.createdAt).toLocaleTimeString()}`,
    ]);
  };

  return (
    <CustomTable
      tableHead={tableHead}
      tableData={
        donations && donations.length ? formatTableData(donations) : []
      }
      noBorder
      withPagination={false}
      style={{ marginTop: 20 }}
    />
  );
};
export default DonationReport;
