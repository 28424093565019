import React from "react";
import { Grid, Typography } from "@material-ui/core";
import AvatarPicker from "components/AvatarPicker";
import { Alert } from "@material-ui/lab";

const CampaignAssetForm = ({ campaignId }) => (
  <div style={{ marginTop: 30 }}>
    <Typography variant="button" color="secondary">
      Campaign Assets
    </Typography>
    {!campaignId && (
      <Alert severity="error" style={{ marginTop: 10, marginBottom: 10 }}>
        Campaign profile missing. Please complete campaign description section.
      </Alert>
    )}
    {campaignId && (
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item>
          <AvatarPicker
            variant="outlined"
            disabled={!campaignId}
            height={150}
            width={150}
            uniqueId={`campaign_profile_${campaignId}`}
          />
        </Grid>
        <Grid item>
          <AvatarPicker
            variant="outlined"
            height={150}
            width={450}
            disabled={!campaignId}
            label="Upload Cover Image"
            uniqueId={`campaign_cover_${campaignId}`}
          />
        </Grid>
      </Grid>
    )}
  </div>
);
export default CampaignAssetForm;
