import React from "react";
import { Alert } from "@material-ui/lab";

const ResourceNotFound = ({ message = "No Resources Available" }) => {
  return (
    <Alert severity="info" style={{ margin: 10, marginBottom: 20 }}>
      {message}
    </Alert>
  );
};

export default ResourceNotFound;
