import axios from "axios";
import { API_BASE_URL } from "api-client/index";

export const getCampaigns = () => {
  const uri = `${API_BASE_URL}/campaign`;
  return axios.get(uri);
};
export const getCampaign = (campaignId, adultId) => {
  const uri = `${API_BASE_URL}/campaign/by_id/${campaignId}?adult_id=${adultId}`;
  return axios.get(uri);
};

export const getCampaignByWebAddress = (campaignId, adultId) => {
  const uri = `${API_BASE_URL}/campaign/by_website/${campaignId}?adult_id=${adultId}`;
  return axios.get(uri);
};
export const getCampaignsOfOrganizations = (orgId) => {
  const uri = `${API_BASE_URL}/campaign/by_organization/${orgId}`;
  return axios.get(uri);
};

export const createCampaign = (payload) => {
  const uri = `${API_BASE_URL}/campaign/`;
  return axios.post(uri, payload);
};
export const validateWebAddressCampaign = (payload) => {
  const uri = `${API_BASE_URL}/campaign/validate/web_address`;
  return axios.post(uri, payload);
};
export const updateCampaign = (campaignId, payload) => {
  const uri = `${API_BASE_URL}/campaign/edit/${campaignId}`;
  return axios.put(uri, payload);
};

export const getFaqs = (campaignId) => {
  const uri = `${API_BASE_URL}/campaign/faq/${campaignId}`;
  return axios.get(uri);
};

export const deleteFaq = (faqId) => {
  const uri = `${API_BASE_URL}/campaign/faq/remove/${faqId}`;
  return axios.delete(uri);
};
export const deleteCampaign = (campaignId) => {
  const uri = `${API_BASE_URL}/campaign/${campaignId}`;
  return axios.delete(uri);
};


export const createFaq = (campaignId, payload) => {
  const uri = `${API_BASE_URL}/campaign/add/faq/${campaignId}`;
  return axios.post(uri, payload);
};

export const updatedFaq = (faqId, payload) => {
  const uri = `${API_BASE_URL}/campaign/faq/${faqId}`;
  return axios.put(uri, payload);
};

export const getGoals = (orgId, campaignId) => {
  const uri = `${API_BASE_URL}/team/goals/organization/${orgId}/campaign/${campaignId}`;
  return axios.get(uri);
};

export const createGoal = (payload) => {
  const uri = `${API_BASE_URL}/campaign/set_goal`;
  return axios.post(uri, payload);
};

export const updateGoal = (payload) => {
  const uri = `${API_BASE_URL}/campaign/update_goal`;
  return axios.put(uri, payload);
};

export const publishCampaign = (campaignId) => {
  const uri = `${API_BASE_URL}/campaign/publish/${campaignId}`;
  return axios.post(uri);
};
