import React, { useEffect, useState } from "react";
import ViewHeader from "components/ViewHeader";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import TabPanel from "components/TabPanel";
import DonationReport from "views/admin/report/DonationReport";
import FundraiserReport from "views/admin/report/FundraiserReport";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganizations } from "store/organization";
import {
  fetchCampaignDetails,
  fetchCampaignsOfOrganizations,
} from "store/campaign";

const Report = () => {
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const { data } = useSelector((state) => state.campaign);

  const { data: organizations } = useSelector((state) => state.organization);
  const [readyToGetCampaign, setReadyToGetCampaign] = useState(false);
  const [readyToGetReports, setReadyToGetReports] = useState(false);

  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedCampaignOrganization, setSelectedCampaignOrganization] =
    useState("");
  const { currentCampaign: campaignDetails } = useSelector(
    (state) => state.campaign
  );
  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  useEffect(async () => {
    if (organizations) {
      await dispatch(fetchCampaignsOfOrganizations(selectedOrganization));
    }
  }, [readyToGetCampaign]);

  useEffect(async () => {
    dispatch(fetchCampaignDetails(selectedCampaign, undefined));
  }, [readyToGetReports]);

  return (
    <>
      <ViewHeader title="Reports" subtitle="Summary of Donations and Goals" />
      <Grid container spacing={4} style={{ marginTop: 15 }}>
        <Grid item xl={8} lg={8}>
          <Paper variant="outlined">
            <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
              <Tab label="Fundraisers" />
              <Tab label="Donations" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <FundraiserReport fundraisers={campaignDetails?.fundraiser} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <DonationReport donations={campaignDetails?.alldonations} />
            </TabPanel>
          </Paper>
        </Grid>
        <Grid item xl={4} lg={4}>
          <Grid item xs={12} lg={9}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Organization</InputLabel>
              <Select
                value={selectedOrganization}
                label="Select Organization"
                onChange={(e) => {
                  setSelectedOrganization(e.target.value);
                  setSelectedCampaign("");
                  setReadyToGetCampaign(readyToGetReports ? false : true);
                }}
              >
                {organizations
                  ?.filter((o) => o.managers && o.managers.length)
                  .map((o) => (
                    <MenuItem value={o.id} key={o.id}>
                      {o.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={9} style={{ marginTop: 15 }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Campaign</InputLabel>
              <Select
                value={selectedCampaign}
                label="Select Campaign"
                onChange={(e) => {
                  setSelectedCampaign(e.target.value);
                  let selectedCam = data.find((x) => x.id === e.target.value);
                  setSelectedCampaignOrganization(selectedCam?.organization_id);
                  setReadyToGetReports(true);
                }}
              >
                {data &&
                  data
                    .filter((o) => o.status && o.status === "published")
                    .map((o) => (
                      <MenuItem value={o.id} key={o.id}>
                        {o.title}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>

          <Paper
            variant="outlined"
            style={{
              marginTop: 15,
              paddingLeft: 30,
              paddingRight: 40,
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography variant="body2">Total Raised</Typography>
                <Typography
                  variant="h5"
                  style={{ paddingTop: 5, paddingBottom: 10 }}
                >
                  $ {campaignDetails?.achieved_goal_amount || 0}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Our Goal: $ {campaignDetails?.total_goal_amount || 0}
                </Typography>
              </div>

              <CircularProgress
                color="secondary"
                thickness={10}
                variant="determinate"
                value={
                  (parseInt(campaignDetails?.achieved_goal_amount) /
                    parseInt(campaignDetails?.total_goal_amount)) *
                  100
                }
                style={{ width: 70, height: 40 }}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Report;
