import React from "react";
import { Grid, Typography } from "@material-ui/core";

const LabelValue = ({ label, value }) => {
  return (
    <Grid container style={{ marginBottom: 2 }}>
      <Grid item xs={2}>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body2" style={{ marginLeft: 50 }}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LabelValue;
