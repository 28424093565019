import React, { useEffect, useState } from "react";
import { SvgIcon } from "@material-ui/core";
import {
  EditOutlined as EditIcon,
  RemoveRedEyeOutlined as ViewIcon,
  Delete, DeleteOutline as DeleteIcon
} from "@material-ui/icons";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import ActionBar from "components/ActionBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaigns, fetchCampaignsOfOrganizations , removeCampaign } from "store/campaign";
import Loading from "components/Loading";

import { Role } from "utils/roles";
import MUIDataTable from "mui-datatables";
import {useConfirm} from "material-ui-confirm";


const CampaignListing = ({
  orgId,
  withActionbar = false,
  status = undefined,
  style,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();

  const location = useLocation();
  const { data, inProgress } = useSelector((state) => state.campaign);
  const { user } = useSelector((state) => state.auth);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => setCampaigns(data), [data]);

  useEffect(() => {
    if (orgId) {
      dispatch(fetchCampaignsOfOrganizations(orgId));
    } else {
      dispatch(fetchCampaigns());
    }
  }, [dispatch, orgId]);

  const tableHead = [
    "Title",
    "Address",
    "Created Date",
    { label: "Goal Progress", enableSort: true, order: "asc" },
    "Status",
    "Start Date",
    "End Date",
    "Actions",
  ];

  const formatTableData = (campaigns) =>
    campaigns
      .filter((campaign) => {
        if (status) {
          return campaign.status === status;
        }
        return true;
      })
      .map((campaign) => [
        campaign.title,
        campaign.address,
        moment(campaign.createdAt).format("DD/MM/YYYY"),
        `${campaign.achieved_goal_amount}/${campaign.total_goal_amount}`,
        campaign.status,
        moment(campaign.start_date).format("DD/MM/YYYY"),
        moment(campaign.end_date).format("DD/MM/YYYY"),
        <>
          <SvgIcon
            fontSize="small"
            color="action"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(
                user?.role === Role.Admin
                  ? `${location.pathname}/campaigns/edit/${campaign.id}`
                  : `/admin/campaigns/edit/${campaign.id}`
              )
            }
          >
            <EditIcon />
          </SvgIcon>
          <SvgIcon
              fontSize="default"
              color="action"
              style={{
                cursor: "pointer",
                backgroundColor: "secondory",
              }}
              onClick={async () => {
                try {
                  await confirm({
                    title: "Delete Campaign",
                    description:
                        "Are you sure you want to delete this Campaigin?",
                  });
                  await dispatch(removeCampaign(campaign.id));
                  await dispatch(fetchCampaigns());
                } catch (err) {}
              }}
          >
            <DeleteIcon />
          </SvgIcon>
          <SvgIcon
            fontSize="small"
            color="action"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(
                user?.role === Role.Admin
                  ? `${location.pathname}/campaigns/${campaign.id}`
                  : `/admin/campaigns/${campaign.id}`
              )
            }
          >
            <ViewIcon />
          </SvgIcon>
        </>,
      ]);

  const options = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    elevation: 0,
  };
  return (
    <div style={style}>
      {withActionbar ? (
        <div style={{ paddingRight: 25, paddingBottom: 15 }}>
          <ActionBar
            actionText="New Campaign"
            onAction={() =>
              history.push(
                user?.role === Role.Admin
                  ? `${location.pathname}/campaign/new`
                  : `/admin/campaigns/new`
              )
            }
          />
        </div>
      ) : null}
      {inProgress && (
        <div style={{ margin: 20 }}>
          <Loading />
        </div>
      )}
      {!inProgress && (
        <MUIDataTable
          data={campaigns && campaigns.length ? formatTableData(campaigns) : []}
          columns={tableHead}
          options={options}
        />
      )}
    </div>
  );
};

export default CampaignListing;
