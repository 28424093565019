import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../../../index.css";
import { Grid, TextField, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const ProfileDetails = ({ orgId, organization, onChange }) => {
  const [values, setValues] = useState({
    name: "",
    address: "",
    email: "",
    phone_number: "",
    website: "",
    admin_name: "",
    admin_email: "",
    admin_password: "",
    admin_phone_number: "",
  });
  const [address, setAddress] = useState("");
  const [phone_number, setPhoneNumber] = useState("");

  const handleAddressChange = (address) => {
    setAddress(address.label);
    const newValues = {
      ...values,
      address: address.label,
    };
    setValues(newValues);
    onChange(newValues);
  };
  useEffect(() => {
    if (organization) {
      setValues({
        name: organization.name,
        address: organization.address,
        email: organization.email,
        phone_number: organization.phone_number,
        website: organization.website,
      });
      setAddress(organization.address);
    }
  }, [organization]);

  const handleChange = (event) => {
    const newValues = {
      ...values,
      [event.target.name]: event.target.value,
    };
    setValues(newValues);
    onChange(newValues);
  };
  const handlePhoneNumberChange = (number) => {
    const newValues = {
      ...values,
      phone_number: number,
    };
    setValues(newValues);
    onChange(newValues);
  };
  const handleAdminPhoneNumberChange = (number) => {
    const newValues = {
      ...values,
      admin_phone_number: number,
    };
    setValues(newValues);
    onChange(newValues);
  };

  return (
    <form autoComplete="off" noValidate>
      <div>
        <Typography variant="button" color="secondary">
          Organization Profile
        </Typography>

        <Grid container spacing={1} style={{ marginTop: 10, marginBottom: 15 }}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Organization Name"
              name="name"
              onChange={handleChange}
              required
              value={values.name}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="button" color="black">
              {address}
            </Typography>
            <GooglePlacesAutocomplete
              selectProps={{
                address,
                placeholder: "Search Address",
                onChange: handleAddressChange,
              }}
              apiKey="AIzaSyBLHaBkTB0c6tEPKBso5rIaWJM5OacfaBE"
            ></GooglePlacesAutocomplete>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Organization Email"
              name="email"
              onChange={handleChange}
              required
              value={values.email}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PhoneInput
              defaultCountry="US"
              placeholder="Enter phone number"
              value={values.phone_number}
              onChange={handlePhoneNumberChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Organization website"
              name="website"
              onChange={handleChange}
              required
              value={values.website}
              variant="outlined"
            />
          </Grid>
        </Grid>

        {!orgId ? (
          <>
            <Divider style={{ marginBottom: 15 }}></Divider>
            <Typography variant="button" color="secondary">
              Admin Profile
            </Typography>
            <Grid container spacing={1} style={{ marginTop: 10 }}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Admin Name"
                  name="admin_name"
                  onChange={handleChange}
                  required
                  value={values.admin_name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Admin Email"
                  name="admin_email"
                  onChange={handleChange}
                  required
                  value={values.admin_email}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  label="Admin Password"
                  name="admin_password"
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <PhoneInput
                  defaultCountry="US"
                  placeholder="Enter Admin phone number"
                  value={values.admin_phone_number}
                  onChange={handleAdminPhoneNumberChange}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </div>
    </form>
  );
};

ProfileDetails.propTypes = {
  onChange: PropTypes.func.isRequired,
  organization: PropTypes.object,
};

export default ProfileDetails;
