import React, {useEffect, useState} from "react";
import CampaignListing from "views/admin/campaign/CampaignListing";
import ActionBar from "components/ActionBar";
import {Avatar, Button, Grid, Paper, Tab, Tabs, TextField, Typography} from "@material-ui/core";
import TabPanel from "components/TabPanel";
import ViewHeader from "components/ViewHeader";
import { useHistory, useLocation } from "react-router-dom";
import { resetOrganization } from "store/organization";
import {useDispatch, useSelector} from "react-redux";
import RewardCard from "../../../components/RewardCard";
import {fetchRewards} from "../../../store/reward";
import {Sort} from "../../../components/SortMenu";
import Campaign from "../../../components/Campaign";
import ResourceNotFound from "../../../components/ResourceNotFound";

const RewardsListing = () => {

    const { rewards , inProgress } = useSelector((state) => state.reward);

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(0);


    useEffect(() => {
        dispatch(fetchRewards());
    }, [dispatch]);


    return (
        <>
            <ViewHeader
                title="Rewards"
            >
                <ActionBar
                    actionText="New Reward"
                    searchPlaceholder="Search Campaigns"
                    onAction={async () => {
                        await dispatch(resetOrganization());
                        history.push(`${location.pathname}/new`);
                    }}
                />
            </ViewHeader>
            <Paper style={{ border : 0 , marginTop: 25  }} variant="outlined">
                <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
                    <Tab label="Active" />
                    <Tab label="History" />
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                    <>
                        {rewards && rewards.length
                            ? rewards.filter((reward) => {
                                if (reward.isActive) {return true}
                                return false;
                            }).map((reward, index) => (
                                <RewardCard  reward={reward} />
                            ))
                            : !inProgress && <ResourceNotFound />}
                    </>

                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    {rewards && rewards.length
                        ? rewards.filter((reward) => {
                            if (reward.isActive) {return false}
                            return true;
                        }).map((reward, index) => (
                            <RewardCard  reward={reward} />
                        ))
                        : !inProgress && <ResourceNotFound />}
                </TabPanel>
            </Paper>
        </>
    );
};

export default RewardsListing;
