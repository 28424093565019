import { createSlice } from "@reduxjs/toolkit";
import {
  createCampaign,
  createFaq,
  createGoal,
  deleteFaq,
  getCampaign,
  getCampaigns,
  getCampaignsOfOrganizations,
  getFaqs,
  getGoals,
  publishCampaign,
  updateCampaign,
  updatedFaq,
  updateGoal,
  validateWebAddressCampaign,
  getCampaignByWebAddress,
    deleteCampaign,
} from "api-client/campaign";

const initialState = {
  error: null,
  inProgress: false,
  data: [],
  faqs: [],
  goals: [],
  currentCampaign: null,
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setProgress: (state, action) => {
      state.inProgress = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setFaqs: (state, action) => {
      state.faqs = action.payload;
    },
    setCurrentCampaign: (state, action) => {
      state.currentCampaign = action.payload;
    },
    setGoals: (state, action) => {
      state.goals = action.payload;
    },
  },
});

export const fetchGoals =
  (orgId, campaignId, silent = false) =>
  async (dispatch) => {
    try {
      if (!silent) {
        dispatch(campaignSlice.actions.setProgress(true));
      }
      const response = await getGoals(orgId, campaignId);
      dispatch(campaignSlice.actions.setGoals(response.data.teams));
    } catch (err) {
      dispatch(campaignSlice.actions.setError(err));
    } finally {
      dispatch(campaignSlice.actions.setProgress(false));
    }
  };

export const addGoal =
  (orgId, campaignId, teamId, goalAmount) => async (dispatch) => {
    try {
      await createGoal({
        campaign_id: campaignId,
        team_id: teamId,
        amount: goalAmount,
      });
      dispatch(fetchGoals(orgId, campaignId, true));
    } catch (err) {
      dispatch(campaignSlice.actions.setError(err));
    }
  };

export const updateCampaignGoal =
  (orgId, campaignId, teamId, goalAmount) => async (dispatch) => {
    try {
      await updateGoal({
        campaign_id: campaignId,
        team_id: teamId,
        amount: goalAmount,
      });
      dispatch(fetchGoals(orgId, campaignId, true));
    } catch (err) {
      dispatch(campaignSlice.actions.setError(err));
    }
  };

export const publish = (campaignId) => async (dispatch) => {
  try {
    await publishCampaign(campaignId);
  } catch (err) {
    dispatch(campaignSlice.actions.setError(null));
  }
};


export const addCampaign = (payload) => async (dispatch) => {
  try {
    const res = await createCampaign(payload);
    dispatch(campaignSlice.actions.setCurrentCampaign(res.data.campaign));
  } catch (err) {
    dispatch(campaignSlice.actions.setError(err));
  }
};
export const validateWebAddress = (payload) => async (dispatch) => {
  try {
    const res = await validateWebAddressCampaign(payload);
    return res.data.valid;
  } catch (err) {
    dispatch(campaignSlice.actions.setError(err));
  }
};

export const fetchCampaignsOfOrganizations = (orgId) => async (dispatch) => {
  try {
    dispatch(campaignSlice.actions.setProgress(true));
    dispatch(campaignSlice.actions.setData([]));
    const response = await getCampaignsOfOrganizations(orgId);
    dispatch(campaignSlice.actions.setData(response.data.campaigns));
  } catch (err) {
    dispatch(campaignSlice.actions.setError(err));
  } finally {
    dispatch(campaignSlice.actions.setProgress(false));
  }
};

export const fetchCampaigns = () => async (dispatch) => {
  try {
    dispatch(campaignSlice.actions.setProgress(true));
    dispatch(campaignSlice.actions.setData([]));
    const response = await getCampaigns();
    dispatch(campaignSlice.actions.setData(response.data.campaigns));
  } catch (err) {
    dispatch(campaignSlice.actions.setError(err));
  } finally {
    dispatch(campaignSlice.actions.setProgress(false));
  }
};

export const updateCampaignInfo = (campaignId, payload) => async (dispatch) => {
  try {
    await updateCampaign(campaignId, payload);
    dispatch(
      campaignSlice.actions.setCurrentCampaign({ id: campaignId, ...payload })
    );
  } catch (err) {
    dispatch(campaignSlice.actions.setError(err));
  }
};

export const fetchFaqs =
  (campaignId, silent = false) =>
  async (dispatch) => {
    try {
      if (!silent) {
        dispatch(campaignSlice.actions.setProgress(true));
        dispatch(campaignSlice.actions.setFaqs([]));
      }
      const response = await getFaqs(campaignId);
      dispatch(campaignSlice.actions.setFaqs(response.data.faqs));
    } catch (err) {
      dispatch(campaignSlice.actions.setError(err));
    } finally {
      dispatch(campaignSlice.actions.setProgress(false));
    }
  };

export const addFaq = (campaignId, faq) => async (dispatch) => {
  try {
    await createFaq(campaignId, faq);
    dispatch(fetchFaqs(campaignId, true));
  } catch (err) {
    dispatch(campaignSlice.actions.setError(err));
  }
};
export const updateFaq = (faqId, campaignId, faq) => async (dispatch) => {
  try {
    await updatedFaq(faqId, faq);
    dispatch(fetchFaqs(campaignId, true));
  } catch (err) {
    dispatch(campaignSlice.actions.setError(err));
  }
};

export const removeFaq = (faqId, campaignId) => async (dispatch) => {
  try {
    await deleteFaq(faqId);
    dispatch(fetchFaqs(campaignId, true));
  } catch (err) {
    dispatch(campaignSlice.actions.setError(err));
  }
};

export const removeCampaign = (campaignId) => async (dispatch) => {
  try {
    await deleteCampaign(campaignId);

  } catch (err) {}
};

export const fetchCampaignDetails =
  (campaignId, adultId) => async (dispatch) => {
    try {
      dispatch(campaignSlice.actions.setProgress(true));
      dispatch(campaignSlice.actions.setCurrentCampaign(null));
      const res = await getCampaign(campaignId, adultId);
      dispatch(campaignSlice.actions.setCurrentCampaign(res.data.campaigns));
    } catch (err) {
      dispatch(campaignSlice.actions.setError(err));
    } finally {
      dispatch(campaignSlice.actions.setProgress(false));
    }
  };

export const fetchCampaignDetailsByWebAddress =
  (campaignId, adultId) => async (dispatch) => {
    try {
      dispatch(campaignSlice.actions.setProgress(true));
      dispatch(campaignSlice.actions.setCurrentCampaign(null));
      const res = await getCampaignByWebAddress(campaignId, adultId);
      dispatch(campaignSlice.actions.setCurrentCampaign(res.data.campaigns));
    } catch (err) {
      dispatch(campaignSlice.actions.setError(err));
    } finally {
      dispatch(campaignSlice.actions.setProgress(false));
    }
  };
export default campaignSlice;
