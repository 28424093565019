import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import {colors, getRandomColor} from "utils/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { storageRef } from "index";
import { AttachMoney, Share } from "@material-ui/icons";
import { BASE_URL } from "api-client";
import { toast } from "react-toastify";
import {useMediaQuery} from "react-responsive";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    flex: 1,
    height: "100vh",
    width: "100%",
    alignContent: "center",
    marginTop: 30,
  },
  avatar: {
    backgroundColor: getRandomColor(),
    height: 78,
    width: 78,
  },
}));

const FundraiserCard = ({ fundraiserDetail }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { team: teamDetail } = useSelector((state) => state.team);
  const { org_name, campaignId, team_id } = useParams();
  const [imageTeam, setImageTeam] = useState(null);
  const [imageMinor, setMinorImage] = useState(null);
  const [imageCampaign, setImageCampaign] = useState(null);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

  useEffect(() => {
    downloadImages();
  }, [teamDetail]);
  const downloadImages = async () => {
    try {
      try {
        const minorImage = `minor_${fundraiserDetail?.id}`;
        const minorImageUrl = await storageRef
          .child(minorImage)
          .getDownloadURL();
        await setMinorImage(minorImageUrl);
      } catch (err) {}

      try {
        const campaignImage = `campaign_profile_${fundraiserDetail?.Campaign?.id}`;
        const campaignImageUrl = await storageRef
          .child(campaignImage)
          .getDownloadURL();
        await setImageCampaign(campaignImageUrl);
      } catch (err) {}
      try {
        const teamImage = `team_${fundraiserDetail?.Team?.id}`;
        const teamImageUrl = await storageRef.child(teamImage).getDownloadURL();
        setImageTeam(teamImageUrl);
      } catch (err) {}
    } catch (err) {}
  };

  return (
      <>
          {isDesktopOrLaptop ?  <Card className={clsx(classes.cardRoot)}>
              <CardContent>
                  <Box>
                      <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar className={classes.avatar} src={imageMinor} />
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: 20,
                              }}
                          >
                              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                  {fundraiserDetail?.name}
                              </Typography>
                              <Typography>Parent</Typography>
                          </div>
                      </div>
                      <Typography variant="h6" style={{ marginTop: 20 }}>
            <span style={{ fontWeight: "bold" }}>
              ${fundraiserDetail?.achieved_goal || 0} Raised
            </span>{" "}
                          of {fundraiserDetail?.goal}
                      </Typography>
                      <LinearProgress
                          style={{
                              height: 8,
                              borderRadius: 15,
                              marginTop: 10,
                              marginBottom: 25,
                          }}
                          variant="determinate"
                          value={
                              fundraiserDetail?.achieved_goal
                                  ? (parseInt(fundraiserDetail?.achieved_goal) /
                                      parseInt(fundraiserDetail?.goal)) *
                                  100
                                  : 0
                          }
                          color="secondary"
                      />
                      <div style={{ display: "flex" }}>
                          <Button
                              variant="outlined"
                              size="large"
                              fullWidth
                              style={{ fontWeight: "bold" }}
                              startIcon={<Share color="action" />}
                              onClick={() => {
                                  navigator.clipboard.writeText(
                                      ` ${BASE_URL}${location.pathname}`
                                  );
                                  toast("Link Copied to Share !", { type: "success" });
                              }}
                          >
                              Share
                          </Button>
                          <Button
                              variant="contained"
                              fullWidth
                              size="large"
                              startIcon={<AttachMoney color= {colors.white} />}
                              style={{ fontWeight: "bold", marginLeft: 10 ,
                                color : colors.white,
                                backgroundColor : colors.donationGreen
                          }}
                              onClick={() => {
                                  history.push(
                                      `${fundraiserDetail?.id}/campaign/${fundraiserDetail?.campaign_id}/team/${fundraiserDetail?.team_id}/donate`
                                  );
                              }}
                          >
                              Donate
                          </Button>
                      </div>

                      <div style={{ marginTop: 50, marginBottom: 20 }}>
                          <Divider />
                      </div>

                      <div style={{ width: "100%" }}>
                          <Typography variant="body2" style={{ fontWeight: "bold" }}>
                              Campaigns
                          </Typography>
                          <div
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 10,
                                  cursor: "pointer",
                              }}
                              onClick={() => history.push(`/org/${org_name}/c/${campaignId}`)}
                          >
                              <Avatar
                                  src={imageCampaign}
                                  style={{
                                      width: 80,
                                      height: 80,
                                      backgroundColor: getRandomColor(),
                                  }}
                              />
                              <div
                                  style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                      marginLeft: 20,
                                  }}
                              >
                                  <Typography variant="body2">
                                      {fundraiserDetail?.Campaign?.title}
                                  </Typography>
                                  <LinearProgress
                                      style={{
                                          width: 200,
                                          marginTop: 5,
                                          marginBottom: 5,
                                          height: 4,
                                          borderRadius: 7,
                                      }}
                                      value={
                                          (parseInt(
                                                  fundraiserDetail?.Campaign?.achieved_goal_amount
                                              ) /
                                              parseInt(fundraiserDetail?.Campaign?.total_goal_amount)) *
                                          100
                                      }
                                      color="secondary"
                                      variant="determinate"
                                  />
                                  <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>
                    ${fundraiserDetail?.Campaign?.achieved_goal_amount} raised
                  </span>{" "}
                                      of ${fundraiserDetail?.Campaign?.total_goal_amount}
                                  </Typography>
                              </div>
                          </div>
                      </div>

                      <div style={{ marginTop: 50, marginBottom: 20 }}>
                          <Divider />
                      </div>

                      <div style={{ marginTop: 20, width: "100%" }}>
                          <Typography variant="body2" style={{ fontWeight: "bold" }}>
                              My team
                          </Typography>
                          <div
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 10,
                                  cursor: "pointer",
                              }}
                              onClick={() =>
                                  history.push(`/org/${org_name}/c/${campaignId}/t/${team_id}`)
                              }
                          >
                              <Avatar
                                  src={imageTeam}
                                  style={{
                                      width: 80,
                                      height: 80,
                                      backgroundColor: getRandomColor(),
                                  }}
                              />
                              <div
                                  style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                      marginLeft: 20,
                                  }}
                              >
                                  <Typography variant="body2">
                                      {fundraiserDetail?.Team?.name}
                                  </Typography>
                                  <LinearProgress
                                      style={{
                                          width: 200,
                                          marginTop: 5,
                                          marginBottom: 5,
                                          height: 4,
                                          borderRadius: 7,
                                      }}
                                      value={
                                          (parseInt(teamDetail?.team_goal?.achieved_goal_amount) /
                                              parseInt(teamDetail?.team_goal?.goal)) *
                                          100
                                      }
                                      color="secondary"
                                      variant="determinate"
                                  />
                                  <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>
                    ${teamDetail?.team_goal?.achieved_goal_amount} raised
                  </span>{" "}
                                      of ${teamDetail?.team_goal?.goal}
                                  </Typography>
                              </div>
                          </div>
                      </div>
                  </Box>
              </CardContent>
          </Card> :
              <Box>
            <div style={{ marginTop: 35, display: "flex", alignItems: "center" ,  }}>
              <Avatar className={classes.avatar} src={imageMinor} />
              <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 20,
                  }}
              >
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {fundraiserDetail?.name}
                </Typography>
                <Typography>Parent</Typography>
              </div>
            </div>
            <Typography variant="h6" style={{ marginTop: 20 }}>
            <span style={{ fontWeight: "bold" }}>
              ${fundraiserDetail?.achieved_goal || 0} Raised
            </span>{" "}
              of {fundraiserDetail?.goal}
            </Typography>
            <LinearProgress
                style={{
                  height: 8,
                  borderRadius: 15,
                  marginTop: 10,
                  marginBottom: 25,
                }}
                variant="determinate"
                value={
                  fundraiserDetail?.achieved_goal
                      ? (parseInt(fundraiserDetail?.achieved_goal) /
                          parseInt(fundraiserDetail?.goal)) *
                      100
                      : 0
                }
                color="secondary"
            />
            <div style={{ display: "flex" }}>
              <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  style={{ fontWeight: "bold" }}
                  startIcon={<Share color="action" />}
                  onClick={() => {
                    navigator.clipboard.writeText(
                        ` ${BASE_URL}${location.pathname}`
                    );
                    toast("Link Copied to Share !", { type: "success" });
                  }}
              >
                Share
              </Button>
              <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  startIcon={<AttachMoney color= {colors.white} />}
                  style={{ fontWeight: "bold", marginLeft: 10 ,
                    color : colors.white,
                    backgroundColor : colors.donationGreen
                  }}
                  onClick={() => {
                    history.push(
                        `${fundraiserDetail?.id}/campaign/${fundraiserDetail?.campaign_id}/team/${fundraiserDetail?.team_id}/donate`
                    );
                  }}
              >
                Donate
              </Button>
            </div>

            <div style={{ marginTop: 50, marginBottom: 20 }}>
              <Divider />
            </div>

            <div style={{ width: "100%" }}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Campaigns
              </Typography>
              <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/org/${org_name}/c/${campaignId}`)}
              >
                <Avatar
                    src={imageCampaign}
                    style={{
                      width: 80,
                      height: 80,
                      backgroundColor: getRandomColor(),
                    }}
                />
                <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginLeft: 20,
                    }}
                >
                  <Typography variant="body2">
                    {fundraiserDetail?.Campaign?.title}
                  </Typography>
                  <LinearProgress
                      style={{
                        width: 200,
                        marginTop: 5,
                        marginBottom: 5,
                        height: 4,
                        borderRadius: 7,
                      }}
                      value={
                        (parseInt(
                                fundraiserDetail?.Campaign?.achieved_goal_amount
                            ) /
                            parseInt(fundraiserDetail?.Campaign?.total_goal_amount)) *
                        100
                      }
                      color="secondary"
                      variant="determinate"
                  />
                  <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>
                    ${fundraiserDetail?.Campaign?.achieved_goal_amount} raised
                  </span>{" "}
                    of ${fundraiserDetail?.Campaign?.total_goal_amount}
                  </Typography>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 50, marginBottom: 20 }}>
              <Divider />
            </div>

            <div style={{ marginTop: 20, width: "100%" }}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                My team
              </Typography>
              <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                      history.push(`/org/${org_name}/c/${campaignId}/t/${team_id}`)
                  }
              >
                <Avatar
                    src={imageTeam}
                    style={{
                      width: 80,
                      height: 80,
                      backgroundColor: getRandomColor(),
                    }}
                />
                <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginLeft: 20,
                    }}
                >
                  <Typography variant="body2">
                    {fundraiserDetail?.Team?.name}
                  </Typography>
                  <LinearProgress
                      style={{
                        width: 200,
                        marginTop: 5,
                        marginBottom: 5,
                        height: 4,
                        borderRadius: 7,
                      }}
                      value={
                        (parseInt(teamDetail?.team_goal?.achieved_goal_amount) /
                            parseInt(teamDetail?.team_goal?.goal)) *
                        100
                      }
                      color="secondary"
                      variant="determinate"
                  />
                  <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>
                    ${teamDetail?.team_goal?.achieved_goal_amount} raised
                  </span>{" "}
                    of ${teamDetail?.team_goal?.goal}
                  </Typography>
                </div>
              </div>
            </div>
          </Box>
          }
      </>

  );
};

export default FundraiserCard;
