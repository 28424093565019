import { createSlice } from "@reduxjs/toolkit";
import {
  createManager,
  deleteManager,
  getManagers,
  updateManager,
} from "api-client/manager";

export const initialState = {
  error: null,
  inProgress: false,
  data: [],
};

const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setProgress: (state, action) => {
      state.inProgress = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const addManager = (orgId, manager) => async (dispatch) => {
  try {
    await createManager(orgId, manager);
    dispatch(fetchManagers(orgId, true));
  } catch (err) {
    dispatch(managerSlice.actions.setError(err));
  }
};

export const updateMgr = (orgId, manager) => async (dispatch) => {
  try {
    await updateManager(manager.id, manager);
    dispatch(fetchManagers(orgId, true));
  } catch (err) {
    dispatch(managerSlice.actions.setError(err));
  }
};

export const fetchManagers =
  (orgId, silent = false) =>
  async (dispatch) => {
    try {
      if (!silent) dispatch(managerSlice.actions.setProgress(true));
      const response = await getManagers(orgId);
      dispatch(managerSlice.actions.setData(response.data.managers));
    } catch (err) {
      dispatch(managerSlice.actions.setError(err));
    } finally {
      dispatch(managerSlice.actions.setProgress(false));
    }
  };

export const removeManager = (managerId) => async (dispatch, getState) => {
  try {
    await deleteManager(managerId);
    const filtered = getState().manager.data.filter(
      (manager) => manager.id !== managerId
    );
    dispatch(managerSlice.actions.setData(filtered));
  } catch (err) {
    dispatch(managerSlice.actions.setError(err));
  }
};

export default managerSlice;
