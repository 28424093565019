import { createSlice } from "@reduxjs/toolkit";
import {
  createAdult,
  getAdultDetail,
  joinCampaign,
  createMinor,
  getFundrasierDetail,
    updateName
} from "api-client/adult";

export const initialState = {
  error: "",
  inProgress: false,
  adult: null,
  fundraiser: null,
};

const adultSlice = createSlice({
  name: "adult",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setProgress: (state, action) => {
      state.inProgress = action.payload;
    },
    setAdult: (state, action) => {
      state.adult = action.payload;
    },
    setFundraiser: (state, action) => {
      state.fundraiser = action.payload;
    },
  },
});

export const fetchAdultDetail = (adultId) => async (dispatch) => {
  try {
    dispatch(adultSlice.actions.setProgress(true));
    const response = await getAdultDetail(adultId);
    dispatch(adultSlice.actions.setAdult(response.data.adult));
  } catch (err) {
    dispatch(adultSlice.actions.setError(err));
  } finally {
    dispatch(adultSlice.actions.setProgress(false));
  }
};

export const joinCampaignAsFundraiser =
  (adultId, campaignId) => async (dispatch) => {
    try {
      const response = await joinCampaign(campaignId, adultId);
      return response;
    } catch (err) {
      dispatch(adultSlice.actions.setError(err));
    }
  };

export const addAdult = (adult) => async (dispatch) => {
  try {
    const response = await createAdult(adult);
    if (response.data.success === true) {
      return {
        created: true,
        adult: response.data.adult,
        error: null,
      };
    } else
      return {
        created: false,
        error: response.data.message,
      };
  } catch (err) {
    return {
      created: false,
      error: "Something went wrong.",
    };
  }
};
export const updateParentName = (payload) => async (dispatch) => {
  try {
    const response = await updateName(payload);
  } catch (err) {
    return {
      created: false,
      error: "Something went wrong.",
    };
  }
};

export const addMinor = (payload) => async (dispatch) => {
  try {
    const response = await createMinor(payload);
    dispatch(adultSlice.actions.setFundraiser(response.data.minor));
    return response.data.minor;
  } catch (err) {
    return {
      created: false,
      error: "Something went wrong.",
    };
  }
};

export const fetchMinor = (minorId) => async (dispatch) => {
  try {
    dispatch(adultSlice.actions.setFundraiser(null));
    dispatch(adultSlice.actions.setProgress(true));
    const response = await getFundrasierDetail(minorId);
    dispatch(adultSlice.actions.setFundraiser(response.data.minor));
    return response;
  } catch (err) {
    dispatch(adultSlice.actions.setError(err));
  } finally {
    dispatch(adultSlice.actions.setProgress(false));
  }
};
export default adultSlice;
