import React, {useEffect, useState} from "react";
import {Avatar, Container, Grid, SvgIcon, Typography,} from "@material-ui/core";
import {
    AttachMoneyOutlined,
    DeleteOutline as DeleteIcon,
    DeleteOutline,
    EditOutlined as EditIcon,
    EditOutlined,
    KeyboardArrowDownOutlined,
    KeyboardArrowUpOutlined, Money, MoneyOff, Payment, Payments, VideoCameraBackOutlined
} from "@material-ui/icons";

import useCollapse from "react-collapsed";
import RewardTableRow from "./RewardTableRow";
import {Role} from "../utils/roles";
import {fetchCampaigns, removeCampaign} from "../store/campaign";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {useConfirm} from "material-ui-confirm";
import {fetchRewards, removeReward} from "../store/reward";
import ResourceNotFound from "./ResourceNotFound";



const RewardCard = ({reward}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const confirm = useConfirm();
    const location = useLocation();
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    const [notificationCount , setNotificationCount] = useState(0);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        calculateNotificationCount();
    }, [reward]);

    const calculateNotificationCount = async () => {
        if (reward) {
            let count = 0 ;
            if(reward?.type == "team"){
                if(reward?.reward_aim === "fundraising"){
                    for( const team of reward?.teams){
                        let teamAchivedGoal = team?.achivedGoal ? Number(team?.achivedGoal) : 0
                        let goal = Number(reward?.goal)
                        if(teamAchivedGoal >= goal){
                            for(const member of team?.members){
                                if(!member?.isRewarded){
                                    count ++
                                }
                            }
                        }
                    }
                }

            }else if(reward?.type == "person") {
                if(reward?.reward_aim === "fundraising"){
                    for( const team of reward?.teams){
                        for(const member of team?.members){
                            let memberAchivedGoal = member?.rewardAchievedAmount ? Number(member?.rewardAchievedAmount) : 0
                            let goal = Number(reward?.goal)
                            if(memberAchivedGoal >= goal){
                                if(!member?.isRewarded){
                                    count ++
                                }
                            }
                        }
                    }
                }

            }else{
                if(reward?.Campaign?.rewardAchievedAmount >= reward?.goal){
                    for( const team of reward?.teams){
                        for(const member of team?.members){
                            if(!member?.isRewarded){
                                    count ++
                            }
                        }
                    }
                }
            }
            setNotificationCount(count);
        }
    };

    return (
        <div className="collapsible" style={{borderRadius : 15 , border : 1 , borderColor : "#F5F6F8" , borderStyle : "solid" , marginTop : 10  , paddingBottom : 5}}>
            <div style={{ height : 50 , padding : 15 , }} className="header" {...getToggleProps()}>
                <div style={{display: "flex" , flexDirection : "row" }}>
                    {isExpanded ? <KeyboardArrowUpOutlined/> : <KeyboardArrowDownOutlined/>}
                    {reward?.reward_aim === "fundraising" ? <AttachMoneyOutlined/> : <VideoCameraBackOutlined/>}
                    <Typography style={{fontWeight : "bold" , marginLeft : 10}}>
                        {reward?.title.charAt(0).toUpperCase() + reward?.title.slice(1)}
                    </Typography>
                    <div style={{ marginLeft: "auto",float : "right" , display: "flex" , flexDirection : "row" }}>
                    {notificationCount > 0 ?
                        <div style={{marginRight: 0  ,height : 25 , width : 25 , background : "#F95070", borderRadius : 12 }}>
                        <div style={{textAlign : "center" , color : "#ffffff" , marginTop : 2}}>
                            {notificationCount}
                        </div>
                    </div> : <></>
                    }
                        <div style={{marginLeft : 10 }}>
                            <SvgIcon
                                fontSize="small"
                                color="action"
                                style={{ cursor: "pointer" , marginLeft : 10 }}
                                onClick={() =>
                                    history.push(`${location.pathname}/edit/${reward.id}`)
                                }
                            >
                                <EditOutlined />
                            </SvgIcon>
                            <SvgIcon
                                fontSize="default"
                                color="action"
                                style={{marginRight : 10 , marginLeft : 10 ,
                                    cursor: "pointer"
                                }}
                                onClick={async () => {
                                    try {
                                        await confirm({
                                            title: "Delete Reward",
                                            description:
                                                "Are you sure you want to delete this Reward?",
                                        });
                                        await dispatch(removeReward(reward.id));
                                        await dispatch(fetchRewards());
                                    } catch (err) {}
                                }}
                            >
                                <DeleteIcon style={{color : "#E8769F"}}  />
                            </SvgIcon>
                        </div>
                    </div>
                </div>

            </div>
            <div   {...getCollapseProps()}>

                <div style={{paddingRight : 5 , paddingLeft : 5}}>
                    {reward?.type === "campaign" ?
                        <>
                            <Grid container style={{ background: "#F5F6F8"  , height : 50   }}  spacing={1}>
                            <Grid item xl={6} lg={4} style={{alignSelf : "center" , paddingLeft :  60 }} >
                                <Typography variant="body1" style={{fontWeight : "bold" }} >
                                    Campaign
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >
                                <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                    Teams
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                                <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                    Raised
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={2} style={{alignSelf : "center" }} >
                                <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                    Goal progress
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >
                                <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                    Completed
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                                <Typography variant="body1" style={{fontWeight : "bold" }} >
                                    Rewarded
                                </Typography>
                            </Grid>
                        </Grid>
                            <Grid container style={{  height : 50   }}  spacing={1}>
                                <Grid item xl={6} lg={4} style={{alignSelf : "center" , paddingLeft :  60 }} >
                                    <div style={{display: "flex" , flexDirection : "row" }}>

                                        {/*<Avatar className={classes.avatar} src={imageTeam} />*/}
                                        <Typography style={{ alignSelf : "center", marginLeft : 10}}>
                                            {reward?.Campaign?.title}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >
                                    <Typography variant="body1" >
                                        {reward?.teams.length}
                                    </Typography>
                                </Grid>
                                <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                                    <Typography variant="body1" >
                                        ${reward?.Campaign?.rewardAchievedAmount ? reward?.Campaign?.rewardAchievedAmount : 0 }
                                    </Typography>

                                </Grid>
                                <Grid item xl={6} lg={2} style={{alignSelf : "center" }} >
                                    <Typography variant="body1" >

                                        {reward?.Campaign?.rewardAchievedAmount ? reward?.Campaign?.rewardAchievedAmount : 0  } / {reward.goal}
                                    </Typography>
                                </Grid>
                                <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >

                                </Grid>
                                <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >

                                </Grid>
                            </Grid>
                            <Grid container style={{ background: "#F5F6F8"  , height : 50   }}  spacing={1}>
                            <Grid item xl={6} lg={4} style={{alignSelf : "center" , paddingLeft :  60 }} >
                                <Typography variant="body1" style={{fontWeight : "bold" }} >
                                    Teams
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >
                                <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                    Members
                                </Typography>
                            </Grid>
                        </Grid>
                        </>
                        :
                        reward?.reward_aim === "fundraising" ?
                        <>
                            <Grid container style={{ background: "#F5F6F8"  , height : 50   }}  spacing={1}>
                                <Grid item xl={6} lg={4} style={{alignSelf : "center" , paddingLeft :  60 }} >
                                    <Typography variant="body1" style={{fontWeight : "bold" }} >
                                        Teams
                                    </Typography>
                                </Grid>
                                <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >
                                    <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                        Members
                                    </Typography>
                                </Grid>
                                <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                                    <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                        Raised
                                    </Typography>
                                </Grid>
                                <Grid item xl={6} lg={2} style={{alignSelf : "center" }} >
                                    <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                        Goal progress
                                    </Typography>
                                </Grid>
                                <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >
                                    <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                        Completed
                                    </Typography>
                                </Grid>
                                <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                                    <Typography variant="body1" style={{fontWeight : "bold" }} >
                                        Rewarded
                                    </Typography>
                                </Grid>
                            </Grid>
                        </> :
                            <>
                                <Grid container style={{ background: "#F5F6F8"  , height : 50   }}  spacing={1}>
                                    <Grid item xl={6} lg={4} style={{alignSelf : "center" , paddingLeft :  60 }} >
                                        <Typography variant="body1" style={{fontWeight : "bold" }} >
                                            Teams
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >
                                        <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                            Members
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                                        <Typography variant="body1" style={{fontWeight : "bold"  }} >

                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={2} style={{alignSelf : "center" }} >
                                        <Typography variant="body1" style={{fontWeight : "bold"  }} >
                                            Video Uploaded
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={1} style={{alignSelf : "center" }} >
                                        <Typography variant="body1" style={{fontWeight : "bold"  }} >

                                        </Typography>
                                    </Grid>
                                    <Grid item xl={6} lg={2} style={{alignSelf : "center", paddingLeft :  40 }} >
                                        <Typography variant="body1" style={{fontWeight : "bold" }} >
                                            Rewarded
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>

                    }

                    {reward?.teams && reward?.teams.length
                        ? reward?.teams.map((team, index) => (
                            <>
                                <div style={{background : "#F5F6F8" , height : 1 , marginBottom : 5}}></div>
                                <RewardTableRow team={team} reward={reward} notificationCount={notificationCount}  setNotificationCount={setNotificationCount}/>
                            </>
                        ))
                        : <></>}

                </div>


            </div>
        </div>
    );
};

export default RewardCard;
