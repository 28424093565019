import React, { useEffect, useState } from "react";
import { SvgIcon } from "@material-ui/core";
import {
  DeleteOutline,
  EditOutlined,
  VerifiedUser as DoneIcon,
} from "@material-ui/icons";
import CustomTable from "components/CustomTable";
import ActionBar from "components/ActionBar";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { fetchTeams, removeTeam } from "store/team";
import Loading from "components/Loading";
import { colors } from "utils/colors";
import CreateTeamForm from "views/admin/organization/CreateTeamForm";

const TeamListing = ({ orgId }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [openDialog, setOpenDialog] = useState(false);
  const { data, inProgress, error } = useSelector((state) => state.team);
  const { data: managers } = useSelector((state) => state.manager);
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => orgId && dispatch(fetchTeams(orgId)), [orgId]);

  const tableHead = [
    "Team Name",
    "Manager Name",
    "Manager Email",
    "Registration Status",
    "Actions",
  ];

  const formatTableData = (teams) =>
    teams.map((team) => [
      team.name,
      team.Manager.name,
      team.Manager.email,
      team.is_verified === "true" && (
        <SvgIcon fontSize="small" color="action">
          <DoneIcon style={{ fill: colors.teal }} />
        </SvgIcon>
      ),
      <>
        <SvgIcon
          fontSize="small"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedTeam(team);
            setOpenDialog(true);
          }}
        >
          <EditOutlined color="action" />
        </SvgIcon>
        <SvgIcon
          fontSize="small"
          style={{ cursor: "pointer" }}
          onClick={async () => {
            try {
              await confirm({
                title: "Delete Team",
                description: "Are you sure you want to delete this Team ?",
              });
              dispatch(removeTeam(team.id));
            } catch {}
          }}
        >
          <DeleteOutline color="action" />
        </SvgIcon>
      </>,
    ]);

  const onAddTeam = () => {
    if (managers && managers.length) {
      setOpenDialog(true);
    } else {
      confirm({
        title: "Sorry",
        description:
          "Cannot add a new team without a manager, please add at-least one manager to this organization",
      });
    }
  };

  return (
    <>
      <div style={{ paddingRight: 25, paddingBottom: 15 }}>
        <ActionBar actionText="Add Team" onAction={onAddTeam} />
      </div>
      {inProgress && (
        <div style={{ margin: 20 }}>
          <Loading />
        </div>
      )}
      {!inProgress && (
        <CustomTable
          tableHead={tableHead}
          tableData={data && data.length ? formatTableData(data) : []}
          noBorder
        />
      )}
      <CreateTeamForm
        open={openDialog}
        onClose={() => {
          setSelectedTeam(null);
          setOpenDialog(false);
        }}
        orgId={orgId}
        team={selectedTeam}
      />
    </>
  );
};

export default TeamListing;
