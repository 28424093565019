import { createMuiTheme } from "@material-ui/core";
import { colors } from "utils/colors";

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: colors.yellow,
    },
    secondary: {
      main: colors.green,
    },
    donation: {
      main : colors.donationGreen
    },
    background: {
      paper: "#31324E",
      default: "#000229",
    },
  },
  typography: {
    fontSize: 13,
    fontFamily: "Raleway",
  },
  shape: {
    borderRadius: "0.5rem",
  },
});

export const lightTheme = createMuiTheme({
  palette: {
    type: "light",
    background: {
      default: "#fff",
    },
    primary: {
      main: colors.yellow,
    },
    secondary: {
      main: colors.green,
    },
    donation: {
      main : colors.donationGreen
    },
  },
  typography: {
    fontSize: 13,
    fontFamily: "Raleway",
  },
  shape: {
    borderRadius: "0.5rem",
  },
});
