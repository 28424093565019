import React, { useEffect, useState } from "react";
import ViewHeader from "components/ViewHeader";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField, Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeams } from "store/team";
import Loading from "components/Loading";
import { addMinor, fetchAdultDetail, fetchMinor } from "store/adult";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ProgressButton from "components/ProgressButton";
import { toast } from "react-toastify";
import AvatarPicker from "components/AvatarPicker";
import { getConsoleHome } from "routes";

const NewMinorForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { data } = useSelector((state) => state.campaign);
  const { data: teams } = useSelector((state) => state.team);
  const { fundraiser: minor } = useSelector((state) => state.adult);
  const { id, minorId } = useParams();
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedCampaignOrganization, setSelectedCampaignOrganization] =
    useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const { adult } = useSelector((state) => state.adult);
  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useState("");
  const [goal, setGoal] = useState(100);
  const [storyTitle, setStoryTitle] = useState("Thank you for your support to our school");
  const [introductionMessage, setIntroductionMessage] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    dispatch(fetchAdultDetail(id || user?.systemId));
    if (minorId) {
      dispatch(fetchMinor(minorId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (minor && minor.id === parseInt(minorId)) {
      setName(minor.name);
      setGoal(minor.goal);
      setStoryTitle(minor.story_title);
      setIntroductionMessage(minor.introduction_message);
      setSelectedCampaign(minor.campaign_id);
      setSelectedTeam(minor.team_id);
      const campaign = adult.campaigns.find((c) => c.id === minor.campaign_id);
      setSelectedCampaignOrganization(campaign?.organization_id);
    }
  }, [minor]);

  useEffect(() => {
    if (selectedCampaignOrganization) {
      dispatch(fetchTeams(selectedCampaignOrganization, true));
    }
  }, [selectedCampaignOrganization, dispatch]);
  if (!data || !adult) {
    return <Loading />;
  }
  const handleSave = async () => {
    setSaving(true);

    if (minorId && minorId !== "new") {
      console.log("edit");
      toast("Kid Updated successfully", { type: "success" });
      history.push(getConsoleHome(user));
    } else {
      const payload = {
        campaign_id: selectedCampaign,
        team_id: selectedTeam,
        name: name,
        goal: goal,
        story_title: storyTitle,
        introduction_message: introductionMessage,
        adult_id: adult?.id,
      };
      await dispatch(addMinor(payload));
      toast("Kid Added successfully", { type: "success" });
      history.goBack();
    }

    setSaving(false);
    /*const newPath = location.pathname.replace("new", response.id);
    history.push(newPath);*/
  };

  return (
    <>
      <ViewHeader
        title={minorId && minorId !== "new" ? "Edit Kid" : "Register Kid"}
        subtitle={`for ${adult?.name}, ${adult?.email}`}
      />
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={3} lg={3} xl={3} md={12} sm={12}>
          {minorId && minorId !== "new" && (
              <>
                <Typography variant="subtitle1" color="textPrimary" style={{marginBottom : 10}}>  Kid's Profile Pic </Typography>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AvatarPicker variant="outlined" uniqueId={`minor_${minorId}`} height="100%"  />
                </Grid>
              </>

          )}
        </Grid>
        <Grid item xs={9} lg={9} xl={9} md={12} sm={12}>
          {minorId && minorId !== "new" && (
              <>
                <Typography variant="subtitle1" color="textPrimary" style={{marginBottom : 10}}>  Kid's Video </Typography>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AvatarPicker variant="outlined" uniqueId={`minor_video_${minorId}`} isVideo={true} />
                </Grid>
              </>

          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6} lg={6} xl={6} md={12} sm={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Select Campaign</InputLabel>
            <Select
              value={selectedCampaign}
              label="Select Campaign"
              onChange={(e) => {
                setSelectedCampaign(e.target.value);
                const campaign = adult.campaigns.find(
                  (c) => c?.id === e.target.value
                );
                setSelectedCampaignOrganization(campaign?.organization_id);
              }}
            >
              {adult &&
                adult.campaigns &&
                adult.campaigns.length &&
                adult.campaigns
                  .filter((o) => o?.status && o?.status === "published")
                  .map((o) => (
                    <MenuItem value={o?.id} key={o?.id}>
                      {o?.title}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={6} xl={6} md={12} sm={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Select Team</InputLabel>
            <Select
              value={selectedTeam || ""}
              label="Select Team"
              onChange={(e) => {
                setSelectedTeam(e.target.value);
              }}
            >
              {teams &&
                teams.map((o) => (
                  <MenuItem value={o.id} key={o.id}>
                    {o.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            <Grid item xl={6} lg={6}>
              <TextField
                label="Kid Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xl={6} lg={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Fundraising Goal"
                type="number"
                value={goal}
                onChange={(e) => setGoal(e.target.value)}
              />
            </Grid>
            {/* <Grid item xl={6} lg={6}>
              <TextField
                label="Story Title "
                variant="outlined"
                fullWidth
                value={storyTitle}
                onChange={(e) => setStoryTitle(e.target.value)}
              />
            </Grid> */}
            <Grid item xl={6} lg={6}>
              <TextField
                label="Share your inspiration"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={introductionMessage}
                onChange={(e) => setIntroductionMessage(e.target.value)}
              />
            </Grid>
            <Grid item xl={6} lg={6}>
              <ProgressButton
                showProgress={saving}
                variant="contained"
                color="secondary"
                disabled={
                  saving ||
                  !name ||
                  !goal ||
                  !selectedCampaign ||
                  !selectedTeam ||
                  // !introductionMessage ||
                  !storyTitle
                }
                label={
                  minorId && minorId !== "new"
                    ? "Update Changes"
                    : "Save Changes"
                }
                onClick={handleSave}
              />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
};

export default NewMinorForm;
