import React from "react";
import CustomTable from "components/CustomTable";
import { Edit } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { BASE_URL } from "api-client";
import { Link } from "@material-ui/core";

const MinorListing = ({ style, minors }) => {
  const history = useHistory();
  const location = useLocation();
  const tableHead = ["Name", "Team", "Campaign", "Goal", "Actions"];
  const formatTableData = (data) => {
    if (data && data.length) {
      return data.map((minor) => [
        <Link underline="none" href={`${BASE_URL}/${minor?.publicUrl}`}>
          {minor?.name}
        </Link>,
        minor?.Team?.name || "N/A",
        minor?.Campaign?.title,
        minor?.goal,
        <Edit
          color="action"
          style={{ cursor: "pointer" }}
          onClick={() => history.push(`${location.pathname}/minor/${minor.id}`)}
        />,
      ]);
    }
    return [];
  };
  return (
    <div style={style}>
      <CustomTable
        tableHead={tableHead}
        noBorder
        tableData={formatTableData(minors)}
      />
    </div>
  );
};

export default MinorListing;
