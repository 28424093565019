import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { addGoal, updateCampaignGoal } from "store/campaign";
import { toast } from "react-toastify";
import ProgressButton from "components/ProgressButton";

const CreateGoalForm = ({
  open,
  onClose,
  singleGoal,
  multipleGoals,
  campaignId,
  orgId,
}) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [inProgress, setProgress] = useState(false);

  useEffect(() => {
    if (singleGoal) {
      setAmount(singleGoal.goal);
    }
  }, [singleGoal]);

  const handleSave = async () => {
    setProgress(true);
    if (multipleGoals) {
      await dispatch(
        updateCampaignGoal(
          orgId,
          campaignId,
          multipleGoals.map((g) => g.id),
          amount
        )
      );
    } else {
      if (singleGoal.goal) {
        await dispatch(
          updateCampaignGoal(orgId, campaignId, [singleGoal.id], amount)
        );
      } else {
        await dispatch(addGoal(orgId, campaignId, [singleGoal.id], amount));
      }
    }
    setProgress(false);
    toast("Goal Saved Successfully", { type: "success" });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {singleGoal?.goal ? `Update Goal` : "Create Goal"}
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="body2" color="textSecondary">
          Goal for {multipleGoals ? "selected teams" : `${singleGoal?.name}`}
        </Typography>
        <TextField
          fullWidth
          type="number"
          variant="outlined"
          helperText="Goal Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ProgressButton
          label="Save"
          showProgress={inProgress}
          color="primary"
          onClick={handleSave}
          disabled={inProgress || !amount}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateGoalForm;
