import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { signIn } from "store/auth";
import { Alert } from "@material-ui/lab";
import { isValidEmail } from "utils";
import { useHistory } from "react-router-dom";
import { getConsoleHome } from "routes";
import ForgotPasswordDialog from "./ForgotPasswordDialog";

const LoginDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inProgress, setProgress] = useState(false);
  const [error, setError] = useState(null);
  const [isForgetPasswordShowing, showForgetPassword] = useState(false);

  const handleClose = () => {
    setEmail("");
    setPassword("");
    setError(null);
    setProgress(false);
    onClose();
  };
  const handleLogin = async (e) => {
    setError(null);
    setProgress(true);
    const user = await dispatch(signIn(email, password));
    history.push(getConsoleHome(user));
    setProgress(false);
    if (!user) {
      setError("Incorrect Email/Password, Please try again");
    } else {
      history.push(getConsoleHome(user));
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: 25,
        },
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" , flexDirection : "row"  }}>
        <Typography
          style={{alignSelf : "center"  , fontWeight: "bold", marginTop: 17, marginBottom: 17 , marginLeft : "45%" }}
          variant="h6"
        >
          Login
        </Typography>
        <Button onClick={handleClose} style={{marginLeft :"auto"}}>
          x
        </Button>
      </div>

      <Divider />
      <DialogContent>
        <div style={{ marginTop: 40, paddingLeft: 50, paddingRight: 50 }}>
          <Typography
            variant="body2"
            style={{ marginBottom: 5, fontWeight: "bold" }}
          >
            Email
          </Typography>
          <TextField
            variant="outlined"
            value={email}
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@email.com"
            onKeyDown={(e) => {
              if (
                e.key !== "Enter" ||
                inProgress ||
                !isValidEmail(email) ||
                !password
              )
                return;
              handleLogin(e);
            }}
          />
          <Typography
            variant="body2"
            style={{ marginTop: 10, fontWeight: "bold" }}
          >
            Password
          </Typography>
          <TextField
            variant="outlined"
            value={password}
            fullWidth
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            style={{ marginTop: 10 }}
            placeholder="**********"
            type="password"
            onKeyDown={(e) => {
              if (
                e.key !== "Enter" ||
                inProgress ||
                !isValidEmail(email) ||
                !password
              )
                return;
              handleLogin(e);
            }}
          />
          <Button
            fullWidth
            style={{
              marginTop: 20,
              marginBottom: 25,
              textTransform: "none",
              color: "white",
              fontWeight: "bold",
              height: 55,
            }}
            variant="contained"
            disableElevation
            size="large"
            color="primary"
            disabled={inProgress || !isValidEmail(email) || !password}
            onClick={(e) => handleLogin(e)}
          >
            Login to Walkathon
          </Button>
        </div>
      </DialogContent>
      <Divider style={{ marginTop: 20, marginBottom: 10 }} />
      <Button
        variant="text"
        size="large"
        style={{ marginBottom: 10, textTransform: "none", fontWeight: "bold" }}
        onClick={() => showForgetPassword(true)}
      >
        Forgot your password ?
      </Button>
      {error && <Alert severity="error">{error}</Alert>}
      <ForgotPasswordDialog open={isForgetPasswordShowing} onClose={() => showForgetPassword(false)} />

    </Dialog>
  );
};
export default LoginDialog;
