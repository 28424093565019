import { API_BASE_URL } from "api-client/index";
import axios from "axios";

export const createAdult = (adult) => {
  const uri = `${API_BASE_URL}/adult/`;
  return axios.post(uri, adult);
};
export const createMinor = (payload) => {
  const uri = `${API_BASE_URL}/adult/minor`;
  return axios.post(uri, payload);
};

export const updateName = (payload) => {
  const uri = `${API_BASE_URL}/adult/changeName`;
  return axios.post(uri, payload);
};

export const joinCampaign = (campaignId, adultId) => {
  const uri = `${API_BASE_URL}/adult/join_campaign`;
  return axios.post(uri, { campaign_id: campaignId, adult_id: adultId });
};

export const getAdultDetail = (adultId) => {
  const uri = `${API_BASE_URL}/adult/by_id/${adultId}`;
  return axios.get(uri);
};
export const getFundrasierDetail = (minorId) => {
  const uri = `${API_BASE_URL}/adult/minor/${minorId}`;
  return axios.get(uri);
};
