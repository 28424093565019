import { API_BASE_URL } from "api-client/index";
import axios from "axios";

export const getTeams = (orgId) => {
  const uri = `${API_BASE_URL}/team/${orgId}`;
  return axios.get(uri);
};
export const getTeam = (teamId) => {
  const uri = `${API_BASE_URL}/team/by_id/${teamId}`;
  return axios.get(uri);
};
export const deleteTeam = (teamId) => {
  const uri = `${API_BASE_URL}/team/${teamId}`;
  return axios.delete(uri);
};

export const createTeam = (orgId, team) => {
  const uri = `${API_BASE_URL}/team/${orgId}`;
  return axios.post(uri, team);
};

export const updateTeam = (teamId, team) => {
  const uri = `${API_BASE_URL}/team/${teamId}`;
  return axios.put(uri, team);
};
