import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  makeStyles,
  TableSortLabel,
} from "@material-ui/core";
import ResourceNotFound from "components/ResourceNotFound";

const useStyles = makeStyles((theme) => ({
  noBorder: { borderWidth: 0 },
  table: { marginBottom: 20 },
}));

const Direction = {
  Asc: "asc",
  Desc: "desc",
};

const CustomTable = ({
  tableHead = [],
  tableData = [],
  noBorder = false,
  withPagination = false,
  onSortChange = () => {},
  style,
}) => {
  const classes = useStyles();
  const isSortRequired = (header) =>
    typeof header === "object" && header !== null && header.enableSort;
  const [dir, setDir] = useState(Direction.Asc);
  useEffect(() => {
    if (tableData && tableData.length > 1) onSortChange(dir);
  }, [tableData]);

  return (
    <div style={style}>
      {withPagination && (
        <TablePagination
          component="div"
          page={0}
          count={tableData.length}
          rowsPerPage={10}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
      <Paper className={noBorder && classes.noBorder} variant="outlined">
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHead.map((header) =>
                isSortRequired(header) ? (
                  <TableCell>
                    <TableSortLabel
                      direction={dir}
                      active
                      onClick={() => {
                        setDir(
                          dir === Direction.Asc ? Direction.Desc : Direction.Asc
                        );
                        onSortChange(dir);
                      }}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell>{header}</TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, rowKey) => (
              <TableRow key={rowKey}>
                {row.map((cell, cellKey) => (
                  <TableCell
                    key={cellKey}
                    className={
                      rowKey + 1 === tableData.length
                        ? classes.noBorder
                        : undefined
                    }
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!tableData.length && <ResourceNotFound />}
      </Paper>
    </div>
  );
};

export default CustomTable;
