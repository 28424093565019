import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import RewardsListing from "./RewardsListing";
import RewardForm from "./RewardForm";

const Rewards = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <RewardsListing />
            </Route>
            <Route path={`${path}/new`}>
                <RewardForm/>
            </Route>
            <Route path={`${path}/edit/:rewardId`}>
                <RewardForm />
            </Route>
        </Switch>
    );
};

export default Rewards;
