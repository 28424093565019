import React, { useEffect } from "react";
import { Grid, Paper, SvgIcon } from "@material-ui/core";
import ActionBar from "components/ActionBar";
import moment from "moment";
import {
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
  RemoveRedEyeOutlined as ViewIcon,
  VerifiedUser as DoneIcon,
} from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import ViewHeader from "components/ViewHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganizations, removeOrganization } from "store/organization";
import Loading from "components/Loading";
import { colors } from "utils/colors";
import { useConfirm } from "material-ui-confirm";
import ResourceNotFound from "components/ResourceNotFound";
import MUIDataTable from "mui-datatables";

const OrganizationListing = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const history = useHistory();
  const location = useLocation();
  const { data, inProgress, error } = useSelector(
    (state) => state.organization
  );
  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  const tableHead = [
    "Name",
    "School Address",
    "School Phone Number",
    "Status",
    "Registration Date",
    "Actions",
  ];
  const formatTableData = (organizations) =>
    organizations.map((org) => [
      org.name,
      org.address,
      org.phone_number,
      org.registration_status === "verified" && (
        <SvgIcon fontSize="small" color="action">
          <DoneIcon style={{ fill: colors.teal }} />
        </SvgIcon>
      ),
      moment(org.createdAt).format("MMMM DD, YYYY"),
      <>
        <Grid item xl={4} sm={12} xs={4}>
          <SvgIcon
            fontSize="default"
            color="action"
            style={{ cursor: "pointer", margin: 5 }}
            onClick={() => history.push(`${location.pathname}/edit/${org.id}`)}
          >
            <EditIcon />
          </SvgIcon>
          <SvgIcon
            fontSize="default"
            color="action"
            style={{ cursor: "pointer", margin: 5 }}
            onClick={() => history.push(`${location.pathname}/${org.id}`)}
          >
            <ViewIcon />
          </SvgIcon>
          <SvgIcon
            fontSize="default"
            color="action"
            style={{
              cursor: "pointer",
              backgroundColor: "secondory",
              margin: 5,
            }}
            onClick={async () => {
              try {
                await confirm({
                  title: "Delete Organization",
                  description:
                    "Are you sure you want to delete this Organization?",
                });
                await dispatch(removeOrganization(org.id));
              } catch (err) {}
            }}
          >
            <DeleteIcon />
          </SvgIcon>
        </Grid>
      </>,
    ]);
  const options = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    elevation: 0,
  };

  return (
    <>
      <ViewHeader
        title="Organizations"
        subtitle="Create and Manage Organizations"
      >
        <ActionBar
          actionText="New Organization"
          onAction={() => history.push(`${location.pathname}/new`)}
          searchPlaceholder="Search Organizations"
        />
      </ViewHeader>
      {inProgress && <Loading />}
      {data && data.length ? (
        // <CustomTable
        //   tableData={formatTableData(data)}
        //   tableHead={tableHead}
        //   style={{ marginTop: 30 }}
        // />
        <Paper variant="outlined" style={{ marginTop: 30 }}>
          <MUIDataTable
            data={formatTableData(data)}
            columns={tableHead}
            options={options}
          />
        </Paper>
      ) : (
        !inProgress && <ResourceNotFound />
      )}
    </>
  );
};

export default OrganizationListing;
