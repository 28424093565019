import React from "react";
import ViewHeader from "components/ViewHeader";
import { Button, Grid, TextField } from "@material-ui/core";

const NewAdultForm = () => {
  return (
    <>
      <ViewHeader
        title="Adult Registration"
        subtitle="Register Adult in system"
      />
      <Grid container style={{ marginTop: 20 }} spacing={1}>
        <Grid item xl={7} lg={7}>
          <TextField fullWidth label="Full Name" variant="outlined" />
        </Grid>
        <Grid item xl={7} lg={7}>
          <TextField fullWidth label="Email Address" variant="outlined" />
        </Grid>
        <Grid item xl={7} lg={7}>
          <TextField fullWidth label="Password" variant="outlined" />
        </Grid>
        <Grid item xl={7} lg={7}>
          <TextField fullWidth label="Confirm Password" variant="outlined" />
        </Grid>
        <Grid item xl={7} lg={7}>
          <Button variant="contained" color="secondary">
            Register Account
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default NewAdultForm;
