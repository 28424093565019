import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { colors, getRandomColor } from "utils/colors";
import { makeStyles } from "@material-ui/core/styles";
import BecomeFundraiserDialog from "views/public/campaign/BecomeFundraiserDialog";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { storageRef } from "../../../index";
import { AttachMoney, Share } from "@material-ui/icons";
import { BASE_URL } from "api-client";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {useMediaQuery} from "react-responsive";
import RewardComponent from "../reward/RewardComponent";
import ResourceNotFound from "../../../components/ResourceNotFound";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    flex: 1,
    height: "100vh",
    width: "100%",
    alignContent: "center",
    marginTop: 30,
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 120,
    width: 120,
  },
}));

const TeamCard = ({ teamDetail }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { org_name, campaignId } = useParams();
  const [imageTeam, setImageTeam] = useState(null);
  const [imageCampaign, setImageCampaign] = useState(null);
  const { user } = useSelector((s) => s.auth);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [openFundRaiserDialog, setOpenFundraiserDialog] = useState(false);
  const [isFundraiser, setIsFundraiser] = useState(false);

  useEffect(() => {
    downloadImages();
  }, [teamDetail]);
  const downloadImages = async () => {
    try {
      try {
        const campaignImage = `campaign_profile_${teamDetail?.team_goal?.campaign_id}`;
        const campaignImageUrl = await storageRef
          .child(campaignImage)
          .getDownloadURL();
        await setImageCampaign(campaignImageUrl);
      } catch (e) {}

      try {
        const teamImage = `team_${teamDetail?.id}`;
        const teamImageUrl = await storageRef.child(teamImage).getDownloadURL();
        setImageTeam(teamImageUrl);
      } catch (e) {}
    } catch (err) {}
  };

  return (
    <>
      {isDesktopOrLaptop ? <Card className={clsx(classes.cardRoot)}>
        <CardContent>
          <Box>
            <Avatar className={classes.avatar} src={imageTeam} />
            <div style={{ marginTop: 15 }}>
              <Typography variant="h4">{teamDetail?.name}</Typography>
              <Typography variant="body2">
                Please help us reach our goal !
              </Typography>
            </div>
            <Typography variant="h6" style={{ marginTop: 20 }}>
              <span style={{ fontWeight: "bold" }}>
                $
                {teamDetail?.team_goal.achieved_goal_amount
                    ? teamDetail?.team_goal.achieved_goal_amount
                    : 0}{" "}
                Raised
              </span>{" "}
              of ${teamDetail?.team_goal.goal}
            </Typography>
            <LinearProgress
                style={{
                  height: 8,
                  borderRadius: 15,
                  marginTop: 10,
                  marginBottom: 25,
                }}
                variant="determinate"
                value={
                  teamDetail?.team_goal.achieved_goal_amount
                      ? (parseInt(teamDetail?.team_goal.achieved_goal_amount) /
                          parseInt(teamDetail?.team_goal.goal)) *
                      100
                      : 0
                }
                color="secondary"
            />
            {!user && (
                <Button
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      color: colors.white,
                      fontWeight: "bold",
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={isFundraiser}
                    onClick={() => setOpenFundraiserDialog(true)}
                >
                  {isFundraiser ? "Joined as Fundraiser" : "Join the Campaign"}
                </Button>
            )}
            <div style={{ display: "flex" }}>
              <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  startIcon={<AttachMoney color={colors.white} />}
                  style={{ marginTop: 0, fontWeight: "bold" ,
                    color : colors.white,
                    backgroundColor : colors.donationGreen}}
                  onClick={() =>
                      history.push(
                          `${location.pathname}/campaign/${teamDetail?.team_goal?.campaign_id}/donate`
                      )
                  }
              >
                Donate
              </Button>
              <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  style={{ marginLeft: 10, fontWeight: "bold" }}
                  startIcon={<Share color="action" />}
                  onClick={() => {
                    navigator.clipboard.writeText(
                        ` ${BASE_URL}${location.pathname}`
                    );
                    toast("Link Copied to Share !", { type: "success" });
                  }}
              >
                Share this Page
              </Button>
            </div>

            <div style={{ marginTop: 50, marginBottom: 20 }}>
              <Divider />
            </div>

            <div>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Team Lead
              </Typography>
              <div
                  style={{ display: "flex", alignItems: "center", marginTop: 10 }}
              >
                <Avatar
                    style={{
                      height: 43,
                      width: 43,
                      backgroundColor: getRandomColor(),
                    }}
                />
                <Typography variant="body2" style={{ marginLeft: 20 }}>
                  {teamDetail?.Manager?.name}
                </Typography>
              </div>
            </div>

            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Divider />
            </div>

            <div style={{ width: "100%" }}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Campaigns
              </Typography>
              <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/org/${org_name}/c/${campaignId}`)}
              >
                <Avatar src={imageCampaign} style={{ width: 43, height: 43 }} />
                <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginLeft: 20,
                    }}
                >
                  <Typography variant="body2">
                    {teamDetail?.team_goal?.Campaign?.title}
                  </Typography>
                  <LinearProgress
                      style={{
                        width: 200,
                        marginTop: 6,
                        marginBottom: 6,
                        height: 4,
                        borderRadius: 7,
                      }}
                      value={
                        (parseInt(
                                teamDetail?.team_goal?.Campaign?.achieved_goal_amount
                            ) /
                            parseInt(
                                teamDetail?.team_goal?.Campaign?.total_goal_amount
                            )) *
                        100
                      }
                      color="secondary"
                      variant="determinate"
                  />
                  <Typography variant="body2">
                    <span style={{ fontWeight: "bold" }}>
                      ${teamDetail?.team_goal?.Campaign?.achieved_goal_amount}{" "}
                      raised
                    </span>{" "}
                    of ${teamDetail?.team_goal?.Campaign?.total_goal_amount}
                  </Typography>
                </div>
              </div>
            </div>

          </Box>
        </CardContent>
      </Card> : <>

        <Avatar style={{marginTop: 30}} className={classes.avatar} src={imageTeam} />
        <div style={{ marginTop: 15 }}>
          <Typography variant="h4">{teamDetail?.name}</Typography>
          <Typography variant="body2">
            Please help us reach our goal !
          </Typography>
        </div>
        <Typography variant="h6" style={{ marginTop: 20 }}>
              <span style={{ fontWeight: "bold" }}>
                $
                {teamDetail?.team_goal.achieved_goal_amount
                    ? teamDetail?.team_goal.achieved_goal_amount
                    : 0}{" "}
                Raised
              </span>{" "}
          of ${teamDetail?.team_goal.goal}
        </Typography>
        <LinearProgress
            style={{
              height: 8,
              borderRadius: 15,
              marginTop: 10,
              marginBottom: 25,
            }}
            variant="determinate"
            value={
              teamDetail?.team_goal.achieved_goal_amount
                  ? (parseInt(teamDetail?.team_goal.achieved_goal_amount) /
                      parseInt(teamDetail?.team_goal.goal)) *
                  100
                  : 0
            }
            color="secondary"
        />
        {!user && (
            <Button
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  color: colors.white,
                  fontWeight: "bold",
                }}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                disabled={isFundraiser}
                onClick={() => setOpenFundraiserDialog(true)}
            >
              {isFundraiser ? "Joined as Fundraiser" : "Join the Campaign"}
            </Button>
        )}
        <div style={{ display: "flex" }}>
          <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<AttachMoney color={colors.white} />}
              style={{ marginTop: 0, fontWeight: "bold" ,
                color : colors.white,
                backgroundColor : colors.donationGreen}}
              onClick={() =>
                  history.push(
                      `${location.pathname}/campaign/${teamDetail?.team_goal?.campaign_id}/donate`
                  )
              }
          >
            Donate
          </Button>
          <Button
              variant="outlined"
              size="large"
              fullWidth
              style={{ marginLeft: 10, fontWeight: "bold" }}
              startIcon={<Share color="action" />}
              onClick={() => {
                navigator.clipboard.writeText(
                    ` ${BASE_URL}${location.pathname}`
                );
                toast("Link Copied to Share !", { type: "success" });
              }}
          >
            Share this Page
          </Button>
        </div>

        <div style={{ marginTop: 50, marginBottom: 20 }}>
          <Divider />
        </div>

        <div>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            Team Lead
          </Typography>
          <div
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
          >
            <Avatar
                style={{
                  height: 43,
                  width: 43,
                  backgroundColor: getRandomColor(),
                }}
            />
            <Typography variant="body2" style={{ marginLeft: 20 }}>
              {teamDetail?.Manager?.name}
            </Typography>
          </div>
        </div>

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Divider />
        </div>

        <div style={{ width: "100%" }}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            Campaigns
          </Typography>
          <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                cursor: "pointer",
              }}
              onClick={() => history.push(`/org/${org_name}/c/${campaignId}`)}
          >
            <Avatar src={imageCampaign} style={{ width: 43, height: 43 }} />
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginLeft: 20,
                }}
            >
              <Typography variant="body2">
                {teamDetail?.team_goal?.Campaign?.title}
              </Typography>
              <LinearProgress
                  style={{
                    width: 200,
                    marginTop: 6,
                    marginBottom: 6,
                    height: 4,
                    borderRadius: 7,
                  }}
                  value={
                    (parseInt(
                            teamDetail?.team_goal?.Campaign?.achieved_goal_amount
                        ) /
                        parseInt(
                            teamDetail?.team_goal?.Campaign?.total_goal_amount
                        )) *
                    100
                  }
                  color="secondary"
                  variant="determinate"
              />
              <Typography variant="body2">
                    <span style={{ fontWeight: "bold" }}>
                      ${teamDetail?.team_goal?.Campaign?.achieved_goal_amount}{" "}
                      raised
                    </span>{" "}
                of ${teamDetail?.team_goal?.Campaign?.total_goal_amount}
              </Typography>
            </div>
          </div>
        </div>
      </>}


      <BecomeFundraiserDialog
        campaignId={campaignId}
        open={openFundRaiserDialog}
        onJoin={() => setIsFundraiser(true)}
        onClose={() => setOpenFundraiserDialog(false)}
      />
    </>
  );
};

export default TeamCard;
