import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "views/public/landing/Landing.js";
import CampaignDetail from "views/public/campaign/CampaignDetail";
import { LinearProgress, MuiThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "config/theme";
import Template from "components/Template";
import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import TeamDetail from "views/public/team/TeamDetail";
import FundraiserDetail from "views/public/fundraiser/FundraiserDetail";
import Donation from "views/public/Donation/Donation";
import { useSelector } from "react-redux";
import { ConfirmProvider } from "material-ui-confirm";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ThankyouPage from "views/public/Donation/ThankyouPage";

toast.configure();

const App = () => {
  const { inProgress, isDarkTheme } = useSelector((state) => state.auth);
  return (
    <MuiThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <ConfirmProvider>
        <CssBaseline />
        {inProgress ? (
          <LinearProgress color="secondary" />
        ) : (
          <Router>
            <Switch>
              <Route path="/" exact>
                <Landing />
              </Route>
              <Route path="/org/:org_name/c/:campaignId/donate">
                <Donation />
              </Route>
              <Route path="/campaign/thanks/:name/:email/:amount/:campaignId/:teamId/:orgName/:fundraiserId/:stripeSessionId/:isAnonymous">
                <ThankyouPage />
              </Route>
              <Route path="/org/:org_name/c/:campaignId/t/:team_id/campaign/:campaignID/donate">
                <Donation />
              </Route>
              <Route path="/org/:org_name/c/:campaignId/t/:team_id/f/:fundraiser_id/campaign/:campaignID/team/:team_id/donate">
                <Donation />
              </Route>
              <Route path="/org/:org_name/c/:campaignId/t/:team_id/f/:fundraiser_id">
                <FundraiserDetail />
              </Route>
              <Route path="/org/:org_name/c/:campaignId/t/:team_id">
                <TeamDetail />
              </Route>
              <Route path="/org/:org_name/c/:campaignId">
                <CampaignDetail />
              </Route>

              <Template />
            </Switch>
          </Router>
        )}
      </ConfirmProvider>
    </MuiThemeProvider>
  );
};

export default App;
