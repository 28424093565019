import { API_BASE_URL } from "api-client/index";
import axios from "axios";

export const getManagers = (orgId) => {
  const uri = `${API_BASE_URL}/manager/${orgId}`;
  return axios.get(uri);
};

export const deleteManager = (managerId) => {
  const uri = `${API_BASE_URL}/manager/${managerId}`;
  return axios.delete(uri);
};

export const createManager = (orgId, manager) => {
  const uri = `${API_BASE_URL}/manager/${orgId}`;
  return axios.post(uri, manager);
};

export const updateManager = (managerId, manager) => {
  const uri = `${API_BASE_URL}/manager/${managerId}`;
  return axios.put(uri, manager);
};
