import { createSlice } from "@reduxjs/toolkit";
import firebase from "firebase";
import {deleteAccountFromDB, getName} from "api-client/auth";

export const initialState = {
  error: null,
  inProgress: false,
  user: null,
  isAuthenticated: false,
  isDarkTheme: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setProgress: (state, action) => {
      state.inProgress = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setDarkTheme: (state, action) => {
      state.isDarkTheme = action.payload;
    },
  },
});

export const signIn = (email, password) => async (dispatch) => {
  try {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const doc = await firebase
      .firestore()
      .collection("users")
      .doc(response.user.uid)
      .get();
    let name = null;
    if (doc?.data()?.role !== "super-admin") {
      const getNameRes = await getName(
        doc?.data()?.role,
        response?.user?.email
      );
      name = getNameRes.data.name;
    }

    const user = {
      id: response.user.uid,
      displayName: name,
      photoURL: response.user.photoURL,
      email: response.user.email,
      role: doc?.data()?.role,
      systemId: doc?.data()?.systemId,
      orgId: doc?.data()?.orgId,
    };
    dispatch(authSlice.actions.setUser(user));
    dispatch(authSlice.actions.setAuthenticated(true));
    return user;
  } catch (err) {
    dispatch(
      authSlice.actions.setError(
        "Incorrect Email or Password, Please try again."
      )
    );
    return null;
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    const response = await firebase.auth().sendPasswordResetEmail(email);

    return response;
  } catch (err) {
    dispatch(
        authSlice.actions.setError(
            "Incorrect Email or Password, Please try again."
        )
    );
    return null;
  }
};

export const deleteAccount = () => async (dispatch) => {
  try {
    const user = await firebase.auth().currentUser;
    user.delete().then(() => {
      return true
    }).catch((error) => {
      return false
    });
  } catch (err) {
    dispatch(
        authSlice.actions.setError(
            "Incorrect Email or Password, Please try again."
        )
    );
    return null;
  }
};
export const removeUserFromDB = (role) => async (dispatch) => {
  try {
    const user = await firebase.auth().currentUser;
    await deleteAccountFromDB(role,user.email);

  } catch (err) {
    dispatch(
        authSlice.actions.setError(
            "Incorrect Email or Password, Please try again."
        )
    );
    return null;
  }
};


export const signOut = () => async (dispatch) => {
  await firebase.auth().signOut();
  dispatch(authSlice.actions.setUser(null));
  dispatch(authSlice.actions.setAuthenticated(false));
};

export default authSlice;
